import React from 'react';
import { Field } from 'react-final-form';
import RawInputGallery from './RawInputGallery';
import { required, ShowError } from './formUtils';
import config from '../../config';

const FormFieldGallery = ({
  name,
  title,
  className,
  required: isRequired = false,
}) => {
  const validate = isRequired ? required : undefined;

  return (
    <div className={`pb-3 ${className}`}>
      <Field
      name={name}
      validate={validate}
      defaultValue={config.defaultLocationGPS || { lat: 0, lng: 0 }}>
        {({ input, meta }) => (
          <div>
            <label>
              <span className={`font-semibold mr-3 ${meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'}`}>
                {title}
                {isRequired ? '*' : ''}
              </span>
            </label>
            <div className="mt-2">
              <RawInputGallery
                value={input.value}
                onChange={input.onChange}
              />
            </div>
            {/* Render error if any */}
            {meta.touched && meta.error && <div className="text-red-600" style={{ fontSize: 12 }}>{meta.error}</div>}
            {/* Error */}
            <ShowError name={name} input={input} meta={meta} required={isRequired} />
          </div>
        )}
      </Field>
    </div>
  );
};

export default FormFieldGallery;
