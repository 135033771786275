import { useState, useEffect } from "react";
import Product from "../../models/Product";
import ItemThumbOne from "../ItemThumbOne";

export default function SuperOutstandingProductBlock({ data }) {
  const fetchSuperOutstandingProduct = async () => {
    const productsAll = await Product.getAll();
    Product.sortDocs(productsAll, 'sort');
    const superOutstandingProduct = productsAll.filter((product) => product.data.superOutstanding);
    return superOutstandingProduct;
  };

  const [superOutstandingProduct, setSuperOutstandingProduct] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await fetchSuperOutstandingProduct();
      setSuperOutstandingProduct(products);
    };

    fetchProducts();
  }, []);

  return (
    superOutstandingProduct.length ? (
      <div className="px-4 py-20 bg-white text-brand-dark">
        <div className="container-width">
          <h3 className="pb-4 text-lg">{data.title}</h3>
          <ItemThumbOne item={superOutstandingProduct[0]} />
        </div>
      </div>
    ) : null
  );
}
