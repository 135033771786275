import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
// import MetaTags from 'react-meta-tags';
import Model from "../libs/ModelClass";
import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import config from "../config";
import { parseAttrValParams } from '../libs/utils';
import useBreakpoints from '../libs/useBreakpoints';
import { blocks } from "../stringsMap";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";
import { animateScroll as scroll } from 'react-scroll';
import { usePartOfModule } from "../components/Module/PartOfModule";
import Page404 from '../components/Page404';
import { useModule } from "../context/ModuleContext";

export function PublicModulePage({ defaultPath }) {
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();

  const { entitySlug, action, '*': attrValParams } = useParams();
  const [parsedParams, setParsedParams] = useState();
  
  useEffect(() => {
    setParsedParams( parseAttrValParams( attrValParams ) ); 
  }, [location, attrValParams]);

  const routeOfModule = usePartOfModule({
    type: 'routePublic',
    entitySlug,
    action
  });

  const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.brand.name}
      className="px-6 text-gray-600 hover:text-gray-900  md:text-lg lg:text-xl text-lg font-semibold cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
    <Link
      to="/fracciones/mapa"
      title={config.brand.name}
      className="px-6 text-gray-600 hover:text-gray-900  md:text-lg lg:text-xl text-lg font-semibold cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Mapa
    </Link>
  </>);

  return (
    <LayoutPublic HeaderMenu={HeaderMenu}>
      {/* <MetaTags>
        <title>{getPageTitle(config.projectName)}</title>
        <meta name={getPageTitle()} content={config.projectName} />
      </MetaTags> */}
      {routeOfModule?.Content ? (
        <routeOfModule.Content {...{ 
          parsedParams,
          entitySlug, 
          action,
          config,
          isAllowed, 
          Model,
          isMinBreakpointActive, 
          location, 
          navigate, 
        }} />)
      : (
        <Page404 />
      )}
    </LayoutPublic>
  );
}
