import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import _ from 'lodash';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth, addUserProperties } from "../firebase";
import Model from "../libs/ModelClass";

const authContext = createContext();

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error("There is no Auth provider");
  return context;
};

export const getUserCredentials = async ({ email, withRoles }) => {
  if (!email) {
    return false;
  }
  const UserProfile = Model.extend('usersProfiles');
  const Credential = Model.extend('credentials');
  const userDoc = await UserProfile.whereOne('email', '==', email);
  let rolesDoc;
  if (!userDoc?.data?.email) {
    throw new Error('No está registrado en el sistema');
  }
  const credentialDoc = await Credential.whereOne('profile', '==', userDoc?.id);
  if (!credentialDoc?.data?.isActive) {
    throw new Error('No tiene acceso al sistema');
  }
  if (withRoles && credentialDoc?.data?.roles) {
    const Role = Model.extend('roles'); 
    rolesDoc = await Role.findById(credentialDoc?.data?.roles);
    if (!rolesDoc?.data) {
      throw new Error('No tiene roles válidos');
    }
  }
  return { userDoc, credentialDoc, rolesDoc };
};

export function AuthProvider({ children }) {
  let location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const loginWithGoogleAndVerify = async () => {
    const googleProvider = new GoogleAuthProvider();
    const credentials = await signInWithPopup(auth, googleProvider);
    await getUserCredentials({ email: credentials?.user?.email});
    return credentials;
  };

  const loginWithGoogle = async () => {
    const googleProvider = new GoogleAuthProvider();
    const credentials = await signInWithPopup(auth, googleProvider);
    return credentials;
  };

  const logout = () => {
    signOut(auth);
    setUser(null);
  };

  const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

  // log user params analytics
  useEffect(() => {
    const unsubuscribe = onAuthStateChanged(auth, async (currentUser) => {
      // attach funnel param to log
      let params = {
        'role': currentUser ? 'admin' : 'guest'
      };
      const searchParams = new URLSearchParams( location.search.split('?')[1] );
      params.funnel = searchParams.has('funnel') 
        ? searchParams.get('funnel')
        : 'none';
      addUserProperties(params); // analytics
      // set user data
      try {
        const { userDoc, credentialDoc, rolesDoc } = await getUserCredentials({ email: currentUser?.email, withRoles: true });
        setUser({ userAuth: currentUser, userDoc, credentialDoc, rolesDoc });
        setLoading(false);
      } catch (e) {
        if (_.includes(location.pathname, "/admin")) {
          navigate('/login');
          e?.message && toast.error(e.message);
        }
      }
    });
    return () => unsubuscribe();
  }, []);
  
  return (
    <authContext.Provider
      value={{
        signup,
        login,
        user,
        logout,
        loading,
        loginWithGoogleAndVerify,
        loginWithGoogle,
        resetPassword,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
