import config from '../../config';
import { RouteFraccionesMapa } from './RouteFraccionesMapa';
import { RouteShowFraction } from './RouteShowFraction';
import { RouteShowLote } from './RouteShowLote';
import { cityDataType } from './cityDataType';
import entities from './entities.json';
import { polygonDataTypeForFraccion, polygonDataTypeForLote } from './polygonDataType';
import { ListHeaderExtraActionsForLotes } from './syncData';

let { lotes, fracciones } = entities;

const UnderListItemTitleForLotes = ({ doc, ViewData }) => (<>
  <ViewData field="manzana" classes={{ 
    fieldContainer: 'mr-2 pt-1.5 inline-block h-4 text-sm text-gray-800', 
    fieldValue: `font-semibold text-gray-700`
  }} />
  <ViewData 
    field="fraccion"
    classes={{ fieldContainer: 'pt-1.5 inline-block text-sm text-gray-800' }} 
    Render={({ value }) => ( value )}
  />
  <ViewData field="estado" classes={{ 
    fieldContainer: 'ml-2 pt-1.5 inline-block h-4  text-sm text-gray-800', 
    fieldValue: `font-semibold ${doc?.data?.estado === 'Disponible' ? 'text-blue-500' : 'text-gray-800'}`
  }} />
</>);

const AdminHomeAfterLinksList = ({ isAllowed }) => {
  return (<>
    {/* {isAllowed('actions', 'btn-webmail') ? () : null} */}
    <a href={config.webmailUrl} target='_blank' className="bg-gray-300 text-gray-900 p-4 font-semibold border border-gray-600 rounded shadow-md">
      Email
    </a>
  </>);
};

export default function ({ setPermissions }) {
  return {
    slug: 'fraccionesLotes',
    label: 'fracciones y Lotes',
    entities: {
      lotes, fracciones
    },
    permissions: [
      setPermissions({ slug: 'lotes', label: 'Credenciales' }),
      setPermissions({ slug: 'fracciones', label: 'Fracciones' }),
      setPermissions({ slug: 'actions', label: 'Acciones', type: 'system', actions: ['btn-webmail'] })
    ],
    routesPublic: {
      'fracciones': {
        'show': {
          Content: RouteShowFraction
        },
        'mapa': {
          Content: RouteFraccionesMapa
        }
      },
      'lotes': {
        'show': {
          Content: RouteShowLote
        }
      }
    },
    components: {
      'main': {
        AdminHomeAfterLinksList
      },
      'lotes': {
        crud: {
          ListHeaderExtraActions: ListHeaderExtraActionsForLotes,
          UnderListItemHeader: UnderListItemTitleForLotes
        },
        codedDataTypes: {
          'polygon': polygonDataTypeForLote
        },
      },
      'fracciones': {
        codedDataTypes: {
          'polygon': polygonDataTypeForFraccion,
          'city': cityDataType
        },
      },
    },
    // events: {}
  };
};