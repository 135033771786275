import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";


export default function CtaGrid ({ items, classes }) {
  return (
    <div className={`flex flex-col md:flex-row gap-4 justify-center items-center ${classes?.ctaGridContainer}`}>
      {items?.map((item, index) => (
      <div key={index}>
        {item.ctaType === "whatsapp" && (
          <Link to={item.ctaUrl} target="_blank" className={`
            px-4 md:px-8 lg:px-8 py-3 md:py-3 lg:py-5
            bg-brand-red hover:bg-orange-700 flex items-center
            text-white ${classes?.ctaGridButton || "rounded-sm md:uppercase"}
          `} rel="noreferrer">
            <h3 className="text-md">
              {item.ctaTitle}
            </h3>
            <FaWhatsapp className="text-xl ml-1.5" />
          </Link>
        )}
        
        {item.ctaType === "link" && (
          <Link to={item.ctaUrl} className={`
            px-4 md:px-8 lg:px-8 py-3 md:py-3 lg:py-5
            bg-white hover:bg-amber-100 flex items-center
            ${classes?.ctaGridButton || "rounded-sm text-black md:uppercase"}
          `}>
            <h3 className="text-md">
              {item.ctaTitle}
            </h3>
          </Link>
        )}  
      </div>))}
    </div>
  )
}