import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import Model from "../libs/ModelClass";
import config from "../config";
import EntityFilterMenuDisplay from '../components/EntityFilterMenuDisplay';
import ItemThumbGrid from "../components/ItemThumbGrid";
import { parseAttrValParams } from '../libs/utils';
import { useEntityFullBySlug } from '../modules/entity/Entity';
import dataToQueryFormatter from '../components/Filters/dataToQueryFormatter';
import useBreakpoints from '../libs/useBreakpoints';
import { blocks } from "../stringsMap";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";
import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
import { animateScroll as scroll } from 'react-scroll';

const entitySlug = "inmuebles";
const filterMenuSlug = "search";

export function FilterUrlSection({ defaultPath }) {
  const { '*': attrValParams } = useParams();
  const [filterData, setFilterSelected] = useState();
  const [filteredDocs, setFilteredDocs] = useState([]);
  const [redirectionPath, setRedirectionPath] = useState('/filter');
  const location = useLocation();
  const navigate = useNavigate();
  const { isMinBreakpointActive } = useBreakpoints();

  const ExtendedModel = Model.extend(entitySlug);
  const { filterMenuTaxonomyTypes } = useEntityFullBySlug({ entitySlug, filterMenuSlug });

  useEffect(async () => {
    if (filterMenuTaxonomyTypes?.length) {
      await fetchFilteredDocs();
    }
  }, [location, filterMenuTaxonomyTypes]);

  const fetchFilteredDocs = async () => {
    const filterData = parseAttrValParams( attrValParams );
    setFilterSelected(filterData); // set form default value
    const filterQuery = dataToQueryFormatter(filterData, filterMenuTaxonomyTypes);
    // filterQuery.deleted = { ne: true };
    let filteredDocs = await ExtendedModel.filterByAttributes(filterQuery);
    filteredDocs = filteredDocs.filter(doc => !doc.data?.deleted);
    // console.log(filteredDocs)
    setFilteredDocs(filteredDocs);
  };

  const getPageTitle = (suffix) => {
    // Resto de tu lógica para obtener el título de la página
    // retornar suffix + key1: value1 / key2: value2 / key3:value3
    const attrValPairs = attrValParams.split("/");
    const attrValStrings = [];
    for (let i = 0; i < attrValPairs.length; i += 2) {
      const attr = attrValPairs[i];
      const val = attrValPairs[i + 1];
      attrValStrings.push(`${attr}: ${val}`);
    }
    return suffix + " | " + attrValStrings.join(" / ");
  };

  const onFormClear = () => {
    navigate(defaultPath);
  }

  const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.brand.name}
      className="ml-8 md:px-6 text-gray-500 hover:text-gray-600 md:text-lg lg:text-xl text-sm cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
  </>)

  return (
    <LayoutPublic HeaderMenu={HeaderMenu}>
      <MetaTags>
        <title>{getPageTitle(config.projectName)}</title>
        <meta name={getPageTitle()} content={config.projectName} />
      </MetaTags>

      {/* {filteredDocs.length ? filteredDocs.map((product) => (
        <div key={product.id}>{product.name}</div>
      )) : ''} */}

      <div>
        {/* filter menu */}
        <div className="pb-16">
          {filterData ? (
            isMinBreakpointActive('lg') ? (
              // lg
              <div className="mt-5 lg:px-12 xl:px-24 2xl:px-48">
                <EntityFilterMenuDisplay
                  entitySlug={entitySlug}
                  filterMenuSlug={filterMenuSlug}
                  filterData={filterData}
                  onFormClear={onFormClear}
                  style={'search-expanded'}
                  defaultPath={defaultPath}
                  Components={{
                    ResultsContent: () => (
                      filteredDocs?.length ? (
                        <div className="">
                          <ItemThumbGrid items={filteredDocs} entitySlug={entitySlug} />
                        </div>
                      ) : null
                    )
                  }}
                />
              </div>
            ) : (
              // sm
              <>
                <div className="mt-5">
                  <EntityFilterMenuDisplay
                    entitySlug={entitySlug}
                    filterMenuSlug={filterMenuSlug}
                    filterData={filterData}
                    onFormClear={onFormClear}
                    style={'search-collapsible'}
                    defaultPath={defaultPath}
                  />
                </div>
                {/* results list */}
                {filteredDocs?.length ? (
                  <div className="mt-6">
                    <ItemThumbGrid items={filteredDocs} entitySlug={entitySlug} />
                  </div>
                ) : null}
              </>
            )
          ) : null}
        </div>

        <div className="-mx-5">
          <CtaContactUsBlock data={blocks.ctaContactUs} />
          <CtaImgFullBlock data={blocks.ctaFooter} />
        </div>
      </div>


    </LayoutPublic>
  );
}
