import React, { useState } from 'react';
import { FaMapMarkedAlt  } from "react-icons/fa";


const ActionsDropdown = (props) => {
  let { 
    label,
    classes,
    children
  } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`relative inline-block text-left ${classes?.actionsContainer}`}>
      <button
      onClick={toggleDropdown}
      className={`text-sm px-3 py-1 text-center text-stone-700 bg-stone-100 hover:bg-stone-200 font-medium rounded inline-flex items-center gap-2 ${classes?.button}`}
      type="button">
        <FaMapMarkedAlt className="text-lg" />
        <span className="text-base font-semibold">
          {label}
        </span>
      </button>
      {isOpen && (
        <div className={`z-10 absolute right-0 mt-2 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 ${classes?.actionsPopover}`} >
          {children}
        </div>
      )}
    </div>
  );
};

export default ActionsDropdown;
