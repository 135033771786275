import { collection, doc, query, where, getDocs, getDoc, updateDoc } from "firebase/firestore";
import Model from '../../libs/ModelClass';

export default class FilterMenu extends Model {
  static collectionName = 'filterMenu';

  constructor(data) {
    super(data);
  }
}
