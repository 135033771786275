import { FaQuoteLeft, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useState } from "react";
import { AddClassWhenVisible } from "../AddClassWhenVisible";

export default function CustomerReviewsBlock({ data }) {
  const [startIndex, setStartIndex] = useState(0);
  const testimonialsPerPage = 2;
  const totalPages = Math.ceil(data.items.length / testimonialsPerPage);

  const handleNext = () => {
    const newIndex = startIndex + testimonialsPerPage;
    setStartIndex(newIndex >= data.items.length ? 0 : newIndex);
  };

  const handlePrevious = () => {
    const newIndex = startIndex - testimonialsPerPage;
    setStartIndex(newIndex < 0 ? data.items.length - testimonialsPerPage : newIndex);
  };

  return (
    <div className="px-4 pb-40 pt-40 text-black">
      <div className="container-width">
        {/* Section description */}
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="text-center">
            <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
            <h3 className="text-4xl md:text-4xl font-brand-mulish text-brand-red font-bold">{data.subtitle}</h3>
            <p className="mt-4 mx-auto max-w-xs px-4 pb-4 text-lg font-brand-mulish">{data.content}</p>
          </div>
        </AddClassWhenVisible>
        {/* Testimonials */}
        <div className="mt-8">
          <div className={`grid gap-6 ${totalPages > 1 ? 'lg:grid-cols-2' : ''}`}>
            {data.items.slice(startIndex, startIndex + testimonialsPerPage).map((item, index) => (
              <div key={index} className="review-item p-4 border-brand-yellow border bg-white">
                <div className="review-title text-lg md:text-xl font-semibold flex items-center leading-tight font-brand-mulish">
                  <FaQuoteLeft className="text-xl mr-2.5 ml-7 w-7 text-brand-yellow" />
                  <h3 className="text-brand-red">{item.title}</h3>
                </div>
                <div className="mt-4 md:text-lg font-brand-mulish">{item.content}</div>
              </div>
            ))}
          </div>
          {totalPages > 1 && (
            <div className="flex justify-center gap-4 text-xl mt-8">
              <button
                className={`text-brand-red hover:underline ${startIndex === 0 ? 'text-red-300' : ''}`}
                onClick={handlePrevious}
                disabled={startIndex === 0}
              >
                <FaArrowLeft />
              </button>
              <button
                className={`text-brand-red text-xl hover:underline ${startIndex >= data.items.length - testimonialsPerPage ? 'text-red-300' : ''}`}
                onClick={handleNext}
                disabled={startIndex >= data.items.length - testimonialsPerPage}
              >
                <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
