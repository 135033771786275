import VisibilitySensor from 'react-visibility-sensor';
// import { useData } from "../context/DataContext";
import { priceFormat, getImageURL } from "../libs/utils";
import EntityDocView from '../components/EntityDocView';
import gps from './DataTypes/gps';
import EntityGetOneDocument from './EntityGetOneDocument';
import { FaPhone } from 'react-icons/fa';
import ContactBTN from "../components/ContactBTN";


export default function ItemDetailView({ item, entitySlug }) {
  // const {
  //   // analytics
  //   viewItem
  // } = useData();

  const onSensor = (_isVisible) => {
    if (_isVisible && item.id) {
      // viewItem(item.id); // analytics
      console.log('TODO: viewItem')
    }
  }

  return (
    item ? (
      <VisibilitySensor minTopValue={0} onChange={onSensor}>
        <EntityDocView
          doc={item}
          entitySlug={entitySlug}
          // classes={{}}
          render={({ ViewData, mainAttr, mainImgAttr }) => (<>

            <div className="lg:flex">
              <ViewData field="fotos" classes={{ fieldLabel: "hidden", fieldContainer: 'lg:w-3/5' }} />

              <div className="mb-4 text-center lg:w-2/5 place-content-center">
                <div className="lg:mx-4 bg-gray-100 shadow-sm rounded-2xl px-1.5 pt-1.5 pb-3">
                  <ViewData field="nombre" classes={{ fieldLabel: "hidden", fieldContainer: 'text-3xl font-semibold mt-2' }} />
                  <ViewData field="precio" classes={{ fieldLabel: "hidden", fieldContainer: 'text-xl text-green-600 mt-1 mb-1' }} decorator={priceFormat} />  
                  <ViewData field="trato"  classes={{ fieldLabel: "hidden", fieldContainer: 'inline-block px-2 py-0.5 rounded-full bg-gray-500 text-sm text-white' }} />
                  <ViewData field="tipo"   classes={{ fieldLabel: "hidden", fieldContainer: 'inline-block px-2 py-0.5 ml-1 rounded-full bg-gray-200 mt-1' }} />
                  <div className="mt-3">
                    <ViewData field="direccion" classes={{ fieldContainer: 'mt-2 mb-2' }} />   
                  </div>
                </div>
                <div className="mt-4 mb-12 md:mb-8 lg:mx-4 bg-gray-100 shadow-sm rounded-2xl p-4 text-justify">
                  <ViewData field="descripcion" classes={{ fieldLabel: "hidden", fieldContainer: '' }} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 border-y-2 py-2 mt-6 gap-2 text-center ">
              <ViewData field="dormitorios" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="banos" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="estacionamientos" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
            </div>

            <div className="grid grid-cols-2 py-2 w-full gap-2 text-center">
              <ViewData field="medidaTerreno" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="medidaConstruccion" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="medidaFrente" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="medidaFondo" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
            </div>
            
            <div className="grid grid-cols-2 border-y-2 py-2 gap-2 text-center">
              <ViewData field="pileta" classes={{ fieldContainer: ' ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="aceptaMascotas" classes={{ fieldContainer: ' ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="aireAcondicionado" classes={{ fieldContainer: ' ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="estacionamientoBajoTecho" classes={{ fieldContainer: 'ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
              <ViewData field="estiloDeVida" classes={{ fieldContainer: ' ml-3 lg:ml-5 text-gray-800 mt-2 w-full' }} />
            </div>

            <div className="grid md:grid-cols-2 gap-4 mt-12 mb-10 md:mb-2 text-center">
              <ViewData field="ubicacionGPS" classes={{ fieldContainer: 'space-y-3', mapContainer: 'h-64 md:h-80 lg:h-96', mapRender: '', mapHeight: 'h-64 md:h-80 lg:h-96' }}/>

              {/* hacer <CtaContactOfDoc data={ {docId, entitySlug} } /> */}
              <EntityGetOneDocument entitySlug={'asesores'} docId={item?.data?.asesor}>
                {({ doc, mainAttr, mainImgAttr }) => (
                  <div className="grid justify-center mt-10 md:mt-0">
                    <h3 className="font-semibold text-center mb-3">Contactar Asesor</h3>
                    {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
                      <div className="grid grid-cols-1 w-[220px] shadow-md rounded-b ">
                        <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
                        className="rounded-t h-[270px] w-full object-cover" /> {/* image xs */}
                        <div className=" items-center">
                          <h3 className="text-lg mt-2" >{doc.data[mainAttr]}</h3>
                          <h3 className="mb-4" >{doc.data.celular}</h3>

                          <div className="flex flex-col items-center pb-8">
                            <a target='_blank' href={`tel:+${doc.data.celular} `} className="flex text-lg bg-red-500 hover:scale-105 hover:bg-red-600 mx-auto px-4 py-2 text-white shadow-md rounded-full items-center mb-2"><FaPhone className="mr-1.5"/>Llamar</a>
                            <ContactBTN number={doc.data.celular} message={`Hola, estoy interesado en el inmueble de este enlace: ${window.location.href} `} label="Contactar" />
                          </div>
                        </div>
                      </div>
                    ) : null}                      
                  </div>
                )}
              </EntityGetOneDocument>
            </div>
          </>)}
        />
      </VisibilitySensor>
    ) : ''
  )
}