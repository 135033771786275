import {
  FormField,
  FormFieldSelect,
} from '../Form';

const FilterRenderSelectOneEntityDocument = (props) => {
  const {
    values,
    filter,
    DataTypeRender
  } = props;
  const _filter = (filter || values?.filter || values?.taxonomyType?.filter);
  const mode  = _filter?.mode || 'simpleInput';

  const renderComponent = () => {
    switch (mode) {
      case 'simpleInput':
        return (
          <DataTypeRender {...props} />
        );
      default:
        return null;
    }
  };

  return renderComponent();
};

const FilterCreatorSelectOneEntityDocument = ({ form, values, onChange }) => {
  const handleValueChange = (newValue) => {
    const nameSlug = values?.taxonomyType?.nameSlug;
    form.change(`filtersSelected.${nameSlug}`, null);
    onChange && onChange(newValue);
  };
  if (values?.filter?.mode !== 'simpleInput') {
    form.change('filter.mode', 'simpleInput');
  }
  
  return (<>
    <FormFieldSelect
      name="filter.mode"
      title="Modo"
      placeholder="Modo"
      options={[
        { value: 'simpleInput', label: 'Simple' },
      ]}
      onSelect={(newMode) => handleValueChange({ mode: newMode })}
    />
    <div className="mt-4 p-2 bg-white rounded">
      <label className="mb-2 pb-2 block font-semibold border-b border-gray-200">Estilos</label>
      <FormField
        name={`filter.style.classes.filterContainer`}
        showLabel={true}
        title="Contenedor del filtro"
        placeholder="Clases"
        className="inline-block"
        type="text"
      />
    </div>
  </>);
};

export {
  FilterRenderSelectOneEntityDocument,
  FilterCreatorSelectOneEntityDocument
};