import { useState } from 'react';
import _ from 'lodash';
import {
  FormField,
  FormFieldSelect,
  FormFieldFilterRangeCreator,
} from '../Form';

let options = [
  { value: 'start', label: 'Comienza con' },
  { value: 'end', label: 'Termina con' },
  { value: 'equal', label: 'Igual que' },
  { value: 'gte', label: 'Mayor o igual que' },
  { value: 'lte', label: 'Menor o igual que' },
];

const FilterRenderText = (props) => {
  const {
    values,
    filter,
    taxonomyType,
    overrideParams = {},
    DataTypeRender
  } = props;
  const _taxonomyType = (taxonomyType || values?.taxonomyType || values);  // use value as fallback
  const _filter = _.assign({}, _taxonomyType?.filter, values?.filter, filter);
  const mode  = _filter?.mode || 'simpleInput';
  const nameSlug = _taxonomyType?.nameSlug;
  const baseName = nameSlug;
  const title  = _taxonomyType?.name;
  const param = { ..._taxonomyType?.param, ...overrideParams };
  const style = param?.style;

  const renderSingleOperator = () => (<>
    {param?.showLabel && <label className="font-semibold">{title}</label>}
    <div className='flex flex-row place-items-center gap-2'>
      <FormFieldSelect
        name={`${baseName}.operator`}
        showLabel={false}
        placeholder="Operador"
        options={options}
      />
      <FormField
        name={`${baseName}.value`}
        showLabel={false}
        placeholder="Texto"
        type="text"
      />
    </div>
  </>);

  const renderSingleRange = () => (
    <div className={`pb-3 ${style?.classes?.fieldContainer || ''}`}>
      {param?.showLabel && (
        <label className={`font-semibold ${style?.classes?.fieldLabel}`}>{title}</label>
      )}
      <div className={`grid grid-cols-2 place-items-center gap-2 ${style?.classes?.fieldInput || ''}`}>
        <FormField
          name={`${baseName}.startValue`}
          showLabel={false}
          placeholder="Desde"
          type="text"
          classes={{fieldContainer: 'w-full', fieldInput: 'w-full'}}
        />
        <FormField
          name={`${baseName}.endValue`}
          showLabel={false}
          placeholder="Hasta"
          type="text"
          classes={{fieldContainer: 'w-full', fieldInput: 'w-full'}}
        />
      </div>
    </div>
  );

  const renderRangeSelect = () => (<>
    <FormFieldSelect
      name={`${baseName}.rangeIndex`}
      title={title}
      placeholder={title}
      options={_filter?.ranges?.map((range, index) => ({ 
        value: index, 
        label: range.rangeName
      }))}
      {...param}
      {...style}
      {...props}
    />
  </>);

  const renderComponent = () => {
    switch (mode) {
      case 'simpleInput':
        return (
          <DataTypeRender {...props} />
        );
      case 'singleOperator':
        return renderSingleOperator();
      case 'singleRange':
        return renderSingleRange();
      case 'rangeSelect':
        return renderRangeSelect();
      default:
        return null;
    }
  };

  return renderComponent();
};

const FilterCreatorText = ({ form, values, onChange }) => {
  const handleValueChange = (newValue) => {
    const nameSlug = values?.taxonomyType?.nameSlug;
    form.change(`filtersSelected.${nameSlug}`, null);
    onChange && onChange(newValue);
  };
  
  return (<>
    <FormFieldSelect
      name="filter.mode"
      title="Modo"
      placeholder="Modo"
      options={[
        { value: 'simpleInput', label: 'Simple' },
        { value: 'singleOperator', label: 'Operador' },
        { value: 'singleRange', label: 'Selección de Rango' },
        { value: 'rangeSelect', label: 'Rangos predefinidos' },
      ]}
      onChange={(newMode) => handleValueChange({ mode: newMode })}
    />
    {values.filter?.mode === 'rangeSelect' ? (
      <FormFieldFilterRangeCreator 
        name="filter.ranges"
        title="Creador de rangos predefinidos"
        FilterRangeForm={FilterRangeTextForm} 
        className="p-2 bg-white rounded"
      />
    ) : null}
    <div className="mt-4 p-2 bg-white rounded">
      <label className="mb-2 pb-2 block font-semibold border-b border-gray-200">Estilos</label>
      <FormField
        name={`filter.style.classes.filterContainer`}
        showLabel={true}
        title="Contenedor del filtro"
        placeholder="Clases"
        className="inline-block"
        type="text"
      />
    </div>
  </>);
};

const FilterRangeTextForm = ({ items, onChange }) => {
  const [ranges, setRanges] = useState(items);

  const handleRangeChange = (index, newValue) => {
    const updatedRanges = [...ranges];
    updatedRanges[index] = newValue;
    setRanges(updatedRanges);
    if (onChange) {
      onChange(updatedRanges);
    }
  };

  const handleAddRange = () => {
    const newRange = {
      rangeName: '',
      startOperator: '',
      startValue: '',
      endOperator: '',
      endValue: '',
    };
    const updatedRanges = [...ranges, newRange];
    setRanges(updatedRanges);
    if (onChange) {
      onChange(updatedRanges);
    }
  };

  const handleRemoveRange = (index) => {
    const updatedRanges = [...ranges];
    updatedRanges.splice(index, 1);
    setRanges(updatedRanges);
    if (onChange) {
      onChange(updatedRanges);
    }
  };

  const renderRange = (range, index) => (
    <div key={index} className="my-4">
      {/* name */}
      <div className="flex items-center my-2">
        <label className="text-base font-semibold pr-2">
          Rango #{index + 1}
        </label>
        <input
          id={`rangeName_${index}`}
          name={`rangeName`}
          placeholder="Nombre de Rango"
          type="text"
          value={range.rangeName}
          onChange={(event) => handleRangeChange(index, { ...range, rangeName: event.target.value })}
          className="border border-gray-300 rounded px-2 py-1"
        />
      </div>
      {/* start */}
      <div className='flex flex-row place-items-center gap-2'>
        <select
          id={`startOperator_${index}`}
          name={`startOperator`}
          placeholder="Límite inicial"
          value={range.startOperator}
          onChange={(event) => handleRangeChange(index, { ...range, startOperator: event.target.value })}
          className="border border-gray-300 rounded py-1 indent-2"
        >
          <option value="">Seleccionar</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
        <input
          id={`startValue_${index}`}
          name={`startValue`}
          placeholder="Texto de inicio"
          type="text"
          value={range.startValue}
          onChange={(event) => handleRangeChange(index, { ...range, startValue: event.target.value })}
          className="border border-gray-300 rounded py-1 indent-2"
        />
      </div>
      {/* end */}
      <div className='flex flex-row place-items-center gap-2'>
        <select
          id={`endOperator_${index}`}
          name={`endOperator`}
          placeholder="Límite final"
          value={range.endOperator}
          onChange={(event) => handleRangeChange(index, { ...range, endOperator: event.target.value })}
          className="border border-gray-300 rounded py-1 indent-2"
        >
          <option value="">Seleccionar</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
        <input
          id={`endValue_${index}`}
          name={`endValue`}
          placeholder="Texto final"
          type="text"
          value={range.endValue}
          onChange={(event) => handleRangeChange(index, { ...range, endValue: event.target.value })}
          className="border border-gray-300 rounded py-1 indent-2"
        />
      </div>

      <button
        type="button"
        onClick={() => handleRemoveRange(index)}
        className="bg-red-500 text-white py-1 px-2 rounded mt-2"
      >
        Eliminar rango
      </button>
    </div>
  );

  return (
    <div className='w-full'>
      {ranges && ranges.map((range, index) => renderRange(range, index))}
      <button
        type="button"
        onClick={handleAddRange}
        className="bg-blue-500 text-white py-1 px-2 rounded"
      >
        Agregar rango
      </button>
    </div>
  );

};

export {
  FilterRenderText,
  FilterCreatorText,
  FilterRangeTextForm
};