import React from 'react';
import { Field } from 'react-final-form';
import { FaMapMarkerAlt } from 'react-icons/fa';
import RawInputGPS from './RawInputGPS';
import { required, ShowError } from './formUtils';
import config from '../../config';

const FormFieldGPS = ({
  name,
  title,
  className,
  required: isRequired = false,
}) => {
  const validate = isRequired ? required : undefined;

  const handleButtonClick = ({ input }) => {
    if (input.value) {
      input.onChange(null); // Set input value to null if it has a value
    } else {
      input.onChange(config.defaultLocationGPS || { lat: 0, lng: 0 }); // Set input value to default location or specific value
    }
  };

  return (
    <div className={`pb-3 ${className}`}>
      <Field
        name={name}
        validate={validate}
      >
        {({ input, meta }) => (
          <div className="relative">
            <label className="flex w-full place-content-between items-center">
              <span
                className={`font-semibold mr-3 ${
                  meta.touched && meta.error
                    ? 'text-red-600'
                    : 'text-gray-900'
                }`}
              >
                {title}
                {isRequired ? '*' : ''}
              </span>
              {/* Botón para seleccionar o borrar ubicación */}
              <button
                type="button"
                onClick={() => handleButtonClick({ input })}
                className="bg-white rounded border border-gray-300 px-4 py-2 flex flex-row gap-2 items-center"
              >
                <FaMapMarkerAlt className="text-gray-500" />
                <span className="text-sm">
                  {input.value ? 'Borrar ubicación' : 'Seleccionar ubicación'}
                </span>
              </button>
            </label>
            {input.value ? (
              <div className="mt-2">
                <RawInputGPS value={input.value} onChange={input.onChange} />
              </div>
            ) : null}
            {/* Render error if any */}
            {meta.touched && meta.error && (
              <div className="text-red-600" style={{ fontSize: 12 }}>
                {meta.error}
              </div>
            )}
            {/* Error */}
            <ShowError name={name} input={input} meta={meta} required={isRequired} />
          </div>
        )}
      </Field>
    </div>
  );
};

export default FormFieldGPS;
