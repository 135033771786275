import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toast from 'react-hot-toast';
import ModalAlert from './ModalAlert';
import SectionCrudForm from './SectionCrudForm';
import { FormField } from '../components/Form';
import { useModule } from "../context/ModuleContext";


function SectionCrudModel({
  model, // ModelClass constructor
  entitySlug,
  editStyle, // onsite modal route
  reorder,
  title,
  showToast = true,
  showDeleted = false,
  // validatiion
  fieldsRequired,
  onValidation = (() => null),
  // callbacks 
  fetchItems = null,
  handleBeforeSave = (() => null),
  onDelete = (() => null),
  onFetch = (() => null),
  navigateTo = (() => null),
  // UI
  ExtraActions,
  listStyle,
  ListItem,
  ListBtns = (() => null),
  FormSection = null,
  FormInputFields = null,
  // Classes
  classNameFormSection = ''
}) {
  const [docs, setDocs] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);
  const navigate = useNavigate();
  const { isAllowed } = useModule();
  // List
  fetchItems = fetchItems || (async () => {
    let docs = await model.getAll();
    if (!showDeleted) {
      docs = docs.filter(doc => !doc.data?.deleted);
    }
    // TODO: hacer configurable el fieldName desde SectionCrudModel y SectionCrudObject
    if (reorder) {
      model.sortDocs(docs, 'sort');
    } else {
      model.sortDocs(docs, 'createdAt DESC', 'dateString');
    }
    setDocs(docs);
    onFetch && onFetch(docs);
  });
  ListItem = ListItem || (({ doc }) => (<>
    <span className="">{doc?.data?.name}</span>
  </>));
  // Form
  FormSection = FormSection || SectionCrudForm;
  FormInputFields = FormInputFields || (() => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
  </>));

  useEffect(() => {
    fetchItems(setDocs);
  }, []);

  const refreshList = async () => {
    await fetchItems(setDocs);
  };

  const handleSave = async (doc) => {
    if (reorder) {
      doc.sort = (doc.sort >= 0) ? doc.sort : docs.length;
    }
    handleBeforeSave(doc);
    await model.createOrUpdate(doc);
    refreshList();
    setOpenSelector(false);
    setSelectedDoc(null);
  };

  const handleEdit = (doc) => {
    setSelectedDoc(doc);
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      navigate(navigateTo(doc));
    }
  };

  const handleDelete = (doc) => {
    setShowDeleteConfirmation(true);
    setSelectedDoc(doc);
  };

  const confirmedDelete = async () => {
    onDelete(selectedDoc);
    await selectedDoc.delete();
    refreshList();
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
    showToast && toast.error('Se ha eliminado el documento');
  };

  const cancelDelete = () => {
    setShowDeleteConfirmation(false);
    setSelectedDoc(null);
  };

  const handleAdd = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      setSelectedDoc({});
      setOpenSelector(true);
    } else if (editStyle === 'route') {
      setSelectedDoc({});
      navigate(navigateTo());
    }
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;
    const newOrder = Array.from(docs);
    const [movedItem] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedItem);
    if (reorder) {
      setDocs(newOrder);
      await model.saveSort(newOrder);
      showToast && toast.success('Se ha actualizado el orden');
    }
  };

  return (
    <div className="section-crud-model" key="esteeeeeeee">
      <div className="flex flex-row place-content-between items-center place-items-center">
        {title ? (
          <h1 className="text-xl font-semibold">{title}</h1>
        ) : ''}
        {isAllowed(entitySlug, ['create']) ? (
          <button onClick={handleAdd} className="text-left bg-green-600 text-green-100 shadow-md rounded px-3 p-1 text-sm">
            + Agregar
          </button>
        ) : null}
        {ExtraActions ? (
          <ExtraActions {...{refreshList, toast}} />
        ) : null}
      </div>
      {openSelector && selectedDoc && !selectedDoc.id && (
        <div className={`my-5 ${classNameFormSection}`}>
          {/* form add */}
          <FormSection
            model={model}
            editStyle={editStyle}
            doc={selectedDoc}
            onClose={() => {
              setOpenSelector(false);
              setSelectedDoc(null);
            }}
            onSave={handleSave}
            fieldsRequired={fieldsRequired}
            onValidation={onValidation}
            FormInputFields={FormInputFields}
            showToast={showToast}
          />
        </div>
      )}
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="docs">
          {(provided) => (
            <ul className={`grid grid-cols-1 gap-2 ${docs && docs.length ? 'my-5' : ''}`} {...provided.droppableProps} ref={provided.innerRef}>
              {docs && docs.map((doc, index) => (
                <Draggable key={doc.id} draggableId={doc.id} index={index}>
                  {(provided) => (
                    <>
                      {/* form edit */}
                      {openSelector && selectedDoc && selectedDoc.id === doc.id ? (
                        <div ref={provided.innerRef} {...provided.draggableProps} className={`mb-3 ${classNameFormSection}`}>
                          <FormSection
                            model={model}
                            editStyle={editStyle}
                            doc={selectedDoc}
                            onClose={() => {
                              setOpenSelector(false);
                              setSelectedDoc(null);
                            }}
                            onSave={handleSave}
                            fieldsRequired={fieldsRequired}
                            onValidation={onValidation}
                            FormInputFields={FormInputFields}
                            showToast={showToast}
                          />
                          <div {...provided.dragHandleProps} className="hidden"></div>
                        </div>
                      ) : (
                        <>
                          {/* list */}
                          {provided.innerRef && (
                            <li
                              {...provided.draggableProps}
                              className="bg-white rounded px-2 py-2 border border-gray-300"
                              ref={provided.innerRef}
                            >
                              <div className="flex place-content-stretch mb-2">
                                <ListItem doc={doc} style={listStyle }/>
                              </div>
                              <div className='relative w-full'>
                                <ListBtns doc={doc} />
                                {isAllowed(entitySlug, ['update']) ? (
                                  <button type="button" onClick={() => handleEdit(doc)}
                                  className="mr-2 px-2.5 py-0.5 text-xs bg-gray-200 text-gray-600 rounded-md">
                                    Editar
                                  </button>
                                ) : null}
                                {isAllowed(entitySlug, ['delete']) ? (
                                  <button type="button" onClick={() => handleDelete(doc)}
                                  className="px-2.5 py-0.5 text-xs bg-red-200 text-red-900 rounded-md">
                                    Eliminar
                                  </button>
                                ) : null}
                                {reorder && isAllowed(entitySlug, ['update']) ? (
                                  <div {...provided.dragHandleProps}
                                  className="block absolute top-0 right-0 px-2.5 py-0.5 text-xs bg-blue-500 text-white rounded-md">
                                    Mover
                                  </div>
                                ) : (
                                  <div {...provided.dragHandleProps} className="hidden"></div>
                                )}
                              </div>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>

      {showDeleteConfirmation && (
        <ModalAlert
          text="¿Estás seguro de que deseas eliminar este elemento?"
          onConfirm={confirmedDelete}
          onCancel={cancelDelete}
        />
      )}
    </div>
  );
}

export default SectionCrudModel;
