import _ from 'lodash';
import { typeConfigs } from "../DataTypes";

const RenderFilter = (props) => {
  const typeConfig = typeConfigs[props?.taxonomyType?.type];
  
  if (!typeConfig || !typeConfig.RenderFilter) {
    return null;
  }

  return typeConfig.RenderFilter(props);
};

const FormFiltersRender = (props) => {
  let {
    form,
    values,
    filterMenuTaxonomyTypes,
    renderFilterParams,
    showClearBtns = true,
    classes
  } = props;

  const getFilterClasses = (filterPopulated) => {
    let styleDef = _.assign({}, filterPopulated?.taxonomyType?.style, filterPopulated?.taxonomyType?.filter?.style, filterPopulated?.filter?.style);
    return `
      ${styleDef.classes?.filterContainer || ''} ${props?.classes?.filterContainer || ''} ${(values?.hasOwnProperty(filterPopulated?.taxonomyType?.nameSlug)) ? classes?.selectedFilter : ''}
    `;
  }

  return (
    filterMenuTaxonomyTypes ? (
      filterMenuTaxonomyTypes.map((filterPopulated) => (
        filterPopulated?.id && filterPopulated?.taxonomyType ? (
          // filter container
          <div key={filterPopulated.id}
          className={`flex flex-row items-start ${getFilterClasses(filterPopulated)}`}>

            {/* form filter fields */}
            <RenderFilter {...{
              ...filterPopulated, // adds { id, filter, taxonomyType }
              values,
              overrideParams: renderFilterParams,
            }} />

            {showClearBtns && values.hasOwnProperty(filterPopulated?.taxonomyType?.nameSlug) ? (
              <button
                type="button"
                className="ml-2 px-2 py-1 mt-1 font-bold text-xs bg-gray-200 text-gray-500 rounded-full hover:text-gray-800 inline-block"
                onClick={() => form.change(filterPopulated?.taxonomyType?.nameSlug, undefined)}
              >
                X
              </button>
            ) : ''}
          </div>
        ) : ''
      ))
    ) : ''
  );
}

export default FormFiltersRender;