import { useState } from 'react';
import _ from "lodash";
import toast from 'react-hot-toast';


export default function useCart ({ productsById, getVariantAttr })  { 
  const [ cart, setCart ] = useState({ 
    // default cart
    fields: {},
    products: [], // { id, qty }
    total: 0
  });

  const setProductToCart = ({ productId, productQty=1, alertCollection=false }) => {
    // alerts
    if (!cart.products.length) {
      toast.success(alertCollection ? 'Varios productos agregados al pedido!' : 'Producto agregado al pedido!');
      setTimeout(() => {
        toast.success('Seguí explorando!');
      }, 2000);
    }
    // verificar que haya products, si no agregarlo
    cart.products = cart.products || [];
    // verificar si hay product en products, si hay aumentarlo, si no agregarlo
    let productInPack = _.find(cart.products, { id: productId });
    if (productQty <= 0) {
      // remove
      cart.products.splice(cart.products.indexOf(productInPack), 1);
    } else {
      // set or update
      if (productInPack) {
        productInPack.qty = productQty;
      } else {
        cart.products.push({
          id: productId,
          qty: productQty
        });
      }
    }
    updateTotalPrice();
    // update state
    cart.products = [ ...cart.products ];
    setCart({ ...cart });
  }

  const getFomProductInCart = (productId, field) => {
    let productInPack = _.find(cart.products, { id: productId });
    if (productInPack) {
      return field ? productInPack[field] : productInPack;
    }
  }

  const isProductInCart = (productId, type) => {
    let productInCart = false;
    if (type === 'main') {
      _.forEach(cart.products, (product) => {
        if (getVariantAttr('parentId', product.id) === productId) {
          productInCart = true;
          return true;
        }
      })
    }
    if (type === 'variant') {
      _.forEach(cart.products, (product) => {
        if (product.id === productId) {
          productInCart = true;
          return true;
        }
      })
    }
    return productInCart;
  }

  const getProductsInCart = () => {
    if (cart.products.length) {
      const products = cart.products.map(({ id, qty }) => {
        const product = productsById[id];
        product.selectedQty = qty;
        return product;
      });
      return products;
    }
    return [];
  }

  const updateTotalPrice = () => {
    cart.total = 0;
    cart.products.length && cart.products.map(({ id, qty }) => {
      cart.total += getVariantAttr('price', id) * qty;
    });
  }

  const setCartFields = ( fields ) => {
    cart.fields = fields;
    setCart([ ...cart ]);
  }

  const clearCart = () => {
    setCart({ products: [], total: 0, fields: {} });
  }
  
  return {
    cart, 
    setCart,
    setProductToCart,
    getFomProductInCart,
    getProductsInCart,
    isProductInCart,
    setCartFields,
    clearCart
  };
}