import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { FaFacebook, FaWhatsapp, FaInstagram, FaBars, FaRegWindowClose } from 'react-icons/fa';
import { useAuth } from "../context/AuthContext";
import config from "../config";
import { useScrollTo } from "../libs/utils";
import { animateScroll as scroll } from 'react-scroll';

export function LayoutPublic({ HeaderMenu, mainSectionClass, children }) {
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  const ref = useRef();
  const location = useLocation();
  const { scrollToDelay } = useScrollTo();
  const [navbar, setNavbar] = useState(false)


  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(ref.current.getBoundingClientRect().height);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    scrollToDelay('main');
  }, [location.pathname]);


  return (
    <div id="main" className="w-full text-black">
      {/* header */}
      <header id="menu" ref={ref} className="fixed w-full top-0 bg-white shadow-sm md:shadow-md z-50 overflow-hidden">

        <div className="grid grid-cols-2 lg:grid-cols-3 px-1 md:py-2 py-1.5 lg:max-w-3xl xl:max-w-6xl mx-auto items-start lg:items-center justify-items-start lg:justify-items-center">
          {/* links de la izquierda */}
          {HeaderMenu ? (
            <div className="text-xs hidden lg:block">
              <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
              {user ? (
                <Link
                className="bg-zinc-200 lg:hidden ml-2 hover:bg-zinc-300 rounded py-1.5 px-1.5 text-black text-sm"
                to="/admin">
                  Admin
                </Link>
              ) : ''}
            </div>
          ) : ''}

          {/* logo central */}
          <div className="">
            <Link
              to="/"
              title={config.brand.name}
              className="flex justify-center"
              onClick={() => scroll.scrollToTop()}
            >
              <img src="/logo-color.svg" alt={config.brand.name} className="h-10 lg:h-12 md:h-14 ml-3 lg:ml-0 mb-1 " />
            </Link>
          </div>

          {/* botón de menú móbil hamburger */}
          <div className="lg:hidden justify-self-end mr-1 mt-1">
              <button
                className="p-2 text-gray-700 outline-none"
                onClick={() => setNavbar(!navbar)}
              >
                {navbar ? <FaRegWindowClose size={22} /> : <FaBars size={20} />}
              </button>
            </div>

          {/* links sociales */}
          <div className="text-gray-700 text-center hidden lg:block">
            <div className="flex justify-center space-x-4 xl:space-x-5 text-xl pr-5 ">
              <a target='_blank'  href="https://www.facebook.com/tierraguaraniInmobiliaria" className="hover:text-blue-500" rel="noreferrer"><FaFacebook /></a>
              <a target='_blank' href="https://api.whatsapp.com/send?phone=595984577585&text=%C2%A1Hola!%20Quisiera%20solicitar%20informaci%C3%B3n%20sobre%20un%20lote." className="hover:text-green-500" rel="noreferrer"><FaWhatsapp/></a>
              <a target='_blank' href="https://www.instagram.com/tginmobiliaria_/" className="hover:text-brand-red" rel="noreferrer"><FaInstagram/></a>
            </div>
          </div>
        </div>
        
        {/* menu móbil desplegable  */}
        <div
          className={`pb-3 md:hidden border-b border-gray-400 bg-white/[.90] ${
            navbar ? "block" : "hidden"
          }`}
        >
          <div className="">
          {HeaderMenu ? (
            <div className="text-xs flex flex-col space-y-2 lg:hidden pt-3 pb-2 border-t w-full border-gray-400">
              <HeaderMenu menuHeight={menuHeight} ></HeaderMenu>
              {user ? (
                <Link
                className="bg-zinc-200 md:hidden hover:bg-zinc-300 mx-6 text-center w-16 rounded py-1.5 px-1.5 text-black text-sm"
                to="/admin">
                  Admin
                </Link>
              ) : ''}
            </div>
          ) : ''}
          </div>
        </div>
      </header>
      
      {/* content */}
      <section className="layout-main-section-container min-h-screen">
        <div className={`layout-main-section z-10 min-h-screen ${mainSectionClass}`}>
          {children}
          {/* <div className="max-w-none -mx-5 flex place-content-center pt-[100px] pb-[150px] relative overflow-hidden">
            <img src="/[logo].png" alt="logo" className="max-w-none spin-slow object-cover w-[600px] xs:w-[700px] lg:w-[800px] md:w-[800px]" />
          </div> */}
        </div>
      </section>
    </div>
  );
}
