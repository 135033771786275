import { useState } from 'react';
import { AddClassWhenVisible } from '../AddClassWhenVisible';

export default function CtaAccordionBlock({ data }) {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="px-4 pt-20 pb-40 text-black text-center">
      <div className="container-width">
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="pb-5 md:pt-10 px-4">
            <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
            <h3 className="text-4xl md:text-4xl font-brand-mulish text-brand-red font-bold">{data.subtitle}</h3>
          </div>
        </AddClassWhenVisible>

        <div className="mt-10 flex justify-center gap-4 flex-wrap font-brand-mulish">
          {data.items.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer bg-white border-brand-yellow border py-1.5 md:py-2 shadow-sm hover:shadow-lg rounded-sm ${index === activeIndex ? 'border-brand-yellow border-2 lg:border-4' : ''}`}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="accordion-title px-3 md:px-4 font-semibold cursor-pointer font-brand-mulish">
                {item.title}
              </div>
            </div>
          ))}
        </div>

        {data.items.map((item, index) => (
          <div key={index} className={`mt-4 ${index === activeIndex ? 'block' : 'hidden'}`}>
            {index === activeIndex && (
              <div className=" border-brand-yellow bg-white border-2 lg:border-4 py-4 mx-3 shadow-sm rounded-sm">
                <div className="accordion-content px-2 py-2 md:py-10 font-brand-mulish">
                  <p className='text-lg md:text-xl px-2'>
                    {item.content}  
                  </p>
                  <div className='flex gap-4 md:gap-6 lg:gap-10 mt-4 lg:mt-6 justify-center items-center'>
                    <a href={item.ctaUrl} target="_blank" className="
                      px-3 md:px-6 lg:px-8 py-2 md:py-2.5 lg:py-4 flex items-center rounded-sm md:uppercase bg-yellow-600 hover:bg-brand-yellow text-white text-md font-bold"
                      rel="noreferrer">
                      {item.ctaTitle}
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
