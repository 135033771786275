import VisibilitySensor from 'react-visibility-sensor';
import { useNavigate } from "react-router-dom";
// import { useData } from "../context/DataContext";
import { priceFormat, getImageURL } from "../libs/utils";
import EntityDocView from './EntityDocView';
import { FaBed, FaBath, FaCarAlt } from "react-icons/fa";

export default function ItemThumbGrid({ items, entitySlug }) {
  const navigate = useNavigate();

  // const {
  //   // analytics
  //   viewItem
  // } = useData();

  const onSensor = (itemId) => (
    (_isVisible) => {
      if (_isVisible && itemId) {
        // viewItem(itemId); // analytics
        console.log('TODO: viewItem')
      }
    }
  )

  const goToItem = (itemId) => {
    navigate(`/${entitySlug}/${itemId}`);
  }

  return (
    items ? (
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-5 items-start">
        {items ? items.map((item) => (
          <VisibilitySensor key={item.id} minTopValue={0} onChange={onSensor(item.id)}>
            <EntityDocView
              doc={item}
              entitySlug={entitySlug}
              classes={{
                fieldLabel: "hidden"
              }}
              render={({ ViewData, mainAttr, mainImgAttr }) => (

                <button className="flex-1 border border-gray-300 rounded-xl overflow-hidden shadow-md hover:shadow-xl block relative grow" onClick={() => goToItem(item.id)}>
                  {/* image xs */}
                  <img src={item?.data && item.data[mainImgAttr] && item.data[mainImgAttr].length && getImageURL(item.data[mainImgAttr][0], 'xs')} 
                    className="h-[150px] w-full object-cover" 
                  /> 
                  {/* data */}
                  <div className="bg-white pb-1">
                    <div className="my-2 px-1">
                      <ViewData field="nombre" classes={{ fieldContainer: 'px-1 md:text-lg lg:text-xl font-semibold leading-tight' }} />
                      <ViewData field="precio" classes={{ fieldContainer: 'text-sm sm:text-md md:text-lg text-green-600 my-1 lg:my-0' }} decorator={priceFormat} />
                      <ViewData field="trato"  classes={{ fieldContainer: 'inline-block px-2 py-0.5 rounded-full bg-gray-500 text-xs text-white' }} />
                      <ViewData field="tipo"   classes={{ fieldContainer: 'inline-block px-2 py-0.5 rounded-full bg-gray-200 text-xs ml-2 mt-1' }} />
                      <div className="flex text-gray-500 justify-center place-content-center gap-3 my-2">
                        {item?.data?.dormitorios ? (
                          <div className='inline-flex gap-1 items-center'>
                            <FaBed/>
                            <h3 className="text-sm">{item.data.dormitorios} </h3>
                          </div>
                        ) : null }
                        {item?.data?.banos ? (
                          <div className='inline-flex gap-1 items-center'>
                            <FaBath className='w-[14px]'/>
                            <h3 className="text-sm">{item.data.banos}</h3>
                          </div>
                        ) : null }
                        {item?.data?.estacionamientos ? (
                          <div className='inline-flex gap-1 items-center'>
                            <FaCarAlt/>
                            <h3 className="text-sm">{item.data.estacionamientos}</h3>
                          </div>
                        ) : null }
                      </div>
                    </div>
                  </div>
                </button>
                
              )}
            />
          </VisibilitySensor>
        )) : ''}
      </div>
    ) : ''
  )
}