import { useMemo, useState } from "react";
import ContactBTN from "../ContactBTN";
import config from "../../config";
import { AddClassWhenVisible } from "../AddClassWhenVisible";

export default function ContactFormBlock({ data, messageFooter, design }) {
  const [ userData, setUserData ] = useState({});
  const message = useMemo(() => `
    Hola ${config.brand.name}!\n\n
    ${userData.name ? `Mi nombre es ${userData.name}. ` : ''}\n\n
    ${userData.message + '.' || ''}
    ${messageFooter || ''}
  `, [userData]);

  let classes = {
    container: 'bg-brand-red text-white',
    formContainer: 'bg-brand-yellow'
  };
  if (design === 'alt') {
    classes = {
      container: 'bg-brand-yellow text-white',
      formContainer: 'bg-brand-red'
    };
  }

  return (
    <div className={`px-4 py-24 md:py-32 text-center ${classes.container}`}>
      <div className="container-width">
        
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="pb-5 md:pt-10 px-4">
            <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
            <h3 className="text-4xl md:text-4xl font-brand-mulish text-white font-bold">{data.subtitle}</h3>
          </div>
        </AddClassWhenVisible>
        
        <div className="mt-8 flex justify-center">
          <div className={`p-4 rounded-md shadow-lg space-y-4 ${classes.formContainer}`}>
            <label className="block">
              <div className="mb-1 text-lg font-semibold">
                Nombre
              </div>
              <input
                className={`border-lime-600 border-2 text-black h-[40px] px-2 py-1.5 rounded-md`}
                type="text"
                placeholder="Nombre"
                value={userData.name || ''}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    name: e.target.value
                  });
                }}
              />
            </label>

            <label className="block">
              <div className="mb-1 text-lg font-semibold">
                Mensaje
              </div>
              <textarea
                className={`border-lime-600 border-2 text-black h-[100px] px-2 py-1.5 rounded-md`}
                type="text"
                placeholder="Mensaje"
                value={userData.message || ''}
                onChange={(e) => {
                  setUserData({
                    ...userData,
                    message: e.target.value
                  });
                }}
              />
            </label>

            <div className="">
              <ContactBTN 
                design="small"
                className={'ml-2 mb-1'}
                label="Enviar"
                message={message}
                afterSend={() => setUserData({})}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}
