import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { animated } from 'react-spring'
import { defaultProps } from '../../constants'
import { useSlider } from '../../hooks'
import Dots from '../Dots'
import Slide from '../Slide'
import SlideIndicator from '../SlideIndicator'
import Measure from 'react-measure'
import Lightbox from 'react-image-lightbox'
import {
  SliderContainer as StyledSliderContainer,
  Overlay as StyledOverlay,
  SlideOverlay as StyledSlideOverlay,
  Slider as StyledSlider,
  Button as StyledButton,
  ButtonContainer as StyledButtonContainer
} from './Slider.css'
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { FaAngleLeft, FaAngleRight, FaExpand } from 'react-icons/fa'

const AnimatedOverlay = animated(StyledOverlay)
const AnimatedSlider = animated(StyledSlider)

export default function Slider({
  initialSlide,
  slides,
  slidesData,
  slideOverlay,
  slideOverlayBottom,
  slideIndicatorTimeout,
  activeDotColor,
  dotColor,
  showButtons,
  onResize,
  children
}) {
  const [dimensions, setDimensions] = useState({width: -1, height: -1})
  const [isOpen, setIsOpen] = useState(false);
  const [lightboxSlideIndex, setLightboxSlideIndex] = useState(0);
  const [zooming, scale, currentSlide, bind, x, onScale, setSlide] = useSlider({
    slides,
    initialSlide,
    width: dimensions.width
  })

  // reset slide number
  useEffect(() => {
    if (!slides[currentSlide]) {
      setSlide(0)
    }
  }, [slides])

  const InnerSlider = (
    <>
      <Measure
        bounds
        onResize={contentRect => {
          setDimensions(contentRect.bounds)
          onResize(contentRect);
        }}
      >
        {({ measureRef }) => (
          <StyledSliderContainer zooming={zooming} className="zoom-slider"  ref={measureRef}>
            {zooming ? (
              <AnimatedOverlay
                style={{
                  backgroundColor: scale
                    .interpolate({ range: [1, 2, 10], output: [0, 0.7, 0.7] })
                    .interpolate(opacity => `rgba(0, 0, 0, ${opacity})`),
                }}
              />
            ) : ''}

            <StyledSlideOverlay inFront={!zooming}>
              {slideOverlay && slideOverlay(currentSlide)}
              <SlideIndicator
                slideIndicatorTimeout={slideIndicatorTimeout}
                currentSlide={currentSlide}
                totalSlides={slides.length}
              />
            </StyledSlideOverlay>

            <AnimatedSlider
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...bind()}
              style={{
                transform: x.interpolate(slideX => `translateX(${slideX}px`),
              }}
            >
              {slides.map((slide, idx) => (
                // eslint-disable-next-line react/no-array-index-key
                <Slide onScale={onScale} key={idx} width={dimensions.width} zooming={zooming} isCurrent={idx === currentSlide}>
                  {slide}
                </Slide>
              ))}
            </AnimatedSlider>

            {showButtons && (
              <StyledButtonContainer inFront={!zooming}>
                {slides.length > 1 ? (
                  <StyledButton type="button" onClick={() => { setSlide((currentSlide + slidesData.length - 1) % slidesData.length) }}>
                    <FaAngleLeft />
                  </StyledButton>
                ) : ''}
                <StyledButton type="button" style={{marginLeft: '5px'}} onClick={() => { setIsOpen(true); setLightboxSlideIndex(currentSlide) }}>
                  <FaExpand />
                </StyledButton>
                {slides.length > 1 ? (
                  <StyledButton type="button" style={{marginLeft: '5px'}} onClick={() => { setSlide((currentSlide + 1) % slidesData.length) }}>
                    <FaAngleRight />
                  </StyledButton>
                ) : ''}
              </StyledButtonContainer>
            )}

            {slideOverlayBottom && slides[currentSlide] ? (
              <StyledSlideOverlay inFront={!zooming}>
                {slideOverlayBottom(currentSlide, setSlide)}
              </StyledSlideOverlay>
            ): ''}

            {slides.length > 1 ? (
              <Dots
                totalSlides={slides.length}
                currentSlide={currentSlide}
                centerDots={slides.length < 6 ? slides.length : undefined}
                dotColor={dotColor}
                activeDotColor={activeDotColor}
              />
            ) : (
              <div className="mb-2"></div>
            )}
          </StyledSliderContainer>
        )}
      </Measure>
      {isOpen && (
        <Lightbox
          mainSrc={slidesData[lightboxSlideIndex].imageUrl || slidesData[lightboxSlideIndex].url}
          nextSrc={slidesData[(lightboxSlideIndex + 1) % slidesData.length].thumbUrl || slidesData[(lightboxSlideIndex + 1) % slidesData.length].url}
          prevSrc={slidesData[(lightboxSlideIndex + slidesData.length - 1) % slidesData.length].thumbUrl || slidesData[(lightboxSlideIndex + slidesData.length - 1) % slidesData.length].url}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            let prevIndex = (lightboxSlideIndex + slidesData.length - 1) % slidesData.length
            setLightboxSlideIndex(prevIndex)
            setSlide(prevIndex)
          }}
          onMoveNextRequest={() => {
            let nextIndex = (lightboxSlideIndex + 1) % slidesData.length
            setLightboxSlideIndex(nextIndex)
            setSlide(nextIndex)
          }}
        />
      )}
    </>
  )

  return (
    children ? children({ 
      InnerSlider,
      currentSlide,
      setSlide,
      dimensions
    }) : (InnerSlider)
  )
}

Slider.propTypes = {
  /** List of slides to render */
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  slidesData: PropTypes.arrayOf(PropTypes.any),
  /** Maximum zoom level */
  maxScale: PropTypes.number,
  /** Minimum zoom level */
  minScale: PropTypes.number,
  /** Content to overlay on the slider */
  slideOverlay: PropTypes.func,
  slideOverlayBottom: PropTypes.func,
  /** Time in ms until the slide indicator fades out. Set to `null` to disable this behavior. */
  slideIndicatorTimeout: PropTypes.number,
  /** Pagination dot color for the active slide */
  activeDotColor: PropTypes.string,
  /** Pagination dot color for all other slides */
  dotColor: PropTypes.string,
  showButtons: PropTypes.bool
}

Slider.defaultProps = {
  maxScale: defaultProps.maxScale,
  minScale: defaultProps.minScale,
  slideOverlay: null,
  slideOverlayBottom: null,
  slideIndicatorTimeout: defaultProps.slideIndicatorTimeout,
  activeDotColor: defaultProps.activeDotColor,
  dotColor: defaultProps.dotColor,
  showButtons: false
}
