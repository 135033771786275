// import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import loadScript from '../../libs/loadScript';


const XLSX_SCRIPT_URL = '/xlsx.full.min.js';

export const loadXLSXModule = async () => {
  try {
    await loadScript(XLSX_SCRIPT_URL);
    return window.XLSX; // Devuelve el objeto XLSX globalmente disponible
  } catch (error) {
    console.error('Error loading XLSX module:', error);
    throw error; // Propaga el error para manejarlo externamente si es necesario
  }
};

export const readAndConvertXLSXToCSV = async (file) => {
  try {
    const XLSX = await loadXLSXModule();
    const binaryString = await readFileBinaryAsync(file);
    const workbook = XLSX.read(binaryString, { type: 'binary' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    return csv;
  } catch (error) {
    console.error('Error converting XLSX to CSV:', error);
  }
};

export const convertXLSXToArray = async (file) => {
  try {
    const csvContent = await readAndConvertXLSXToCSV(file);
    const recordListObjects = parseCSVToArray(csvContent);
    return recordListObjects;
  } catch (error) {
    console.error('Error converting XLSX to ARRAY:', error);
  }
};

export const convertCSVToArray = async (file) => {
  try {
    const csvContent = await readFileAsync(file);
    const recordListObjects = parseCSVToArray(csvContent);
    return recordListObjects;
  } catch (error) {
    console.error('Error converting CSV to object:', error);
  }
};

const parseCSVToArray = (csvContent) => {
  const parsedData = Papa.parse(csvContent, {
    skipEmptyLines: true,
  });

  const data = parsedData.data;
  const headers = data[1]; // Use the second row as headers
  const recordListObjects = [];

  // Start iterating from the third row (index 2) to extract the data and create objects
  for (let i = 2; i < data.length; i++) {
    const rowData = data[i];
    const recordObject = {};

    // Match the rowData with headers to create the object
    for (let j = 0; j < headers.length; j++) {
      const header = headers[j];
      const value = rowData[j];
      recordObject[header] = value;
    }

    recordListObjects.push(recordObject);
  }

  return recordListObjects;
};

// This is a helper function to read the file content as a string.
const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(file);
  });
};

// This is a helper function to read the file content as a string.
const readFileBinaryAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const binaryString = arrayBufferToString(arrayBuffer);
      resolve(binaryString);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

// Helper function to convert ArrayBuffer to binary string
const arrayBufferToString = (arrayBuffer) => {
  const uint8Array = new Uint8Array(arrayBuffer);
  let binaryString = '';
  for (let i = 0; i < uint8Array.length; i++) {
    binaryString += String.fromCharCode(uint8Array[i]);
  }
  return binaryString;
};