import { AddClassWhenVisible } from "../AddClassWhenVisible";

export default function CtaImgAndContent({ data }) {
  return (
    <div id="explorar" className="text-black text-center md:grid md:grid-cols-2 ">

      {/* Left 1 / Top 1 ( IMAGEN 1)*/}
      <div className="h-[35vh] md:h-[65vh] lg:h-[80vh] xl:h-[90vh] bg-cover bg-bottom" 
        style={{ backgroundImage: `url(${data.firstItem.imageUrl})` }}>
      </div>

      {/* Right 1 / Bottom 1*/}
      <div className="text-start bg-gray-100 content-center py-10 px-4 sm:px-8 md:py-16 lg:py-20 ">
        <div className="container-width py-4 md:pt-12 lg:pt-28 lg:pr-10 lg:pl-12 xl:pl-20 xl:pr-28 xl:pt-36">

          <AddClassWhenVisible classToAdd="animate-fadeIn">
            <div className="text-center lg:text-base text-sm lg:text-start px-3 sm:px-2 md:px-0">
              <h3 className="pb-2 text-3xl lg:text-4xl md:pb-4 font-brand-mulish text-brand-red font-bold">{data.firstItem.title}</h3>
              <p className="pb-4 text-lg md:text-xl font-brand-mulish">{data.firstItem.content}</p>
            </div>
          </AddClassWhenVisible>

          <div className="mt-4 flex items-center justify-center w-52 mx-auto lg:mx-0 text-white bg-brand-yellow hover:bg-brand-red border-2 border-brand-yellow p-2 sm:pr-4 rounded-sm shadow-sm cursor-pointer hover:shadow-md ">
            <a href={data.firstItem.ctaUrl} target="_blank" className="lg:text-xl text-lg" rel="noreferrer">
              {data.firstItem.ctaTitle}
            </a>
          </div>
        </div>
      </div>

      {/* Left 2 / Top 2*/}
      <div className="text-start bg-brand-red content-center py-10 px-4 sm:px-8 md:py-16 lg:py-20 ">
        <div className="container-width py-4 md:pt-12 lg:pt-28 lg:pr-10 lg:pl-12 xl:pl-20 xl:pr-28 xl:pt-36">

          <AddClassWhenVisible classToAdd="animate-fadeIn">
            <div className="text-center text-white lg:text-base text-sm lg:text-start px-3 sm:px-2 md:px-0">
              <h3 className="pb-2 text-3xl lg:text-4xl md:pb-4 font-brand-mulish font-bold">{data.secondItem.title}</h3>
              <p className="pb-4 text-lg md:text-xl font-brand-mulish">{data.secondItem.content}</p>
            </div>
          </AddClassWhenVisible>
          
          <div className="mt-4 flex items-center justify-center w-52 mx-auto lg:mx-0 text-white bg-brand-yellow hover:bg-brand-red border-2 border-brand-yellow p-2 sm:pr-4 shadow-sm cursor-pointer hover:shadow-md ">
            <a href={data.secondItem.ctaUrl} target="_blank" className="lg:text-xl text-lg" rel="noreferrer">
              {data.secondItem.ctaTitle}
            </a>
          </div>
        </div>
      </div>
      
      {/* Right 2 / Bottom 2 ( IMAGEN 2)*/}
      <div className="h-[35vh] md:h-[65vh] lg:h-[80vh] xl:h-[90vh] bg-cover bg-bottom" 
        style={{ backgroundImage: `url(${data.secondItem.imageUrl})` }}>
      </div>
    </div>
  );
}
