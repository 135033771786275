import { useEffect, useState } from 'react';
import Entity from '../entity/Entity';
import { useModule } from "../../context/ModuleContext";
import { setPermissions } from '..';
import _ from 'lodash';

const useHook = () => {
  const { actionsByResource } = useModule();
  const [ entitiesDocList, setEntitiesDocList ] = useState();

  useEffect(async () => {
    let entitiesDocList = await Entity.getAll();
    entitiesDocList = entitiesDocList.filter(entityDoc => !entityDoc.data.deleted);
    entitiesDocList = Entity.sortDocs(entitiesDocList, 'sort');
    setEntitiesDocList(entitiesDocList);
  }, []);

  let entitiesActions = {};
  let systemActions = {};
  _.map(actionsByResource, actionByResource => {
    if (actionByResource?.type === 'system') {
      systemActions[actionByResource.resource] = actionByResource;
    }
    else {
      entitiesActions[actionByResource.resource] = actionByResource;
    }
  });

  // add default permissions to data entities
  if (entitiesDocList?.length) {
    entitiesDocList.forEach(entityDoc => {
      if (!actionsByResource[entityDoc.data.nameSlug]) {
        entitiesActions[entityDoc.data.nameSlug] = setPermissions({
          slug: entityDoc.data.nameSlug,
          label: entityDoc.data.name
        });
      }
    });
  }

  return {
    entitiesActions, systemActions
  }
}

const ActionsByResourceSelector = ({ actionsByResource, value, onChange }) => {
  return (
    <>
      {_.map(actionsByResource, ({ resource, label, actions }) => (
        <div className="my-2 flex flex-row" key={resource}>
          <div className="w-1/3">{label}</div>
          <div className="w-2/3">
            <div className={`inline-block overflow-hidden rounded-md cursor-pointer`}>
              {actions?.map((action) => (
                <button
                  type="button"
                  key={action}
                  onClick={() => {
                    value = value || {};
                    value[resource] = value[resource] || [];
                    
                    const updatedActions = _.includes(value[resource], action)
                    ? value[resource].filter((item) => item !== action)
                    : [...value[resource], action];
                    
                    if (updatedActions.length) {
                      value[resource] = updatedActions;
                    }
                    else {
                      delete value[resource];
                    }
                    onChange({ ...value });
                  }}
                  className={`py-1 px-2 text-xs inline-block ${
                    _.includes(value[resource], action) ? 'bg-brand-dark text-white' : 'bg-brand-light'
                  }`}
                >
                  {action}
                </button>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

const ActionsByResourceDisplay = ({ actionsByResource, value }) => {
  return (
    <>
      {_.map(actionsByResource, ({ resource, label, actions }) => (
        <div className="my-2 flex flex-row" key={resource}>
          <div className="w-1/3">{label}</div>
          <div className="w-2/3">
            <div className={`inline-block overflow-hidden rounded-md cursor-pointer`}>
              {actions?.map((action) => (
                <div
                  key={action}
                  className={`py-1 px-2 text-xs inline-block ${
                    _.includes(value[resource], action) ? 'bg-brand-dark text-white' : 'bg-brand-light/20 line-through text-gray-400'
                  }`}
                >
                  {action}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

const RenderForm = ({ value, onChange }) => {
  const { entitiesActions, systemActions } = useHook();

  return (
    <div className="border-gray-400 border px-2 py-1.5 rounded-md">
      <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Entidades</label>
      <ActionsByResourceSelector {...{actionsByResource: entitiesActions, value, onChange}} />

      <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Sistema</label>
      <ActionsByResourceSelector {...{actionsByResource: systemActions, value, onChange}} />
    </div>
  );
}

const RenderShow = ({ displayedValue }) => {
  const { entitiesActions, systemActions } = useHook();

  return (
    <div className="border-gray-400 border px-2 py-1.5 rounded-md">
      <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Entidades</label>
      <ActionsByResourceDisplay {...{actionsByResource: entitiesActions, value: displayedValue }} />

      <label className="text-xs text-gray-700 font-semibold uppercase block border-b border-gray-400 pb-1.5">Sistema</label>
      <ActionsByResourceDisplay {...{actionsByResource: systemActions, value: displayedValue }} />
    </div>
  );
}

const permissionsDataType = {
  RenderForm,
  RenderShow
};

export default permissionsDataType;