import { useState } from 'react';
import { FormChangeSubscriber } from '../Form';
import { SelectedValuesList, FormFiltersRender } from './';
import { useNavigate } from "react-router-dom";
import { stringifyAttrValParams } from '../../libs/utils';
import useBreakpoints from '../../libs/useBreakpoints';

const MenuStyleCollapsible = (props) => {
  let {
    filterMenuTaxonomyTypes,
    form,
    values,
    handleSubmit,
    classes,
    isAlwaysExpanded = false,
    Components
  } = props;
  const [open, setOpen] = useState(isAlwaysExpanded ? true : false);
  const navigate = useNavigate();
  const { isMinBreakpointActive } = useBreakpoints();

  let renderFilterParams = {
    showLabel: true,
    style: {
      classes: { 
        fieldContainer: 'w-full mb-6',
        fieldLabel: 'block mb-2',
        fieldInput: 'block',
      }
    }
  };
  
  const onFormChange = (formValues) => {
    if (formValues) {
      // handleSubmit(formValues);
      const attrValParams = stringifyAttrValParams(formValues);
      const url = attrValParams.length ? `${props.defaultPath}/${attrValParams}` : props.defaultPath;
      navigate(url);
    }
  };

  const isOpen = () => {
    return isAlwaysExpanded === true ? true : open;
  }
  
  return (
    isMinBreakpointActive('lg') ? (
      // lg
      <div className={`${classes?.filterMenuContainer || ''}`}>
        <form onSubmit={handleSubmit}>
          <FormChangeSubscriber onFormChange={onFormChange} />

          <div className="flex flex-row gap-4 lg:gap-6">
            {/* sidebar */}
            <div className="w-1/4">
              <div className="px-2.5 py-2 border border-gray-200 bg-gray-100 shadow-lg rounded-lg">
                <FormFiltersRender
                  {...props}
                  renderFilterParams={renderFilterParams}
                  classes={{ selectedFilter: 'text-blue-700 font-semibold' }} // classes al ultimo para sobreescribir props.classes
                />
              </div>
            </div>
            {/* content */}
            <div className="w-3/4">
              <div className="flex flex-wrap gap-2 mb-4">
                <SelectedValuesList {...{form, values, filterMenuTaxonomyTypes }} />
              </div>
              {Components?.ResultsContent ? (
                <Components.ResultsContent key="filterResults" />
              ) : null}
            </div>
          </div>

        </form>
      </div>
    ) : (
      // sm
      <div className={`
        ${classes?.filterMenuContainer || 'px-2 py-2 border rounded-lg shadow-md'} 
        ${isOpen() ? 'border-gray-200 bg-gray-300' : ' border-gray-100 bg-gray-50'}`
      }>
        <form onSubmit={handleSubmit}>
          <FormChangeSubscriber onFormChange={onFormChange} />

          <div className="flex flex-wrap gap-2">
            <button
            type="button" onClick={() => setOpen(!isOpen())}
            className={`block px-2.5 py-0.5 text-sm rounded-full font-bold  bg-blue-600 text-white`}>
              Filtros
            </button>

            {/* show selected values */}
            <SelectedValuesList {...{form, values, filterMenuTaxonomyTypes }} />
          </div>

          {/* Filters list */}
          {isOpen() ? (
            <div className='p-2.5 mt-2.5 overflow-y-scroll max-h-[60vh] border-gray-200 bg-gray-50 rounded-lg'>

              <FormFiltersRender
                {...props}
                renderFilterParams={renderFilterParams}
                classes={{ selectedFilter: 'text-blue-700 font-semibold' }} // classes al ultimo para sobreescribir props.classes
              />

            </div>
          ) : ''}
          {/* <button type="submit">Aplicar filtros</button> */}
        </form>
      </div>
    )
  );
}


export default MenuStyleCollapsible;