import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowError } from './formUtils';

const FormFieldSelectButtons = (props) => {
  let {
    name,
    title,
    options,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    onSelect = (() => null),
  } = props;

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {({ input, meta }) => {
          const onChange = (value) => {
            input.onChange(value);
            onSelect(value);
          };

          return (
            <>
              {showLabel && title ? (
                <label position="floating" className={props?.classes?.fieldLabel}>
                  <span
                    className={`font-semibold mr-3 ${
                      meta.touched && meta.error
                        ? 'text-red-600'
                        : 'text-gray-900'
                    }`}
                  >
                    {title}
                    {meta.touched && meta.error ? '*' : ''}
                  </span>
                </label>
              ) : ''}
              <div className={props?.classes?.fieldInput || ''}>
                <div className={`inline-block overflow-hidden rounded-md cursor-pointer`}>
                  {options && options.map((option) => (
                    <button
                      type="button"
                      key={option.value}
                      onClick={() => onChange(option.value)}
                      className={`p-2 px-4 inline-block ${input.value === option.value ? 'bg-brand-dark text-white' : 'bg-brand-light'}`}
                    >
                      {option.label}                      
                    </button>
                  ))}
                </div>
              </div>
              {/* error */}
              <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
              {/* <RequiredByFieldsList
                name={name}
                input={input}
                fieldsRequired={fieldsRequired}
              /> */}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default FormFieldSelectButtons;