import _ from 'lodash';
import {
  FormField,
  FormFieldSelect,
  FormFieldCheckboxToggle,
} from '../Form';

const FilterRenderBoolean = (props) => {
  const {
    values,
    filter,
    taxonomyType,
    overrideParams = {},
    DataTypeRender
  } = props;
  const _taxonomyType = (taxonomyType || values?.taxonomyType || values);  // use value as fallback
  const _filter = _.assign({}, _taxonomyType?.filter, values?.filter, filter);
  const mode  = _filter?.mode || 'simpleInput';
  const param = { ..._taxonomyType?.param, ...overrideParams };
  const style = param?.style;
  
  const renderComponent = () => {
    switch (mode) {
      case 'simpleInput':
        return (
          <DataTypeRender       
            {...param}
            {...style}
            {...props}
          />
        );
      default:
        return null;
    }
  };

  return renderComponent();
};

const FilterCreatorBoolean = ({ form, values, onChange }) => {
  const handleValueChange = (newValue) => {
    const nameSlug = values?.taxonomyType?.nameSlug;
    form.change(`filtersSelected.${nameSlug}`, null);
    onChange && onChange(newValue);
  };
  if (values?.filter?.mode !== 'simpleInput') {
    form.change('filter.mode', 'simpleInput');
  }
  
  return (<>
    <FormFieldSelect
      name="filter.mode"
      title="Modo"
      placeholder="Modo"
      options={[
        { value: 'simpleInput', label: 'Simple' },
      ]}
      onSelect={(newMode) => handleValueChange({ mode: newMode })}
    />
    <div className="mt-4 p-2 bg-white rounded">
      <label className="mb-2 pb-2 block font-semibold border-b border-gray-200">Estilos</label>
      <FormField
        name={`filter.style.classes.filterContainer`}
        showLabel={true}
        title="Contenedor del filtro"
        placeholder="Clases"
        className="inline-block"
        type="text"
      />
    </div>
  </>);
};

export {
  FilterRenderBoolean,
  FilterCreatorBoolean
};