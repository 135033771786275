import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import _ from 'lodash';
import { useAuth } from "../context/AuthContext";
import { useModule } from "../context/ModuleContext";
import { Alert } from "../components/Alert";
import Model from "../libs/ModelClass";

const mainRoute = '/';
const adminMainRoute = '/admin';

const Role = Model.extend('roles');
const UserProfile = Model.extend('usersProfiles');
const Credential = Model.extend('credentials');

const saveManyToEntity = async (entitySlug, dataList) => {
  const ExtendedModel = Model.extend(entitySlug);
  await ExtendedModel.createMany(dataList);
};

export function Install() {
  const { loginWithGoogle } = useAuth();
  const { modules, actionsByResource } = useModule();
  const [error, setError] = useState("");
  const [superAdminEmail, setSuperAdminEmail] = useState();
  const [usersCount, setUsersCount] = useState(null);
  const [ saving, setSaving ] = useState(false);
  const navigate = useNavigate();
  
  useEffect(async () => {
    if (usersCount === null) {
      let usersCount = await UserProfile.count();
      setUsersCount(usersCount);
      if (usersCount > 0) {
        navigate(mainRoute);
      }
    }
  }, [usersCount]);

  const install = async () => {
    setSaving(true);
    let usersCount = await UserProfile.count();
    if (usersCount === 0) {
      // create role, userProfile, credentials form superadmin
      let permissions = {}; 
      _.forEach(actionsByResource, ({ resource, actions }) => {
        permissions[resource] = actions;
      });
      const role = await Role.create({ name: 'Acceso total', permissions })
      const userProfile = await UserProfile.create({ name: 'Super Admin', email: superAdminEmail });
      await Credential.create({ profile: userProfile.id, roles: role.id, isActive: true });
      
      // add entities records from models
        // save entity main doc
        // save all taxonomyType docs
        // save all filterMenu docs
      for (const module of modules) {
        if (module.entities) {
          for (const [ entitySlug, { entity, filterMenuList, taxonomyTypesList } ] of Object.entries(module.entities)) {
            await saveManyToEntity('entities', [entity]);
            await saveManyToEntity('taxonomyTypes', taxonomyTypesList);
            await saveManyToEntity('filterMenu', filterMenuList);
          }
        }
      }
      
      toast('Sistema Instalado', { icon: 'ℹ️' });
      setTimeout(() => {
        toast.success('Será redirigido en breve');
        setSaving(false);
      }, 1500);
      setTimeout(() => {
        window.location.replace(adminMainRoute);
      }, 3000);
    } else {
      toast.error('El sistema ya se encuentra instaldo');
      setSaving(false);
    }
  }

  const handleSuperAdmin = async () => {
    try {
      const credentials = await loginWithGoogle();
      setSuperAdminEmail(credentials?.user?.email);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleInstall = async () => {
    if (saving) {
      return;
    }
    try {
      await install(superAdminEmail);
    } catch (error) {
      setError(error.message);
      setSaving(false);
    }
  };

  return (
    <div className="w-full max-w-xs m-auto">
      <div className="mt-10 px-5 py-5 border border-gray-300 rounded-lg shadow-xl">
        {error && <Alert message={error} />}
        <h1 className="mb-4 text-center text-2xl text-black">Instalación</h1>

        <button
          onClick={handleSuperAdmin}
          className="bg-slate-50 hover:bg-slate-200 text-black rounded-md border border-gray-500 py-2 px-4 w-full"
        >
          Asignar 
          <span className="ml-1 text-xs text-white bg-gray-600 px-2 py-0.5 rounded-full inline-block">
            superadmin
          </span>
        </button>
        <div className="p-2 text-sm text-center">
          {superAdminEmail}
        </div>

        {superAdminEmail ? (<>
          <hr className="border border-gray-300 my-5" />

          <button
            onClick={handleInstall}
            className="py-2 px-4 w-full bg-slate-700 hover:bg-slate-800 text-white text-lg rounded-md"
          >
            <div className="mx-auto align-middle">
              {!saving ? (
                <span>Instalar</span>
              ) : (<>
                <svg className="inline-block animate-spin -mt-1 mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <span>Instalando...</span>
              </>)}
            </div>
          </button>
        </>) : null}
      </div>


      <div className="mt-14">
        <button
          onClick={() => window.location.replace(adminMainRoute) /* hard redirect to admin */ }
          className="text-black w-full underline"
        >
          Ir al panel de administración
        </button>
        <button
          onClick={() => navigate(mainRoute)}
          className="mt-1.5 text-black w-full underline"
        >
          Ir a la portada
        </button>
      </div>
    </div>
  );
}
