import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";

export function LayoutAdmin({ children }) {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error(error.message);
    }
  };
  
  return (
    <div className="admin w-full text-black" id="layout">
      {/* header */}
      <header id="menu" className="z-50 fixed flex place-content-between w-full top-0 bg-white shadow-md px-5 pt-3 pb-3">
        <p className="text-base text-brand-dark xsm:text-lg pt-0.5 xs:pt-1">
          <b>Hola </b> 
          {user?.userDoc?.data?.name || user?.userAuth?.displayName || user?.userAuth?.email}
        </p>
        <div className="flex flex-row">
          <Link to="/" className="bg-zinc-200 hover:bg-zinc-300 rounded mr-1 py-1.5 px-2 text-black text-xs xsm:text-sm">Portada</Link>
          <button
            className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm"
            onClick={handleLogout}
          >
            Cerrar sesión
          </button>
        </div>
      </header>
      
      {/* content */}
      <section className="mt-20 px-5 pb-10 h-auto container-width mx-auto">
        {children}
      </section>
    </div>
  );
}
