import { Link } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";
import Product from "../models/Product";
import { useState, useEffect  } from 'react';
import { priceFormat, getImageURL } from "../libs/utils";

export function List() {
  const [openSelector, setOpenSelector] = useState(false);
  const [productsAll, setProductsAll] = useState([]);

  const fetchProducts = async () => {
    const allProducts = await Product.getAll();
    setProductsAll(allProducts);
  };

  useEffect(async () => {
    await fetchProducts();
  }, []);

  const setSuperOutstandingProduct = async (id, value) => {
    await Promise.getAll(
      productsAll.map(async (product, i) => {
        const updatedProduct = { ...product };
        if (updatedProduct.data.superOutstanding === true) {
          updatedProduct.data.superOutstanding = i === false;
          await Product.createOrUpdate(updatedProduct);
        }
      })
    );

    const updatedProduct = new Product({ ...(await Product.findById(id)) });
    updatedProduct.data.superOutstanding = value;
    await Product.createOrUpdate(updatedProduct);
    await fetchProducts(); // Actualizar la lista de productos
  };

  const setOutstandingProduct = async (id, value) => {
    const updatedProduct = new Product({ ...(await Product.findById(id)) });
    updatedProduct.data.outstanding = value;
    await Product.createOrUpdate(updatedProduct);
    await fetchProducts(); // Actualizar la lista de productos
  };

  const ProductList = ({ listOfProducts, showSuperOutstandingBtn }) => (<>
    {listOfProducts && listOfProducts.map(({
      id, data: { name, price, show, images, outstanding, superOutstanding }
    }) => ( show ? (
      <article key={id} id={id} className="px-2 py-2">
        <div className="bg-white shadow-md rounded mb-3 hover:shadow-xl flex p-2">
          <img src={images.length && getImageURL(images[0], 'xs')} className="h-[120px] w-[120px] object-cover rounded hidden xs:block" /> {/* image xs */}
          <div className="px-4 w-full">
            <div className="flex gap-3">
              <img src={images.length && getImageURL(images[0], 'xs')} className="h-[120px] w-[120px] object-cover rounded xs:hidden" /> {/* image xxs */}
              <div className="">
                {/* titles */}
                {name ? ( <h2 className="text-lg font-semibold">{name}</h2> ) : ''}
                {price ? (<p>
                  <span className="text-green-600 text-md mr-2">{priceFormat(price)}</span>
                </p>) : ''}
                {/* tags */}
                <div className="tags">
                  {superOutstanding ? (
                    <span className={`px-2 py-0.5 text-xs mr-2 bg-yellow-100 text-yellow-700`}>Súper Destacado</span>
                  ) : ''}
                  {outstanding ? (
                    <span className={`px-2 py-0.5 text-xs mr-2 bg-blue-100 text-blue-700`}>Destacado</span>
                  ) : ''}
                </div>
              </div>
            </div>
            <div className="flex place-content-between mt-4">
              <div className="">
                {showSuperOutstandingBtn ? (
                  !superOutstanding ? (
                    <button className=" px-2 py-0.5 text-xs bg-yellow-600 rounded-md text-white mr-2" 
                    onClick={() => {
                      setSuperOutstandingProduct(id, true); 
                    }}>Hacer súper destacado</button>
                  ) : (
                    <button className=" px-2 py-0.5 text-xs bg-gray-600 rounded-md text-white mr-2" 
                    onClick={() => {
                      setSuperOutstandingProduct(id, false); 
                    }}>Quitar súper principal</button>
                  )
                ) : ''}
                {!outstanding ? (
                  <button className=" px-2 py-0.5 text-xs bg-blue-600 rounded-md text-white mr-2" 
                  onClick={() => {
                    setOutstandingProduct(id, true); 
                  }}>Destacar</button>
                ) : (
                  <button className=" px-2 py-0.5 text-xs bg-gray-600 rounded-md text-white mr-2" 
                  onClick={() => {
                    setOutstandingProduct(id, false); 
                  }}>Quitar destacado</button>
                )}
              </div>
            </div>
          </div>
        </div>
      </article>
    ) : ''))}
  </>);

  return (
    <div className="product-list">

      {/* outstanding list */}
      {productsAll ? (
        <ProductList 
          showSuperOutstandingBtn={true}
          listOfProducts={productsAll.filter((product) => product.data.outstanding || product.data.superOutstanding)}>
        </ProductList>
      ) : ''}

      {!openSelector ? (
        <button className="w-full text-left bg-blue-600 text-blue-100 shadow-md rounded px-4 p-3 mb-2 text-xl" 
          onClick={() => setOpenSelector(true)}>Seleccionar</button>
      ) : (
        <div className="shadow-md bg-gray-200">
          <button className="w-full text-left bg-gray-600 text-gray-100 rounded px-4 p-3 mb-2 text-xl" 
          onClick={() => setOpenSelector(false)}>Cerrar</button>
          {/* selector list */}
          {productsAll ? (
            <ProductList listOfProducts={productsAll}></ProductList>
          ) : ''}
        </div>
      )} {/* selector */}

    </div>
  );
}


export function AdminOutstandingProducts() {
  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Productos Destacados</h2>
      </div>

      <List></List>
    </LayoutAdmin>
  );
}
