import React from 'react';

export default function ModalAlert({ text, onConfirm, onCancel }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[1000]">
      <div className="fixed inset-0 bg-black opacity-50"></div> {/* Overlay */}
      <div className="bg-white rounded-lg p-6 shadow-md relative">
        <p className="text-lg mb-4">{text}</p>
        <div className="flex justify-end">
          <button
            onClick={onConfirm}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 mr-2 rounded"
          >
            Sí
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}
