import { Link } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";
import { useEffect, useState } from 'react';
import _ from 'lodash';

export function AdminEntityShowInner() {

  return (
    <div className="">
      EN PROCESO
    </div>
  );
}


export function AdminEntityCreatorShow() {
  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <Link to="/admin/entity/creator"><h2 className="text-xl font-thin mb-4 mr-3">Entidades</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Ver</h2>
      </div>

      <AdminEntityShowInner></AdminEntityShowInner>
    </LayoutAdmin>
  );
}
