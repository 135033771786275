import { Field } from 'react-final-form';
import _ from 'lodash';
import { ShowError } from './formUtils';

const FormField = ({ input, meta, title, showLabel, fieldsRequired, classes }) => {
  return (
    <>
      {showLabel && title ? (
        <label position="floating" className={classes?.fieldLabel}>
          <span
            className={`font-semibold mr-3 ${
              meta.touched && meta.error
                ? 'text-red-600'
                : 'text-gray-900'
            }`}
          >
            {title}
            {meta.touched && meta.error ? '*' : ''}
          </span>
        </label>
      ) : ''}
      <select
        className={`border-gray-200 bg-white h-[40px] border px-2 py-1.5 rounded-md cursor-pointer ${classes?.fieldInput || ''}`}
        {...input}
        onChange={event => { 
          input?.onChange(event.target.value); 
          input?.onSelect(event.target.value); 
        }}
        disabled={input?.disabled}
      >
        <option value="" disabled className="cursor-pointer">
          {input?.placeholder || 'Seleccionar opción'}
        </option>
        {input?.options && input?.options.map((option) => (
          <option key={option.value} value={option.value} className="cursor-pointer">
            {option.label}
          </option>
        ))}
      </select>
      {/* error */}
      <ShowError name={input?.name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
    </>
  );
};

const FormFieldSelect = (props) => {
  let {
    name,
    title,
    options,
    placeholder,
    fieldsRequired = null,
    validate = null,
    disabled = false,
    showLabel = true,
    onSelect = (() => null),
  } = props;

  return (
    <div className={`pb-3 ${props?.classes?.fieldContainer || ''}`}>
      <Field name={name} validate={validate}>
        {(fieldProps) => (
          <FormField
            input={{
              ...fieldProps.input,
              disabled,
              placeholder,
              options,
              onSelect
            }}
            meta={fieldProps.meta}
            title={title}
            showLabel={showLabel}
            classes={props?.classes}
            fieldsRequired={props?.fieldsRequired}
          />
        )}
      </Field>
    </div>
  );
};

export default FormFieldSelect;
