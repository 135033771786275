import { useEffect, useState } from 'react';
import ViewMapPolygon from '../../components/ui/ViewMapPolygon';
import Model from '../../libs/ModelClass';
import { calculateDistance, calculateArea, numberFormat } from '../../libs/utils';

export const colors = {
  fraccion: {
    borderColor: "#ff3d08",
    bgColor: "#ffcec04f"
  },
  manzana: {
    borderColor: "#767272",
    bgColor: "#c7c7c78f",
  },
  loteDisponible: {
    borderColor: "#0000FF",
    bgColor: "#87CEEB",
  },
  loteVendido: {
    borderColor: "#6b6b6b",
    bgColor: "#ccc",
  }
};

export const getLoteColor = (loteDoc) => (
  loteDoc.data.estado === 'Disponible' 
    ? colors.loteDisponible
    : colors.loteVendido
);

export const Estados = ({ doc }) => (
  <div className="ml-2 inline-block text-sm font-normal">
    {doc?.data?.estado === 'Disponible' ? (
      <span className="px-2 py-0.5 rounded-full bg-blue-500 text-white">Disponible</span>
    ) : (
      <span className="px-2 py-0.5 rounded-full bg-gray-500 text-white">Vendido</span>
    )}
  </div>
);

export const Medidas = ({ polygonPaths }) => (
  <ul className="text-left mx-auto">
    {polygonPaths?.map((point, i) => {
      const nextPoint = polygonPaths[(i + 1) % polygonPaths.length];
      const distance = calculateDistance(point, nextPoint);
      return distance !== 0 ? (
        <li key={i}>Punto {i + 1}: <b className='font-semibold'>{numberFormat(distance, 1)}</b> metros</li>
      ) : null;
    })}
  </ul>
);

export const InfoWindowRender = ({ polygonPaths, layer, index }) => {
  // fraccion with no popup
  if (layer?.type === 'fraccion') { return null; }

  return (
    <div className='p-2 text-left'>
      <h2 className='text-lg font-semibold border-b border-gray-300 mb-1.5 pb-1.5'>
        Lote {layer.data?.num}

        <Estados doc={layer} />
      </h2>
  
      {/* Mostrar área en metros cuadrados */}
      {/* <h3 className='text-base font-semibold'>Área</h3>
      <p>{numberFormat(calculateArea(polygonPaths))} metros cuadrados</p> */}
  
      <h3 className='text-base font-semibold'>Medidas</h3>
      <Medidas polygonPaths={polygonPaths} />
      <div className='mt-3'>

      <a href={`/lotes/show/id/${layer?.data?.id}`} className='px-3 py-1.5 rounded-md bg-brand-red text-white '>Ver lote</a>
      </div>
    </div>
  )
};

export const RenderShowForFraction = ({ doc }) => {
  const [ layers, setLayers ] = useState([]);
  const [ lotesDocs, setLotesDocs ] = useState([]);
  const LotesModel = Model.extend('lotes');
  
  useEffect(async () => {
    if (doc && !lotesDocs.length && !layers.length) {
      let layersToSet = [];
      // add fraccion layer
      if (doc.data?.polygon?.coordinates) {
        layersToSet.push({
          type: 'fraccion',
          data: doc,
          coordinates: doc.data.polygon.coordinates,
          ...colors.fraccion
        });
      }
      // add manzanas layers
      if (doc.data?.manzanasList?.length) {
        doc.data.manzanasList.forEach(manzana => {
          layersToSet.push({
            type: 'manzana',
            data: manzana,
            coordinates: manzana?.polygon?.coordinates,
            ...colors.manzana
          });
        });
      }
      // add lotes
      let lotesDocs = await LotesModel.filterByAttributes({
        fraccionId: doc.id
      });
      lotesDocs.forEach(loteDoc => {
        if (loteDoc.data.polygon?.coordinates) {
          layersToSet.push({
            type: 'lote',
            data: loteDoc.data,
            coordinates: loteDoc.data.polygon?.coordinates,
            ...getLoteColor(loteDoc),
            showInfoWindow: true
          });
        }
      });
      setLotesDocs(lotesDocs);
      setLayers(layersToSet);
    }
  }, [doc]);

  return (
    layers.length ? (
      <ViewMapPolygon  layers={layers} classes={{ mapContainer: '', mapHeight: 'h-[450px]' }}  InfoWindowRender={InfoWindowRender} />
    ) : null
  );
}

export const RenderShowForLote = ({ doc }) => {
  const [ layers, setLayers ] = useState([]);
  
  useEffect(() => {
    if (doc && !layers.length) {
      setLayers([{
        type: 'lote',
        data: doc?.data,
        coordinates: doc?.data?.polygon?.coordinates,
        ...getLoteColor(doc),
        showInfoWindow: true
      }]);
    }
  }, [doc]);
  
  if (!doc) { return null; }
  return (
    doc?.data?.polygon?.coordinates ? (
      <ViewMapPolygon  layers={layers} classes={{ mapContainer: 'border border-gray-300 rounded-sm', mapHeight: 'h-[400px] sm:h-[500px] !mb-0' }}  InfoWindowRender={InfoWindowRender} />
    ) : null
  );
}

const RenderForm = ({ value, onChange }) => {
  return (
    <div className=" text-sm text-gray-600">
      Se define únicamente desde "Agregar lotes nuevos"
    </div>
  );
}

export const polygonDataTypeForFraccion = {
  RenderForm,
  RenderShow: RenderShowForFraction
};

export const polygonDataTypeForLote = {
  RenderForm,
  RenderShow: RenderShowForLote
};