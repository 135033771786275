import { openWhatsApp } from "../libs/utils";
import config from "../config";
import { addLogEvent } from '../firebase';
import { FaWhatsapp } from 'react-icons/fa';


export default function ContactBTN ({
  className,
  number = config.number, 
  message = config.whatsAppTextBTN,
  label = 'Whatsapp',
  afterSend = () => {},
  design
}) {
  const openWA = () => {
    addLogEvent('contact_btn'); // analytics
    openWhatsApp({ 
      number, 
      message
    });
    afterSend();
  }

  let classes = {
    btnContainer: 'h-12 md:h-14',
    iconContainer: 'w-12 md:w-14 h-12 md:h-14',
    icon: 'p-2 w-12 h-12 md:w-14 md:h-14',
    label: 'text-xl'
  };
  if (design === 'small') {
    classes = {
      btnContainer: 'h-8 md:h-10',
      iconContainer: 'w-8 md:w-10 h-8 md:h-10',
      icon: 'p-2 w-10 h-10 md:w-10 md:h-10',
      label: 'text-lg'
    };
  }

  return (<>
    <div className="flex place-content-end">
      <button 
        onClick={() => openWA()} type="button" 
        className={`
          bg-lime-600 shadow-lime-500/30 text-white hover:scale-105 hover:bg-lime-700
          ${classes.btnContainer} shadow-md cursor-pointer rounded-full flex place-content-center place-items-center ${className}
        `}
      >
        <div className={`bg-lime-500 font-bold rounded-full flex place-content-center place-items-center ${classes.iconContainer}`}>
          <FaWhatsapp className={`fill-current ${classes.icon}`} />
        </div>
        <div className={`pl-2.5 pr-4 ${classes.label}`}>
          {label}
        </div>
      </button>  
    </div>
  </>)
}