import { useState } from "react";
import Resizer from "react-image-file-resizer";
import slugify from 'slugify';
import config from "../config";
import _ from "lodash";


export const scrollTo = (id, moreOffsetPosition=0) => {
  const menuElement = document.getElementById('menu');
  if (menuElement) {
    // calc offset
    const headerOffset = 15 + menuElement.getBoundingClientRect().height;
    const element = document.getElementById(id);
    const elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scroolY - headerOffset - moreOffsetPosition;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
      duration: 1000,
    });
  }
}


export const useScrollTo = () => {
  const [ scrollOnceList, setScrollOnceList ] = useState([]);

  const scrollOnceTo = (elementId, offsetPosition=0) => {
    if (!scrollOnceList[elementId]) {
      setTimeout(() => { 
        scrollTo(elementId, offsetPosition); 
      }, 400);
      setScrollOnceList({ 
        ...scrollOnceList, 
        [elementId]: true
      });
    }
  }

  const scrollToDelay = (elementId, offsetPosition=0, delay=400) => {
    setTimeout(() => { 
      scrollTo(elementId, offsetPosition); 
    }, delay);
  }
  
  return { 
    scrollOnceTo,
    scrollToDelay
  };
}


export const priceFormat = num => {
  if (num > 0) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' Gs';
  }
}

export const numberFormat = (number, decimals = 2) => {
  return number.toLocaleString('es-ES', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: decimals
  });
};


const WhatsAppURL = 'https://wa.me';

export const openWhatsApp = ({ number, message }) => {
  number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');

  let url = `${WhatsAppURL}/${number}`;

  if (message) {
    url += `?text=${encodeURI(message)}`;
  }

  window.open(url);
}


export const resizeFile = ({ file, width, height, format }) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, width, height, format, 100, 0, 
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}


export const getImageURL = (fullImageURL, size) => {
  const [ hash, format ] = fullImageURL.split('.');
  return config.getImgPrefix(`${hash}-${size}.${format}`);
};


export const sendToLast = (list) => {
  if (list.length <= 1) { return list; }
  let newList = [ ...list ];
  newList.push(newList.shift())
  return newList;
}


export const slug = (text='') => {
  return slugify(text, { replacement: '_', lower: true, trim: true });
}

const nestedAttrSeparator = '__';

// Convertir los pares attr/val en un objeto
export function parseAttrValParams(attrValParams = '', separator = "/") {
  const attrValPairs = attrValParams.split(separator);
  const attrValObject = {};
  for (let i = 0; i < attrValPairs.length; i += 2) {
    let attr = attrValPairs[i];
    let val = attrValPairs[i + 1];
    if (val == parseInt(val, 10)) {
      val = parseInt(val, 10);
    }
    if (_.includes(val, nestedAttrSeparator)) {
      val = parseAttrValParams(val, nestedAttrSeparator);
    }
    attrValObject[attr] = val;
  }
  if (_.isEqual(attrValObject, {'': undefined})) {
    return {}; 
  }
  return attrValObject;
}

// convertir un objeto en pares attr/val en un string
export function stringifyAttrValParams(attrValObject, separator = "/") {
  const attrValPairs = [];
  for (let [attr, val] of Object.entries(attrValObject)) {
    if (!_.isArray(val) && _.isObject(val)) {
      val = stringifyAttrValParams(val, nestedAttrSeparator);
    }
    if (!_.isEmpty(val) || _.size(val) || (_.isBoolean(val) && val) || _.isString(val) || _.isNumber(val)) {
      attrValPairs.push(attr, val);
    }
  }
  return attrValPairs.join(separator);
}

/**
 * Combina los valores de cada objeto en un nuevo objeto, concatenando los valores correspondientes.
 * @param {...Object} layers - Lista variable de objetos que contienen los valores a combinar.
 * @returns {Array} - Un nuevo objeto que contiene los valores concatenados.
 *
 * @example
 * const layers = [
 *   { fieldContainer: 'text-xl', fieldLabel: 'label1' },
 *   { fieldContainer: 'p-2' },
 *   { fieldContainer: 'text-gray-200', fieldLabel: 'label2' }
 * ];
 *
 * const result = stackClasses(...layers);
 * console.log(result);
 * // Salida: { fieldContainer: 'text-xl p-2 text-gray-200', fieldLabel: 'label2' }
 */
export function stackClasses(...layers) {
  let result = {};

  for (const layer of layers) {
    for (const key in layer) {
      if (key in result) {
        result[key] += ` ${layer[key]}`;
      } else {
        result[key] = layer[key];
      }
    }
  }

  return result;
}


export const downloadJSON = (data, filename) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};


export const replaceUndefinedAndNaNWithNull = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    // Si el valor no es un objeto o es nulo, simplemente devolvemos el valor.
    return obj;
  }

  // Creamos un nuevo objeto o array según el tipo de dato del objeto actual.
  const result = Array.isArray(obj) ? [] : {};

  // Recorremos las propiedades del objeto.
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      // Obtenemos el valor actual de la propiedad.
      const value = obj[key];

      // Si el valor es undefined o NaN, lo reemplazamos por null.
      if (value === undefined || Number.isNaN(value)) {
        result[key] = null;
      } else {
        // Si el valor no es undefined o NaN, realizamos una llamada recursiva para tratar sus hijos.
        result[key] = replaceUndefinedAndNaNWithNull(value);
      }
    }
  }

  return result;
}


export const calculateDistance = (latLng1, latLng2) => {
  // Convert latitude and longitude to radians
  const lat1Rad = latLng1.lat * (Math.PI / 180);
  const lng1Rad = latLng1.lng * (Math.PI / 180);
  const lat2Rad = latLng2.lat * (Math.PI / 180);
  const lng2Rad = latLng2.lng * (Math.PI / 180);

  // Earth's radius in meters
  const earthRadius = 6371000;

  // Haversine formula to calculate distance between two points on a sphere
  const dlat = lat2Rad - lat1Rad;
  const dlng = lng2Rad - lng1Rad;
  const a = Math.sin(dlat / 2) * Math.sin(dlat / 2) + Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlng / 2) * Math.sin(dlng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate distance
  const distance = earthRadius * c;

  return distance;
};


export const calculateArea = (points) => {
  if (!Array.isArray(points) || points.length < 3) {
    return 0;
  }

  const radiusOfEarthInMeters = 6371000; // Radio de la tierra en metros
  let area = 0;
  const n = points.length;

  for (let i = 0; i < n; i++) {
    const { lat: lat1, lng: lng1 } = points[i];
    const { lat: lat2, lng: lng2 } = points[(i + 1) % n];

    const x1 = lng1 * Math.cos((lat1 * Math.PI) / 180) * radiusOfEarthInMeters;
    const y1 = lat1 * radiusOfEarthInMeters;
    const x2 = lng2 * Math.cos((lat2 * Math.PI) / 180) * radiusOfEarthInMeters;
    const y2 = lat2 * radiusOfEarthInMeters;

    area += x1 * y2 - x2 * y1;
  }

  return Math.abs(area) / 2;
};


export const calculateCenter = (polygonPaths) => {
  let latSum = 0;
  let lngSum = 0;
  const numPoints = polygonPaths.length;

  for (const point of polygonPaths) {
    latSum += point.lat;
    lngSum += point.lng;
  }

  const centerLat = latSum / numPoints;
  const centerLng = lngSum / numPoints;

  return { lat: centerLat, lng: centerLng };
};


export const calculateTopCenter = (polygonPaths) => {
  // Encontrar los puntos con las latitudes más altas
  const highestLatitudes = polygonPaths.reduce((acc, curr) => {
    if (!acc.length || curr.lat > acc[0].lat) return [curr];
    if (curr.lat === acc[0].lat) return [...acc, curr];
    return acc;
  }, []);

  // Calcular el promedio de las coordenadas de los puntos con las latitudes más altas
  const numPoints = highestLatitudes.length;
  const latSum = highestLatitudes.reduce((sum, point) => sum + point.lat, 0);
  const lngSum = highestLatitudes.reduce((sum, point) => sum + point.lng, 0);

  const centerLat = latSum / numPoints;
  const centerLng = lngSum / numPoints;

  return { lat: centerLat, lng: centerLng };
};

export const parseCoordinates = (layer) => {
  return layer?.coordinates?.split(' ').map((pointStr) => {
    const [lng, lat] = pointStr.split(',');
    return { lat: parseFloat(lat), lng: parseFloat(lng) };
  });
};
