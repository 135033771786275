import _ from 'lodash';

const SelectedValuesList = (props) => {
  let {
    form,
    values,
    filterMenuTaxonomyTypes,
    className
  } = props;
  // console.log('filterMenuTaxonomyTypes', filterMenuTaxonomyTypes)
  return (
    filterMenuTaxonomyTypes?.map((filterPopulated) => {
      let taxonomyType = filterPopulated?.taxonomyType;
      let filter = _.assign({}, taxonomyType?.filter, filterPopulated?.filter);
      let isSelected = filterPopulated?.id && values.hasOwnProperty(taxonomyType?.nameSlug);
      let filterValue = isSelected ? values[taxonomyType?.nameSlug] : null;

      let shouldRenderValue = isSelected && filterValue !== null && filterValue !== undefined && (
        Array.isArray(filterValue) ? filterValue.length > 0 : true
      );
      
      const getValue = () => {
        if (taxonomyType?.type === 'gps') {
          return filterValue?.formatted_address + ' - ' + filterValue?.distance + ' km';
        }

        if (taxonomyType?.type === 'select') {
          if (taxonomyType?.param?.options) {
            const option = taxonomyType?.param?.options?.find((opt) => opt.value === filterValue);
            if (option) {
              return option.label;
            }
          }
        }
        
        if (Array.isArray(filterValue)) {
          let optionLabels = [];
          filterValue.forEach((value) => {
            if (taxonomyType?.param?.options) {
              const option = taxonomyType?.param?.options?.find((opt) => opt.value === value);
              if (option) {
                optionLabels.push(option.label);
              }
            }
          });
          return optionLabels.join(', ');
        }

        if (filter?.mode === 'singleRange' && (filterValue?.startValue || filterValue?.endValue)) {
          let res = filterValue?.startValue ? 'Desde: ' + Number(filterValue?.startValue).toLocaleString() : '';
          if (filterValue?.endValue) {
            res += filterValue?.startValue ? ', ' : ' ';
            res += ' Hasta: ' + Number(filterValue?.endValue).toLocaleString();
          }
          return res;
        } 
        
        if (filter?.mode === 'rangeSelect' && filterValue?.rangeIndex >= 0) {
          const option = filter?.ranges[ parseInt(filterValue?.rangeIndex) ];
          return option?.rangeName;
        }
        return filterValue;
      };

      const attrVal = getValue();
      return shouldRenderValue && attrVal && (
        <div key={filterPopulated.id} className={`block px-2.5 py-0.5 text-sm rounded-full bg-gray-200 ${className}`}>
          <span className="">{taxonomyType?.name}: </span>
          <span className="pl-1 font-semibold">{attrVal}</span>
          {/* Agregar botón 'x' que elimine el filtro seleccionado utilizando alguna función de form 'react-final-form' */}
          <button
            type="button"
            className="ml-2 text-gray-500 hover:text-red-500"
            onClick={() => form.change(taxonomyType?.nameSlug, undefined)}
          >
            X
          </button>
        </div>
      );
    })
  );
}

export default SelectedValuesList;