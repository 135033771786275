import { useModule } from "../../context/ModuleContext";
import _ from 'lodash';

/*
Example:

  const RouteOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action,
    param: { 
      Model,
      config,
      isAllowed, 
      isMinBreakpointActive, 
      location, 
      navigate, 
      module, 
      action, 
      parsedParams
     }
  });
  <RouteOfModule />

  <PartOfModule
    type="main"
    action="AdminHomeBeforeLinksList"
  />

  <PartOfModule
    type="crud"
    action="ListHeaderExtraActions"
    entitySlug={entitySlug}
    // param={{}}
  />

  <PartOfModule
    type="codedDataType"
    fieldName={nameSlug}
    action="RenderShow"
    entitySlug={entitySlug}
    param={{ displayedValue }} 
  />

  <PartOfModule
    type="crud"
    action="UnderListItemHeader"
    entitySlug={entitySlug}
    param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
  />

  param = {
    // only for usePartOfModule on excecution mode, 
    mode: 'element' || 'function', // just return if not present

    // to find the component
    type,
    entitySlug,
    action,
    fieldName,

    // for component
    param
  }

*/
export default function PartOfModule(param) {
  const { modules, actionsByResource } = useModule();
  let Component = getPart(param, modules);
  return Component ? <Component {...{...param.param, modules, actionsByResource}} /> : null;
}

export const usePartOfModule = (param) => {
  const { modules, actionsByResource } = useModule();
  let Part = getPart(param, modules);
  if (!Part) { return null; }
  if (param.mode === 'element') {
    return <Part {...{...param.param, modules, actionsByResource}} />;
  } 
  else if (param.mode === 'function') {
    return Part({ ...param.param, modules, actionsByResource });
  }
  return Part;
}

const getPart = ({ type, entitySlug, action, fieldName }, modules) => {
  let Part = null;
  
  _.forEach(modules, ({ components, routesPublic, routesAdmin }) => {
    let foundedPart;
    // routes
    if (type === 'routePublic') {
      foundedPart = _.get(routesPublic, [entitySlug, action]);
    }
    if (type === 'routeAdmin') {
      foundedPart = _.get(routesAdmin, [entitySlug, action]);
    }
    // components
    if (type === 'main') {
      foundedPart = _.get(components, ['main', action]);
    }
    if (type === 'hook') {
      foundedPart = _.get(components, [entitySlug, action]);
    }
    if (type === 'crud') {
      foundedPart = _.get(components, [entitySlug, 'crud', action]);
    }
    if (type === 'codedDataType') {
      foundedPart = _.get(components, [entitySlug, 'codedDataTypes', fieldName, action]);
    }
    if (foundedPart) {
      Part = foundedPart;
      return false;
    }
  });

  return Part
}