import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudForm from "../components/SectionCrudForm";
import Entity from '../modules/entity/Entity';
import { Content as AdminEntityTaxonomy } from './AdminEntityCreatorTaxonomy';
import { Content as AdminEntityFilterMenu } from './AdminEntityCreatorFilterMenu';

import { 
  FormField,
  FormFieldSelect
} from '../components/Form';

export function Content() {
  const { entityId } = useParams();
  const navigate = useNavigate();
  const [selectedEntity, setSelectedEntity] = useState({});
  const [entitiesDocList, setEntitiesDocList] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      if (entityId === 'new') {
        setSelectedEntity({});
      } else {
        const entity = await Entity.findById(entityId);
        setSelectedEntity(entity);
      }
    };
    fetch();
  }, [entityId]);
  
  useEffect(() => {
    const fetch = async () => {
      if (!entitiesDocList) {
        let entityList = await Entity.getAll();
        entityList = Entity.sortDocs(entityList, 'sort');
        entityList = entityList.filter(entity => !entity?.data?.deleted);
        setEntitiesDocList(entityList);
      }
    };
    fetch();
  }, [entitiesDocList]);

  const handleSave = async (formValues) => {
    if (!formValues.nameSlug) {
      formValues.nameSlug = _.camelCase(formValues.name || '');
    }
    // Lógica para guardar la entidad
    const savedItem = await Entity.createOrUpdate(formValues);
    if (entityId === 'new') {
      navigate(`/admin/entity/creator`);
      navigate(`/admin/entity/creator/${savedItem.id}/form`);
    }
  };

  const fieldsRequired = ['name'];

  const onValidation = (values, errors) => {
    if (!values.name) {
      errors.name = ' ';
    }
  };

  const FormInputFields = (({ form, values }) => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" fieldsRequired={fieldsRequired} />
    <FormField name="nameSlug" title="ID" placeholder={_.camelCase(values?.name || '')} fieldsRequired={fieldsRequired} 
      onChange={(value) => {
        form.change('nameSlug', _.camelCase(value || ''));
      }}
    />
    {entitiesDocList ? (
      <FormFieldSelect 
        name="entityParent"
        title="Entidad superior"
        fieldsRequired={fieldsRequired}
        options={entitiesDocList.map((doc) => ({
          value: doc?.id,
          label: doc?.data?.name
        }))}
      />
    ) : null}
  </>));


  return (
    <div className="">
      {/* Entity Form */}
      <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
        <SectionCrudForm
          doc={selectedEntity}
          onSave={handleSave}
          editStyle="route"
          // validatiion
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          FormInputFields={FormInputFields}
        />
      </div>

      {entityId !== 'new' ? (
        <div className="mt-5 mb-10 p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg">
          {/* FilterMenu */}
          <AdminEntityFilterMenu entityId={entityId} />
          <hr className='my-5 border border-gray-100' />
          {/* Taxonomy */}
          <AdminEntityTaxonomy entityId={entityId} />
        </div>
      ) : ''}
    </div>
  );
}

export function AdminEntityCreatorForm() {
  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <Link to="/admin/entity/creator"><h2 className="text-xl font-thin mb-4 mr-3">Entidades</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Formulario</h2>
      </div>

      <Content />
    </LayoutAdmin>
  );
}
