import { useModule } from "../context/ModuleContext";
import _ from 'lodash';


const ComponentOfModule = ({ type, entitySlug, action, param, fieldName }) => {
  const { modules, actionsByResource } = useModule();
  let Component = null;
  
  modules.forEach(({ components }) => {
    let foundedPart;
    if (type === 'hook') {
      foundedPart = _.get(components, [entitySlug, action]);
    }
    if (type === 'crud') {
      foundedPart = _.get(components, [entitySlug, 'crud', action]);
    }
    if (type === 'codedDataType') {
      foundedPart = _.get(components, [entitySlug, 'codedDataTypes', fieldName, action]);
    }
    if (foundedPart) {
      Component = foundedPart;
      return false;
    }
  });

  return Component ? <Component {...{...param, modules, actionsByResource}} /> : null;
}

export default ComponentOfModule;