import { useState, useEffect } from 'react';
import Model from '../../libs/ModelClass';

export default class TaxonomyType extends Model {
  static collectionName = 'taxonomyTypes';

  constructor(data) {
    super(data);
  }

  static async findByEntityId(entityId) {
    const types = await this.where('entityId', '==', entityId);
    types.sort((a, b) => a.data.sort - b.data.sort);
    return types;
  }  
}

export const useTaxonomyTypesByEntityId = ({ entityId, onFetch }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [entityId]);

  /**
   * Obtiene los tipos de taxonomía disponibles y los almacena en el estado local.
   */
  const fetchData = async () => {
    try {
      const types = await TaxonomyType.findByEntityId(entityId);
      onFetch && onFetch(types);
      setData(types);
    } catch (error) {
      console.log('Error fetching taxonomy types:', error);
    }
  };

  return { 
    entityTaxonomyTypes: data
  };
}
