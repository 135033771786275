import Select from 'react-select';
import departmentAndCities from './departmentAndCities';

const getDepartmentOfDistrict = (value) => {
  return departmentAndCities.find(department => {
    return department.options.find(option => option.value === value)
  });
};

const RenderForm = ({ value, onChange, fieldName }) => {
  return (
    <div className="">
      <Select
        isMulti={false}
        options={departmentAndCities}
        placeholder={`Ciudad`}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'lightgray',
            primary: 'darkgray',
          },
        })}
        value={value}
        onChange={option => { 
          onChange(option);
        }}
      />
    </div>
  );
}

export const RenderShow = ({ doc, fieldName }) => {
  return (
    doc?.data ? (
      <div className="">
        {doc.data[fieldName]?.label}, {getDepartmentOfDistrict(doc.data[fieldName].value).label}
      </div>
    ) : null
  );
}

export const cityDataType = {
  RenderForm,
  RenderShow
};