import { useState } from 'react';
import _ from 'lodash';
import Dropdown from '../../components/ui/Dropdown';
// import { ModalAddFracciones } from './ModalAddFracciones';
import { ModalUpdateLotes } from './ModalUpdateLotes';
import { ModalAddFraccionesLotes } from './ModalAddFraccionesLotes';


export const ListHeaderExtraActionsForLotes = ({ Model, isAllowed, refreshList }) => {
  const [ modalToOpen, setModalToOpen ] = useState();

  const onClose = () => {
    refreshList();
    setModalToOpen(null);
  };

  return (<>
    <Dropdown label="Desde archivos">
      <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
        <li>
          <a onClick={() => setModalToOpen('addFraccion')} className="block px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
            Agregar nuevos lotes
          </a>
        </li>
        <li>
          <a onClick={() => setModalToOpen('updateLotes')} className="block px-3 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white cursor-pointer">
            Sincronizar estados de lotes
          </a>
        </li>
      </ul>
    </Dropdown>
  
    {modalToOpen === 'addFraccion' ? (
      <ModalAddFraccionesLotes onClose={onClose} Model={Model} />
      // <ModalAddFracciones onClose={onClose} Model={Model} />
    ) : null}

    {modalToOpen === 'updateLotes' ? (
      <ModalUpdateLotes onClose={onClose} Model={Model} />
    ) : null}
  </>);
};