import _ from 'lodash';

export const required = (value) => (value ? undefined : "Requerido");

export const RequiredByFieldsList = ({ input, name, fieldsRequired }) => {
  if (!fieldsRequired) return '';
  if (_.includes(fieldsRequired, name) && !input.value) {
    return (
      <div className="pt-0 text-sm text-red-600">*Requerido.</div>
    )
  }
  return ''
}

export const ShowError = ({ input, meta, name, fieldsRequired }) => {
  return (
    <>
      {meta.touched && meta.error !== ' ' ? (
        <div className="text-red-600" style={{fontSize: 12}}>{meta.error}</div>
      ) : ''}
      {/* <RequiredByFieldsList name={name} input={input} fieldsRequired={fieldsRequired} /> */}
    </>
  )
}