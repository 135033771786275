import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import ContactBTN from "../components/ContactBTN";
import HeaderHeroBlock from "../components/Blocks/HeaderHero";
import TextGridWithTitleBlock from "../components/Blocks/TextGridWithTitle";
import CtaMapaDeFraccionamientos from "../components/Blocks/CtaMapaDeFraccionamientos";
import CtaAccordionBlock from "../components/Blocks/CtaAccordion";
// import SuperOutstandingProduct from "../components/Blocks/SuperOutstandingProduct";
// import GridOutstandingProductsBlock from "../components/Blocks/GridOutstandingProducts";
import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import CtaImgAndContent from "../components/Blocks/CtaImgAndContent";
import FrecuentAnswersBlock from "../components/Blocks/FrecuentAnswers";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";
import { blocks } from "../stringsMap";
import { Link as ScrollLink } from 'react-scroll';
import FilterLotesByPriceRanges from '../modules/fraccionesLotes/FilterLotesByPriceRanges';
import { Link } from "react-router-dom";
import config from "../config";
import ContactFormBlock from "../components/Blocks/ContactForm";
import CarouselBlock from "../components/Blocks/CarouselBlock";


const HeaderMenu = ({ menuHeight }) => (<>
  <ScrollLink
    className="pl-6 sm:pl-2 sm md:px-3 text-gray-700 hover:text-black hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-lg font-semibold cursor-pointer"
    to="inicio"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}

  >
    Inicio
  </ScrollLink>
  <ScrollLink
    className="pl-6 sm:pl-2 md:px-3 text-gray-700 hover:text-black hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-lg font-semibold cursor-pointer"
    to="explorar"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Explorar
  </ScrollLink>
  <Link
    to="/fracciones/mapa"
    title={config.brand.name}
    className="pl-6 sm:pl-2 md:px-3 text-gray-700 hover:text-black hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-lg font-semibold cursor-pointer"
  >
    Mapa
  </Link>
  <ScrollLink
    className="pl-6 sm:pl-2 md:px-3 text-gray-700 hover:text-black hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-lg font-semibold cursor-pointer"
    to="contactos"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Contactos
  </ScrollLink>
</>)

export function PublicHome () {  

  // menuFooter: {}
  
  return (
    <LayoutPublic mainSectionClass="px-0" HeaderMenu={HeaderMenu}>
      {/* Header Hero */}
      {/* <HeaderHeroBlock data={blocks.headerHero} /> */}
      <div id='inicio' className='mt-12 md:mt-16'>
        <CarouselBlock data={blocks.ultimosLotes} />
      </div>

      <CtaMapaDeFraccionamientos data={blocks.mapaDeFraccionamientos} />
      
      <TextGridWithTitleBlock data={blocks.textGridWithTitle} />

      <FilterLotesByPriceRanges data={blocks.filterLotesByPriceRanges} />

      {/* <SuperOutstandingProduct data={blocks.mainOutstandingProduct} /> */}

      {/* <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} /> */}

      <ContactFormBlock data={blocks.contactForm} design="alt" />

      <CtaAccordionBlock data={blocks.ctaAccordion} />

      <CtaContactUsBlock data={blocks.ctaContactUs} />

      <CustomerReviewsBlock data={blocks.customerReviews} />

      <CtaImgAndContent data={blocks.ctaImgAndContent} />

      <FrecuentAnswersBlock data={blocks.frecuentAnswers} />
      
      {/* Footer */}

      <CtaImgFullBlock data={blocks.ctaFooter} />

      {/* fixed footer */}
      <footer className=" fixed bottom-2 right-2 w-48 z-50">
        <div className="">
          <ContactBTN className={'ml-2 mb-1'} />
        </div>
      </footer>
    </LayoutPublic>
  );
}