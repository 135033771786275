import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Model from "../libs/ModelClass";
import { useModule } from "../context/ModuleContext";
import { usePartOfModule } from "../components/Module/PartOfModule";
import { parseAttrValParams } from '../libs/utils';
import useBreakpoints from '../libs/useBreakpoints';
import config from "../config";
import Page404 from '../components/Page404';
import { LayoutAdmin } from "../components/LayoutAdmin";


export function AdminModulePage() {
  const { isAllowed } = useModule();
  const { isMinBreakpointActive } = useBreakpoints();
  const location = useLocation();
  const navigate = useNavigate();

  const { entitySlug, action, '*': attrValParams } = useParams();
  const [parsedParams, setParsedParams] = useState();

  useEffect(() => {
    if (!parsedParams) {
      setParsedParams( parseAttrValParams( attrValParams ) ); 
    }
  }, [location]);

  const routeOfModule = usePartOfModule({
    type: 'routeAdmin',
    entitySlug,
    action
  });

  return (
    isAllowed(routeOfModule?.permission?.resource, routeOfModule?.permission?.action) ? (
      (routeOfModule?.Content) ? (
        <routeOfModule.Content {...{ 
          parsedParams,
          entitySlug, 
          action,
          config,
          isAllowed, 
          Model,
          isMinBreakpointActive, 
          location, 
          navigate, 
         }} />)
      : (
        <LayoutAdmin>
          <Page404 />
        </LayoutAdmin>  
      )
    ) : null
  );
}
