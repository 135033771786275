import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';
import config from '../../config';
import { AddClassWhenVisible } from '../AddClassWhenVisible';

export default function CtaImgFullBlock({ data }) {
  return (
    <div className="py-16 lg:py-20 bg-cover bg-center relative" 
      style={{ backgroundImage: `url(${data.backgroundImageUrl})` }}> 

      {/* overlay */}
      <div className="absolute inset-0 bg-black opacity-30"></div>

      {/* cta */}
      <div className="relative z-10 container-width text-center ">
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <a href={data.urlDest} className="text-white font-semibold text-lg md:text-2xl cursor-pointer font-brand-mulish rounded-sm">
            {data.title}
          </a>
        </AddClassWhenVisible>
      </div>

      {/* links */}
      <div className="relative z-20 container-width text-white text-center mt-8 font-brand-mulish">
        <span className="text-base lg:text-xl">Seguinos en: </span>
        <div className="flex justify-center space-x-4 lg:space-x-6 text-3xl sm:text-4xl mt-3 mb-7 lg:mb-10">
          <a target='_blank' href={config.facebook}><FaFacebook /></a>
          <a target='_blank' href={config.whatsappFooter}><FaWhatsapp/></a>
          <a target='_blank' href={config.instagram}><FaInstagram/></a>
        </div>
        <div className="text-base lg:text-lg px-8">
          <div className="border-t pt-2">
            Tierra Guaraní | Paraguay 2023
          </div>
        </div>
        <div className="text-sm">{config.locationString}</div>
        <div className="">
          <a href={`mailto:${config.email}?subject=Consulta%20sobre%20propiedades`} target="_blank" rel="noopener noreferrer" className="text-base ml-2 mt-0">
            {config.email}
          </a>
        </div>
        <div className="">
          <a href={`https://wa.me/${config.number}?text=Consulta%20sobre%20propiedades`} target="_blank" rel="noopener noreferrer" className="text-base ml-2 mt-0">
            +{config.number}
          </a>
        </div>
      </div>
      
    </div>
  )
}