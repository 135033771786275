import { Link, useParams } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import EntityDocView from "../components/EntityDocView";
import { getImageURL } from "../libs/utils";


export function Content({ entity }) {
  const entitySlug = entity?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const { docId } = useParams();
  const doc = useStateSingleResult({
    Model: ExtendedModel, 
    id: docId
  });
  
  return (
    <div className="">
      <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
        <EntityDocView
          doc={doc}
          entitySlug={entitySlug}
          classes={{
            fieldContainer: 'py-1.5'
          }}
          render={({ ViewData, taxonomyTypesDocList, mainAttr, mainImgAttr }) => (
            <>
              {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
                <div className="flex flex-row mb-5">
                  <div className=" w-[70px] shadow-md hover:shadow-xl">
                    <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
                    className="rounded h-[70px] w-full object-cover" /> {/* image xs */}
                  </div>
                  <div className="px-2 w-auto">
                    <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl font-semibold' }} />
                  </div>
                </div>
              ) : (
                <ViewData field={mainAttr} classes={{ fieldLabel: "hidden", fieldContainer: 'text-2xl font-semibold' }} />
              )}

              {taxonomyTypesDocList.map((taxonomyType) => (
                (mainAttr !== taxonomyType.data.nameSlug) && taxonomyType.data.hidden !== true ? (
                  <ViewData key={taxonomyType.data.nameSlug} field={taxonomyType.data.nameSlug} />
                  // TODO: Agregar decorator y classes al TaxonomyType para View
                ) : null
              ))}
            </>
          )}
        />
      </div>
    </div>
  );
}

export function AdminEntityCrudShow() {
  const { entityId } = useParams();
  const entity = useStateSingleResult({
    Model: Model.extend('entities'), 
    id: entityId
  });

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <Link to={`/admin/entity/${entityId}`}><h2 className="text-xl font-thin mb-4 mr-3">{entity?.data?.name}</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Ver</h2>
      </div>

      {entity && <Content entity={entity} />}
    </LayoutAdmin>
  );
}
