import _ from 'lodash';
import JSZip from 'jszip';

export const convertKMZToObject = async (file) => {
  try {
    const zip = new JSZip();
    const zipContents = await zip.loadAsync(file);

    // Assuming there is only one KML file inside the KMZ
    const kmlFile = Object.values(zipContents.files).find((entry) => entry.name.toLowerCase().endsWith('.kml'));
    if (!kmlFile) {
      throw new Error('No KML file found in the KMZ.');
    }

    const kmlContent = await kmlFile.async('text');
    const parser = new DOMParser();
    const kmlDocument = parser.parseFromString(kmlContent, 'text/xml');

    const kmlObject = extractData(kmlDocument.documentElement);
    return kmlObject;
  } catch (error) {
    console.error('Error converting KMZ to object:', error);
  }
};

const extractData = (element) => {
  const extractedData = {};

  // Extract attributes
  const attributes = element.attributes;
  for (let i = 0; i < attributes.length; i++) {
    const attribute = attributes[i];
    extractedData[attribute.name] = attribute.value;
  }

  // Extract child elements
  const childNodes = element.childNodes;
  for (let i = 0; i < childNodes.length; i++) {
    const childNode = childNodes[i];

    if (childNode.nodeType === Node.ELEMENT_NODE) {
      const tagName = childNode.tagName;
      const textContent = childNode.textContent.trim(); // Trim whitespace from text content
      if (tagName) {
        let content = extractData(childNode);
        if (_.isEqual(content, {})) {
          content = textContent;
        }
        if (extractedData.hasOwnProperty(tagName)) {
          // If the element already exists, convert it to an array
          if (!Array.isArray(extractedData[tagName])) {
            extractedData[tagName] = [extractedData[tagName]];
          }
          extractedData[tagName].push(content); // Recursively extract nested data
        } else {
          extractedData[tagName] = content; // Recursively extract nested data
        }
      }
    }
  }

  return extractedData;
};

export function generatePointFromPolygonString(polygonCoordinates) {
  if (polygonCoordinates) {
    // Split the coordinates string into an array of coordinate pairs
    const coordinatePairs = polygonCoordinates.split(' ');
    
    // Calculate the centroid from the coordinate pairs
    const [sumX, sumY] = coordinatePairs.reduce((acc, coordinatePair) => {
      const [x, y] = coordinatePair.split(',').map(parseFloat);
      return [acc[0] + x, acc[1] + y];
    }, [0, 0]);

    const centerX = sumX / coordinatePairs.length;
    const centerY = sumY / coordinatePairs.length;

    return `${centerX},${centerY},0`;
  }
}

export function generatePointFromPolygonObject(polygonCoordinates) {
  if (polygonCoordinates) {
    // Calculate the centroid from the coordinate pairs
    const [sumX, sumY] = polygonCoordinates.reduce((acc, { lat, lng }) => {
      return [acc[0] + lng, acc[1] + lat];
    }, [0, 0]);
    
    const centerX = sumX / polygonCoordinates.length;
    const centerY = sumY / polygonCoordinates.length;
    
    return { lat: centerY, lng: centerX };
  }
}