import { Fragment, useState } from 'react';
import _ from 'lodash';
import toast from 'react-hot-toast';
import Modal from '../../components/Modal';
import { convertKMZToObject, generatePointFromPolygonString } from './kmzParser';
import { convertCSVToArray, convertXLSXToArray } from './csvParser';
import { priceFormat } from '../../libs/utils';


export const extractFraccionesLotes = async ({ kmz, csv }) => {
  if (!kmz && !csv) {
    throw new Error('Selecciona los archivos kmz y excel (csv ó xlsx)');
  }
  if (!(csv instanceof File)) {
    throw new Error('Please specify the csv file');
  }

  let csvList;
  const extension = csv.name.split('.').pop().toLowerCase();
  if (extension === 'csv') {
    csvList = await convertCSVToArray(csv);
  } 
  else if (extension === 'xlsx') {
    csvList = await convertXLSXToArray(csv);
  }
  else {
    throw new Error('Formato de archivo no admintido');
  }
  
  const kmzObject = await convertKMZToObject(kmz);

  // get placemarks
  const placemarksByName = {};
  kmzObject?.Document?.Folder?.Placemark?.forEach(place => {
    placemarksByName[place?.name] = place;
  });
  const assignPlacemarks = (data, key) => {
    data.polygon = placemarksByName[ data[key] ]?.Polygon?.outerBoundaryIs?.LinearRing;
    data.point = placemarksByName[ data[key] ]?.Point?.coordinates || generatePointFromPolygonString(data.polygon?.coordinates);
    // console.log('data', data, placemarksByName[ data[key] ]);
    if ( placemarksByName[ data[key] ]?.LookAt ) {
      const { latitude, longitude } = placemarksByName[ data[key] ]?.LookAt;
      data.lookAt = { latitude, longitude };
    }
  };

  // get lotes
  let lotesData = csvList.map(lote => {
    // console.log('lote', lote)
    const newLote = {
      num: lote['Lote'], // mainAttr
      estado: lote['Estado'].toLowerCase(),
      fraccion: lote['Fracción'],
      manzana: lote['Manzana'],
      cuota: parseInt(lote['Cuota']),
    };
    if (newLote.estado === 'recuperado') {
      newLote.estado = 'disponible';
    }
    assignPlacemarks(newLote, 'num');
    return newLote;
  });
  // group lotesList by fraccion
  let fraccionesData = [];
  const lotesByfracciones = _.groupBy(lotesData, 'fraccion');
  _.forEach(lotesByfracciones, (lotesOfFraccionamiento, fraccionName) => {
    const lotesByManzana = _.groupBy(lotesOfFraccionamiento, 'manzana');
    const manzanasNames = _.keys(lotesByManzana);
    const manzanasList = [];
    const newFraccionamiento = {
      name: fraccionName, // mainAttr
      manzanasList
    };
    assignPlacemarks(newFraccionamiento, 'name');
    if (manzanasNames?.length) {
      manzanasNames.forEach(manzanaName => {
        const newManzana = {
          name: manzanaName // mainAttr
        };
        assignPlacemarks(newManzana, 'name');
        manzanasList.push(newManzana);
      });
    }
    fraccionesData.push(newFraccionamiento);
  });

  return {
    lotesData, fraccionesData
  };
};

export const ModalAddFraccionesLotes = ({ onClose, Model }) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [processedFiles, setProcessedFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const canProcess = selectedFiles?.csv;

  const saveData = async () => {
    if (loading) { return; } // skip
    setLoading(true);
    const FraccionesModel = Model.extend('fracciones');
    const LotesModel = Model.extend('lotes');
    const { lotesData, fraccionesData } = processedFiles;
    console.log('lotesData, fraccionesData', lotesData, fraccionesData)
    // each fraccion
    for (const fraccion of fraccionesData) {
      let fraccionDoc = await FraccionesModel.whereOne('name', '==', fraccion.name);
      // if exists
      if (fraccionDoc) {
        // disable all lotes using updateMany
        const oldDocs = await LotesModel.filterByAttributes({
          fraccionId: fraccionDoc.id
        });
        console.log('oldDocs', oldDocs)
        await LotesModel.updateMany(oldDocs, {
          estado: 'oculto'
        });
        // undelete fraction
        fraccionDoc.data = {
          ...fraccionDoc.data,
          ...fraccion,
          deleted: false
        }
        await fraccionDoc.save();
      }
      // or, create fraccion
      else {
        fraccionDoc = await FraccionesModel.createOrUpdate(fraccion);
      }
      // save each lotes
        // filter by fraccion
        // attach fraccion id
      const lotesOfFraccionamiento = lotesData
        .filter((lote) => lote.fraccion === fraccionDoc.data.name)
        .sort((a, b) => a.num - b.num)
        .map((lote) => {
          lote.fraccionId = fraccionDoc.id;
          lote.num = parseInt(lote.num);
          return lote;
        });

      console.log('lotesData', lotesData)
      console.log('lotesOfFraccionamiento', lotesOfFraccionamiento)
      
      for (const lote of lotesOfFraccionamiento) {
        let loteDoc = await LotesModel.filterByAttributes({
          fraccionId: lote.fraccionId,
          manzana: lote.manzana,
          num: parseInt(lote.num)
        });
        console.log('loteDoc', loteDoc)
        // update lote
        if (loteDoc?.length) {
          loteDoc[0].data = { 
            ...loteDoc[0].data,
            ...lote,
            deleted: false
          };
          console.log('lote update', loteDoc[0].data)
          await loteDoc[0].save();
        } else {
          console.log('lote create')
          await LotesModel.create(lote);
        }
      }
    }
    
    toast('Se han importado los datos', { icon: 'ℹ️' });
    setLoading(false);
    closeModal();
  };

  const handleFileChange = (fileType) => (event) => {
    const file = event.target.files[0];
    setSelectedFiles({ ...selectedFiles, [fileType]: file });
  };

  const processData = async () => {
    if (canProcess) {
      try {
        const { lotesData, fraccionesData } = await extractFraccionesLotes(selectedFiles);
        setProcessedFiles({ lotesData, fraccionesData });
      } catch (error) {
        toast.error(error.message);
      }
    } 
  };

  const closeModal = () => {
    onClose && onClose();
    setSelectedFiles(null);
    setProcessedFiles(null);
  };

  return (
    <Modal
    title="Agregar nuevos lotes" open={true} setOpen={closeModal}
    classes={{ modalCardContainer: 'w-full sm:max-w-md mx-4 max-h-[90vh]', modalContent: 'pb-10' }}>

      <ul className="mb-10 list-disc list-inside">
        <li>Se crearán las fracciones necesarias</li>
        <li>Pueden agregarse varias fracciones simultáneamente</li>
        <li>Se guardarán los precios de cuotas de los lotes</li>
        <li>Se actualizarán los precios de cuotas de los lotes previamente existentes</li>
      </ul>

      <div className={`p-2 bg-gray-200 rounded border-[3px] ${selectedFiles?.csv ? 'border-blue-600' : 'border-gray-400'}`}>
        <label className='mb-2 block font-semibold'>Listado del sistema de administración - XLSX / CSV</label>
        <input type="file" accept=".csv, .xlsx" onChange={handleFileChange('csv')} className='' />
      </div>

      <button onClick={processData} className={`
        mt-4 px-3 p-1 text-lg text-white shadow-md rounded ${canProcess ? 'opacity-100 bg-blue-600' : 'opacity-30 cursor-not-allowed bg-gray-500'}
      `}>
        Procesar
      </button>

      <div className="mt-4">
        {processedFiles?.fraccionesData?.map((fraccion, index) => (
          <Fragment key={index}>
            <h4 className="mb-1 uppercase text-sm text-gray-400 tracking-wide">fracciones</h4>
            <div className="p-2 border border-gray-300 rounded">
              <h3 className="mb-2 font-bold text-xl">{fraccion.name}</h3>
              {/* manzanas */}
              <h4 className="mb-1 uppercase text-sm text-gray-400 tracking-wide">Manzanas y Lotes</h4>
              {fraccion.manzanasList.sort((a, b) => a.name - b.name).map((manzana, index) => (
                <div className="mb-3 flex flex-row gap-5" key={index}>
                  <div className="mt-0.5 font-semibold text-xl">
                    {manzana.name}
                  </div>
                  {/* lotes */}
                  <div className="w-full border border-gray-200 rounded">
                    <div className="px-4 grid grid-cols-3 gap-2 bg-gray-100 border-b border-gray-200 text-sm py-1 last:border-none" key={index}>
                      <div className={`text-left`}>Lote</div>
                      <div className={``}>Estado</div>
                      <div className={`text-right`}>Cuota</div>
                    </div>
                    {processedFiles?.lotesData?.
                      filter((lote) => 
                        lote.fraccion === fraccion.name
                        && lote.manzana === manzana.name
                      )?.
                      sort((a, b) => a.num - b.num)
                      .map((lote, index) => (
                        <div className={`px-4 grid grid-cols-3 gap-2 border-b border-gray-200 py-1.5 last:border-none ${lote.estado === 'disponible' ? 'font-semibold text-blue-700' : 'text-gray-500'}`} key={index}>
                          <div className={`text-left`}>{lote.num}</div>
                          <div className={``}>{lote.estado}</div>
                          <div className={`text-right`}>{priceFormat(lote.cuota)}</div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Fragment>
        ))}
      </div>
      {processedFiles?.fraccionesData ? (
        <div>
          <button className={`
            mt-3 px-3 p-1 text-lg text-left text-white 
            flex flex-row justify-center items-center gap-2
            shadow-md rounded ${canProcess ? 'opacity-100 bg-green-600' : 'opacity-30 cursor-not-allowed bg-gray-500'}
          `}
          onClick={saveData}>
            {loading ? (
              <svg className="animate-spin mx-auto h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
            Registrar
          </button>
          <div className='mt-2.5'>
            Se guardará los fracciones y lotes.
            <br />
            En caso de existir algunos datos, se actualizará con los nuevos valores.
          </div>
        </div>
      ) : null}
    </Modal>
  );
}