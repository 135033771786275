import { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import config from "../config";
import Item from "../models/Product";
import ItemDetailView from "../components/ItemDetailView";
import { blocks } from "../stringsMap";
import CtaAccordionBlock from "../components/Blocks/CtaAccordion";
import SuperOutstandingProduct from "../components/Blocks/SuperOutstandingProduct";
import GridOutstandingProductsBlock from "../components/Blocks/GridOutstandingProducts";
import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import FrecuentAnswersBlock from "../components/Blocks/FrecuentAnswers";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";

export function ItemShow () {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  const navigate = useNavigate();

  const fetchItem = async () => {
    const item = await Item.find(itemId);
    setItem(item);
    console.log('Item id:', item.id);
  };

  useEffect(() => {
    fetchItem();
  }, [itemId]);

  const getPageTitle = (suffix) => {
    // Resto de tu lógica para obtener el título de la página
    return suffix + " | " + itemId;
  };

  return (
    <LayoutPublic>
      <MetaTags>
        <title>{getPageTitle(config.projectName)}</title>
        <meta name={getPageTitle()} content={config.projectName} />
      </MetaTags>
      <div className="mt-5">
        <button type="button" className="px-2 py-2 w-32 text-left border rounded-lg shadow-md border-gray-200 bg-gray-50" onClick={() => {
          // GPT navigate a /filter
          navigate('/filter');
        }}>
          Buscar...
        </button>
      </div>
      <hr className="border-gray-200 my-5" />
      {item ? (
        <ItemDetailView item={item} />
      ) : ''}

      <div className="-mx-5 mt-20">
        <CtaContactUsBlock data={blocks.ctaContactUs} />

        <SuperOutstandingProduct data={blocks.mainOutstandingProduct} />

        <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} />

        <CtaAccordionBlock data={blocks.ctaAccordion} />


        <CustomerReviewsBlock data={blocks.customerReviews} />

        <FrecuentAnswersBlock data={blocks.frecuentAnswers} />

        <CtaImgFullBlock data={blocks.ctaFooter} />
      </div>

    </LayoutPublic>
  );
}
