import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';


export function AddClassWhenVisible({ children, classToAdd }) {
  const [isVisible, setIsVisible] = useState(false);

  const onSensor = (isVisible) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  // Verificando si children es un único elemento React
  const child = React.Children.only(children);

  // Agregar la clase classToAdd si el elemento es visible
  const className = isVisible ? `${child.props.className} ${classToAdd}` : `${child.props.className} opacity-0`;

  return (
    <VisibilitySensor onChange={onSensor} partialVisibility>
      {React.cloneElement(child, { className })}
    </VisibilitySensor>
  );
}
