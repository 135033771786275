import { memoize } from 'lodash'

/**
 * WARNING: Use this method directly to avoid memoization cache
 * @param {string} src - URL of script to lazy-load
 */
export const load = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.charset = 'utf-8'
    script.async = false

    // Handlers
    script.onload = resolve
    script.onerror = reject
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded') {
        resolve()
      } else {
        reject()
      }
    }

    script.src = src
    document.body.appendChild(script)
  })

const cachedLoad = memoize(load)

/**
 * Use this to handle caching of script imports
 * @param {string} src - URL of script to lazy-load
 * @param {boolean} [force] - Skip cached load
 */
export default (src, force) => {
  if (force) {
    return load(src)
  }
  return cachedLoad(src)
}