export default [{
  label: "ASUNCIÓN",
  options: [
    { value: "0000", label: "ASUNCIÓN" }
  ]
}, {
  label: "CONCEPCIÓN",
  options: [
    { value: "0101", label: "CONCEPCIÓN" },
    { value: "0102", label: "BELÉN" },
    { value: "0103", label: "HORQUETA" },
    { value: "0104", label: "LORETO" },
    { value: "0105", label: "SAN CARLOS DEL APA" },
    { value: "0106", label: "SAN LÁZARO" },
    { value: "0107", label: "YBY YAÚ" },
    { value: "0108", label: "AZOTE'Y" },
    { value: "0109", label: "SARGENTO JOSÉ FÉLIX LÓPEZ" },
    { value: "0110", label: "SAN ALFREDO" },
    { value: "0111", label: "PASO BARRETO" }
  ]
}, {
  label: "SAN PEDRO",
  options: [
    { value: "0201",	label: "SAN PEDRO DEL YCUAMANDYYÚ" },
    { value: "0202",	label: "ANTEQUERA" },
    { value: "0203",	label: "CHORÉ" },
    { value: "0204",	label: "GENERAL ELIZARDO AQUINO" },
    { value: "0205",	label: "ITACURUBÍ DEL ROSARIO" },
    { value: "0206",	label: "LIMA" },
    { value: "0207",	label: "NUEVA GERMANIA" },
    { value: "0208",	label: "SAN ESTANISLAO" },
    { value: "0209",	label: "SAN PABLO" },
    { value: "0210",	label: "TACUATÍ" },
    { value: "0211",	label: "UNIÓN" },
    { value: "0212",	label: "25 DE DICIEMBRE" },
    { value: "0213",	label: "VILLA DEL ROSARIO" },
    { value: "0214",	label: "GENERAL FRANCISCO ISIDORO RESQUÍN" },
    { value: "0215",	label: "YATAITY DEL NORTE" },
    { value: "0216",	label: "GUAJAYVI" },
    { value: "0217",	label: "CAPIIBARY" },
    { value: "0218",	label: "SANTA ROSA DEL AGUARAY" },
    { value: "0219",	label: "YRYBUCUA" },
    { value: "0220",	label: "LIBERACIÓN" },
  ]
}, {
  label: "CORDILLERA",
  options: [
    { value: "0301",	label: "CAACUPÉ" },
    { value: "0302",	label: "ALTOS" },
    { value: "0303",	label: "ARROYOS Y ESTEROS" },
    { value: "0304",	label: "ATYRÁ" },
    { value: "0305",	label: "CARAGUATAY" },
    { value: "0306",	label: "EMBOSCADA" },
    { value: "0307",	label: "EUSEBIO AYALA" },
    { value: "0308",	label: "ISLA PUCÚ" },
    { value: "0309",	label: "ITACURUBÍ DE LA CORDILLERA" },
    { value: "0310",	label: "JUAN DE MENA" },
    { value: "0311",	label: "LOMA GRANDE" },
    { value: "0312",	label: "MBOCAYATY DEL YHAGUY" },
    { value: "0313",	label: "NUEVA COLOMBIA" },
    { value: "0314",	label: "PIRIBEBUY" },
    { value: "0315",	label: "PRIMERO DE MARZO" },
    { value: "0316",	label: "SAN BERNARDINO" },
    { value: "0317",	label: "SANTA ELENA" },
    { value: "0318",	label: "TOBATÍ" },
    { value: "0319",	label: "VALENZUELA" },
    { value: "0320",	label: "SAN JOSE OBRERO" }
  ]
}, {
  label: "GUAIRÁ",
  options: [
    { value: "0401",	label: "VILLARRICA" },
    { value: "0402",	label: "BORJA" },
    { value: "0403",	label: "CAPITÁN MAURICIO JOSÉ TROCHE" },
    { value: "0404",	label: "CORONEL MARTÍNEZ" },
    { value: "0405",	label: "FÉLIX PÉREZ CARDOZO" },
    { value: "0406",	label: "GRAL. EUGENIO A. GARAY" },
    { value: "0407",	label: "INDEPENDENCIA" },
    { value: "0408",	label: "ITAPÉ" },
    { value: "0409",	label: "ITURBE" },
    { value: "0410",	label: "JOSÉ FASSARDI" },
    { value: "0411",	label: "MBOCAYATY" },
    { value: "0412",	label: "NATALICIO TALAVERA" },
    { value: "0413",	label: "ÑUMÍ" },
    { value: "0414",	label: "SAN SALVADOR" },
    { value: "0415",	label: "YATAITY" },
    { value: "0416",	label: "DOCTOR BOTTRELL" },
    { value: "0417",	label: "PASO YOBAI" },
    { value: "0418",	label: "TEBICUARY" }
  ]
}, {
  label: "CAAGUAZÚ",
  options: [
    { value: "0501",	label: "CORONEL OVIEDO" },
    { value: "0502",	label: "CAAGUAZÚ" },
    { value: "0503",	label: "CARAYAÓ" },
    { value: "0504",	label: "DR. CECILIO BÁEZ" },
    { value: "0505",	label: "SANTA ROSA DEL MBUTUY" },
    { value: "0506",	label: "DR. JUAN MANUEL FRUTOS" },
    { value: "0507",	label: "REPATRIACIÓN" },
    { value: "0508",	label: "NUEVA LONDRES" },
    { value: "0509",	label: "SAN JOAQUÍN" },
    { value: "0510",	label: "SAN JOSÉ DE LOS ARROYOS" },
    { value: "0511",	label: "YHÚ" },
    { value: "0512",	label: "DR. J. EULOGIO ESTIGARRIBIA" },
    { value: "0513",	label: "R.I. 3 CORRALES" },
    { value: "0514",	label: "RAÚL ARSENIO OVIEDO" },
    { value: "0515",	label: "JOSÉ DOMINGO OCAMPOS" },
    { value: "0516",	label: "MARISCAL FRANCISCO SOLANO LÓPEZ" },
    { value: "0517",	label: "LA PASTORA" },
    { value: "0518",	label: "3 DE FEBRERO" },
    { value: "0519",	label: "SIMÓN BOLIVAR" },
    { value: "0520",	label: "VAQUERÍA" },
    { value: "0521",	label: "TEMBIAPORÁ" },
    { value: "0522",	label: "NUEVA TOLEDO" }
  ]
}, {
  label: "CAAZAPÁ",
  options: [
    { value: "0601", label: "CAAZAPÁ" },
    { value: "0602", label: "ABAÍ" },
    { value: "0603", label: "BUENA VISTA" },
    { value: "0604", label: "DR. MOISÉS S. BERTONI" },
    { value: "0605", label: "GRAL. HIGINIO MORINIGO" },
    { value: "0606", label: "MACIEL" },
    { value: "0607", label: "SAN JUAN NEPOMUCENO" },
    { value: "0608", label: "TAVAÍ" },
    { value: "0609", label: "YEGROS" },
    { value: "0610", label: "YUTY" },
    { value: "0611", label: "3 DE MAYO" }
  ]
}, {
  label: "ITAPÚA",
  options: [
    { value: "0701",	label: "ENCARNACIÓN" },
    { value: "0702",	label: "BELLA VISTA" },
    { value: "0703",	label: "CAMBYRETÁ" },
    { value: "0704",	label: "CAPITÁN MEZA" },
    { value: "0705",	label: "CAPITÁN MIRANDA" },
    { value: "0706",	label: "NUEVA ALBORADA" },
    { value: "0707",	label: "CARMEN DEL PARANÁ" },
    { value: "0708",	label: "CORONEL BOGADO" },
    { value: "0709",	label: "CARLOS ANTONIO LÓPEZ" },
    { value: "0710",	label: "NATALIO" },
    { value: "0711",	label: "FRAM" },
    { value: "0712",	label: "GENERAL ARTIGAS" },
    { value: "0713",	label: "GENERAL DELGADO" },
    { value: "0714",	label: "HOHENAU" },
    { value: "0715",	label: "JESÚS" },
    { value: "0716",	label: "JOSÉ LEANDRO OVIEDO" },
    { value: "0717",	label: "OBLIGADO" },
    { value: "0718",	label: "MAYOR JULIO DIONISIO OTAÑO" },
    { value: "0719",	label: "SAN COSME Y DAMIAN" },
    { value: "0720",	label: "SAN PEDRO DEL PARANÁ" },
    { value: "0721",	label: "SAN RAFAEL DEL PARANÁ" },
    { value: "0722",	label: "TRINIDAD" },
    { value: "0723",	label: "EDELIRA" },
    { value: "0724",	label: "TOMÁS ROMERO PEREIRA" },
    { value: "0725",	label: "ALTO VERÁ" },
    { value: "0726",	label: "LA PAZ" },
    { value: "0727",	label: "YATYTAY" },
    { value: "0728",	label: "SAN JUAN DEL PARANÁ" },
    { value: "0729",	label: "PIRAPÓ" },
    { value: "0730",	label: "ITAPÚA POTY" }
  ]
}, {
  label: "MISIONES",
  options: [
    { value: "0801", label:	"SAN JUAN BAUTISTA DE LAS MISIONES" },
    { value: "0802", label:	"AYOLAS" },
    { value: "0803", label:	"SAN IGNACIO" },
    { value: "0804", label:	"SAN MIGUEL" },
    { value: "0805", label:	"SAN PATRICIO" },
    { value: "0806", label:	"SANTA MARÍA" },
    { value: "0807", label:	"SANTA ROSA" },
    { value: "0808", label:	"SANTIAGO" },
    { value: "0809", label:	"VILLA FLORIDA" },
    { value: "0810", label:	"YABEBYRY" }
  ]
}, {
  label: "PARAGUARÍ",
  options: [
    { value: "0901",	label: "PARAGUARÍ" },
    { value: "0902",	label: "ACAHAY" },
    { value: "0903",	label: "CAAPUCÚ" },
    { value: "0904",	label: "CABALLERO" },
    { value: "0905",	label: "CARAPEGUÁ" },
    { value: "0906",	label: "ESCOBAR" },
    { value: "0907",	label: "LA COLMENA" },
    { value: "0908",	label: "MBUYAPEY" },
    { value: "0909",	label: "PIRAYÚ" },
    { value: "0910",	label: "QUIINDY" },
    { value: "0911",	label: "QUYQUYHÓ" },
    { value: "0912",	label: "ROQUE GONZALEZ DE SANTACRUZ" },
    { value: "0913",	label: "SAPUCÁI" },
    { value: "0914",	label: "TEBICUARY-MÍ" },
    { value: "0915",	label: "YAGUARÓN" },
    { value: "0916",	label: "YBYCUÍ" },
    { value: "0917",	label: "YBYTYMÍ" }
  ]
}, {
  label: "ALTO PARANÁ",
  options: [
    { value: "1001",	label: "CIUDAD DEL ESTE" },
    { value: "1002",	label: "PRESIDENTE FRANCO" },
    { value: "1003",	label: "DOMINGO MARTÍNEZ DE IRALA" },
    { value: "1004",	label: "DR. JUAN LEÓN MALLORQUÍN" },
    { value: "1005",	label: "HERNANDARIAS" },
    { value: "1006",	label: "ITAKYRY" },
    { value: "1007",	label: "JUAN E. O'LEARY" },
    { value: "1008",	label: "ÑACUNDAY" },
    { value: "1009",	label: "YGUAZÚ" },
    { value: "1010",	label: "LOS CEDRALES" },
    { value: "1011",	label: "MINGA GUAZÚ" },
    { value: "1012",	label: "SAN CRISTOBAL" },
    { value: "1013",	label: "SANTA RITA" },
    { value: "1014",	label: "NARANJAL" },
    { value: "1015",	label: "SANTA ROSA DEL MONDAY" },
    { value: "1016",	label: "MINGA PORÁ" },
    { value: "1017",	label: "MBARACAYÚ" },
    { value: "1018",	label: "SAN ALBERTO" },
    { value: "1019",	label: "IRUÑA" },
    { value: "1020",	label: "SANTA FE DEL PARANÁ" },
    { value: "1021",	label: "TAVAPY" },
    { value: "1022",	label: "DR. RAÚL PEÑA" }
  ]
}, {
  label: "CENTRAL",
  options: [
    { value: "1101", label: "AREGUÁ" },
    { value: "1102", label: "CAPIATÁ" },
    { value: "1103", label: "FERNANDO DE LA MORA" },
    { value: "1104", label: "GUARAMBARÉ" },
    { value: "1105", label: "ITÁ" },
    { value: "1106", label: "ITAUGUÁ" },
    { value: "1107", label: "LAMBARÉ" },
    { value: "1108", label: "LIMPIO" },
    { value: "1109", label: "LUQUE" },
    { value: "1110", label: "MARIANO ROQUE ALONSO" },
    { value: "1111", label: "NUEVA ITALIA" },
    { value: "1112", label: "ÑEMBY" },
    { value: "1113", label: "SAN ANTONIO" },
    { value: "1114", label: "SAN LORENZO" },
    { value: "1115", label: "VILLA ELISA" },
    { value: "1116", label: "VILLETA" },
    { value: "1117", label: "YPACARAÍ" },
    { value: "1118", label: "YPANÉ" },
    { value: "1119", label: "J. AUGUSTO SALDIVAR" }
  ]
}, {
  label: "ÑEEMBUCÚ",
  options: [
    { value: "1201", label: "PILAR" },
    { value: "1202", label: "ALBERDI" },
    { value: "1203", label: "CERRITO" },
    { value: "1204", label: "DESMOCHADOS" },
    { value: "1205", label: "GRAL. JOSÉ EDUVIGIS DÍAZ" },
    { value: "1206", label: "GUAZÚ-CUÁ" },
    { value: "1207", label: "HUMAITÁ" },
    { value: "1208", label: "ISLA UMBÚ" },
    { value: "1209", label: "LAURELES" },
    { value: "1210", label: "MAYOR JOSÉ DEJESÚS MARTÍNEZ" },
    { value: "1211", label: "PASO DE PATRIA" },
    { value: "1212", label: "SAN JUAN BAUTISTA DE ÑEEMBUCÚ" },
    { value: "1213", label: "TACUARAS" },
    { value: "1214", label: "VILLA FRANCA" },
    { value: "1215", label: "VILLA OLIVA" },
    { value: "1216", label: "VILLALBÍN" }
  ]
}, {
  label: "AMAMBAY",
  options: [
    { value: "1301",	label: "PEDRO JUAN CABALLERO" },
    { value: "1302",	label: "BELLA VISTA" },
    { value: "1303",	label: "CAPITÁN BADO" },
    { value: "1304",	label: "ZANJA PYTÃ" },
    { value: "1305",	label: "KARAPAÍ" }
  ]
}, {
  label: "CANINDEYÚ",
  options: [
    { value: "1401",	label: "SALTO DEL GUAIRÁ" },
    { value: "1402",	label: "CORPUS CHRISTI" },
    { value: "1403",	label: "VILLA CURUGUATY" },
    { value: "1404",	label: "VILLA YGATIMÍ" },
    { value: "1405",	label: "ITANARÁ" },
    { value: "1406",	label: "YPEJHÚ" },
    { value: "1407",	label: "FRANCISCO CABALLERO ALVAREZ" },
    { value: "1408",	label: "KATUETÉ" },
    { value: "1409",	label: "LA PALOMA DEL ESPÍRITU SANTO" },
    { value: "1410",	label: "NUEVA ESPERANZA" },
    { value: "1411",	label: "YASY CAÑY" },
    { value: "1412",	label: "YBYRAROBANÁ" },
    { value: "1413",	label: "YBY PYTÁ" }
  ]
}, {
  label: "PRESIDENTE HAYES",
  options: [
    { value: "1502",	label: "BENJAMÍN ACEVAL" },
    { value: "1503",	label: "PUERTO PINASCO" },
    { value: "1504",	label: "VILLA HAYES" },
    { value: "1505",	label: "NANAWA" },
    { value: "1506",	label: "JOSÉ FALCÓN" },
    { value: "1507",	label: "TTE. 1° MANUEL IRALA FERNÁNDEZ" },
    { value: "1508",	label: "TENIENTE ESTEBAN MARTÍNEZ" },
    { value: "1509",	label: "GENERAL JOSÉ MARÍA BRUGUEZ" }
  ]
}, {
  label: "BOQUERÓN",
  options: [
    { value: "1602", label: "MARISCAL JOSÉ FÉLIX ESTIGARRIBIA" },
    { value: "1604", label: "FILADELFIA" },
    { value: "1605", label: "LOMA PLATA" }
  ]
}, {
  label: "ALTO PARAGUAY",
  options: [
    { value: "1701",	label: "FUERTE OLIMPO" },
    { value: "1702",	label: "PUERTO CASADO" },
    { value: "1704",	label: "BAHÍA NEGRA" },
    { value: "1705",	label: "CARMELO PERALTA" }
  ]
}];