import config from './config';

export const blocks = {
  headerHero: {
    items: [
      {
       imageSrc: "main-photo.jpg",
       imageSrcAlign: "bottom",
       imageSrcLg: "main-photo-2.jpg",
       title:"Desarrollos",
       subtitle:"inmobiliarios",
       ctaButtons: [
        {
          ctaTitle: "Ver opciones",
          ctaType: "link",
          ctaUrl: "/fracciones/mapa"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20una%20visita%20a%20un%20lote,%20podrian%20ayudarme?"
        }
       ]
      },
      {
       imageSrc: "/portada.jpg",
       title:"Ventas de Lotes",
       ctaButtons: [
        {
          ctaTitle: "Ver opciones",
          ctaType: "link",
          ctaUrl: "/fracciones/mapa"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20la%20compra/venta%20de%20un%20lote,%20podrian%20ayudarme?"
        }
       ] 
      },
      {
       imageSrc: "/portada-1.jpg",
       title:"intermediación para venta de inmuebles",
       ctaButtons: [
        {
          ctaTitle: "Ver opciones",
          ctaType: "link",
          ctaUrl: "/fracciones/mapa"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20la%20compra/venta%20de%20un%20inmueble,%20podrian%20ayudarme?"
        }
       ] 
      },

    ]
  },
  textGridWithTitle: {
    title: "Descubrí",
    subtitle: "Nuestras propiedades",
    items: [
      {
        title: "Casas modernas",
        content: "Explora nuestra amplia selección de casas modernas con diseños elegantes y comodidades de alta calidad."
      },
      {
        title: "Terrenos espaciosos",
        content: "Ofrecemos terrenos espaciosos en ubicaciones privilegiadas, perfectos para construir la casa de tus sueños."
      },
      {
        title: "Inversiones inteligentes",
        content: "Te ofrecemos oportunidades de inversión inteligentes con altos rendimientos y potencial de crecimiento."
      }
    ]
  },
  ultimosLotes: {
    items: [
      {
       imageSrc: "/flyer-itaugua.jpg",
       title: "Ultimos lotes en Itaugua",
       ctaButtons: [
        {
          ctaTitle: "Ver fracción TIERRA GUARANÍ I",
          ctaType: "link",
          ctaUrl: "/fracciones/show/id/AU1epCDEpU48fHoLASQ2"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20una%20visita%20a%20un%20lote,%20podrian%20ayudarme?"
        }
       ]
      },
      {
       imageSrc: "/flyer-arroyos.jpg",
       title: "Ultimos lotes en Arroyos y Esteros",
       ctaButtons: [
        {
          ctaTitle: "Ver fracción VISTA BELLA",
          ctaType: "link",
          ctaUrl: "/fracciones/show/id/aFlxFE7qqr3f3TJcACA2"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20una%20visita%20a%20un%20lote,%20podrian%20ayudarme?"
        }
       ]
      },
      {
       imageSrc: "/flyer-caacupe.jpg",
       title: "Ultimos lotes en Caacupe",
       ctaButtons: [
        {
          ctaTitle: "Ver fracción INDIO JOSÉ I",
          ctaType: "link",
          ctaUrl: "/fracciones/show/id/1EbCINKCT9Jfe6UVBB0i"
        },
        {
          ctaTitle: "Ver fracción LA HEROICA",
          ctaType: "link",
          ctaUrl: "/fracciones/show/id/sRKDgnzlwOMOXcNHFuTv"
        },
        {
          ctaTitle: "Coordinar visita",
          ctaType: "whatsapp",
          ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20agendar%20una%20visita%20a%20un%20lote,%20podrian%20ayudarme?"
        }
       ]
      },
    ]
  },
  mapaDeFraccionamientos: {
    title: "Explorá",
    subtitle: "Mapa de Loteamientos",
    ctaTitle: "Ver el Mapa",
    ctaUrl: "/fracciones/mapa"
  },

  filterLotesByPriceRanges: {
    title: "Encontrá",
    subtitle: "El lote ideal al mejor precio",
    ctaTitle: "* Consultá planes personalizados",
    ctaUrl: "https://wa.me/595984577585?text=Hola,%20Quisiera%20consultar%20sobre%20planes%20personalizados,%20podrian%20ayudarme?",
    priceStepsTitle: "Cuotas desde",
    priceSteps: [
      158000,
      264000,
      330000,
      396000,
      752000,
      898000,
      1478000,
      5500000
    ]
  },
  ctaAccordion: {
    title: "¿Qué podemos hacer por ti?",
    subtitle: "Explorá nuestras opciones",
    items: [
      {
        title: "Compra",
        content: "Encuentra la propiedad perfecta para ti, ya sea una casa, un apartamento o un terreno.",
        ctaTitle: "Ver opciones",
        ctaType: "link",
        ctaUrl: "/fracciones/mapa"
      },
      {
        title: "Venta",
        content: "Confía en nosotros para vender tu propiedad al mejor precio y en el menor tiempo posible.",
        ctaTitle: "Coordinar venta",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595984577585?text=Hola,%20estoy%20interesado%20en%20vender%20mi%20propiedad"
      },
      {
        title: "Asesoría",
        content: "Recibe asesoramiento personalizado de nuestro equipo de expertos en bienes raíces.",
        ctaTitle: "Contactar",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595984577585?text=Hola,%20necesito%20asesoramiento%20en%20bienes%20raíces"
      },
      {
        title: "Forma Parte",
        content: "Únete a nuestro equipo y forma parte de la familia de Tierra Guaraní Inmobiliaria.",
        ctaTitle: "Ver oportunidades",
        ctaType: "whatsapp",
        ctaUrl: "https://wa.me/595984577585?text=Hola,%20estoy%20interesado%20en%20formar%20parte%20de%20su%20equipo"
      }
    ]
  },
  mainOutstandingProduct: {
    title: "Propiedad destacada",
    subtitle: "Casa de lujo en la playa"
  },
  gridOutstandingProducts: {
    title: "Propiedades destacadas",
    subtitle: "Descubre nuestras mejores ofertas"
  },
  contactForm: {
    title: "Escribinos",
    subtitle: "¡Estamos aquí para ayudarte!",
    phoneNumber: config.numberString
  },
  ctaContactUs: {
    title: "Contáctanos",
    subtitle: "¡Estamos aquí para ayudarte!",
    address: config.locationString,
    phoneNumber: config.numberString,
    email: config.email,
    content: "Si tienes alguna consulta o necesitas más información, no dudes en contactarnos.",
    backgroundImageUrl: "/3.jpg",
    items: [
      {
        iconUrl: '/whatsapp-blanco.png',
        title: "WhatsApp",
        url: "https://wa.me/595984577585?text=Hola,%20necesito%20información%20sobre%20sus%20propiedades"
      },
      {
        iconUrl: '/phone-blanco.png',
        title: "Llamar",
        url: "tel:+595984577585"
      },
      {
        iconUrl: '/email-blanco.png',
        title: "Email",
        url: `mailto:${config.email}?subject=Consulta%20sobre%20propiedades`
      },
      {
        iconUrl: '/location-blanco.png',
        title: "Ubicación",
        url: "https://goo.gl/maps/4PJUwTGyEUbvc2Zt8"
      }
    ]
  },
  customerReviews: {
    title: "Opiniones",
    subtitle: "Descubre lo que dicen",
    content: "Nuestros clientes valoran nuestro trabajo y compromiso. Aquí tienes algunas de sus opiniones.",
    items: [
      {
        title: "¡Excelente servicio!",
        content: "Encontré la casa de mis sueños gracias a Tierra Guaraní Inmobiliaria."
      },
      {
        title: "Increíble atención al cliente",
        content: "Siempre dispuestos a ayudar y encontrar soluciones."
      },
      {
        title: "Experiencia profesional",
        content: "Muy satisfecho con la asesoría y el proceso de compra de mi propiedad."
      },
      {
        title: "Recomendado 100%",
        content: "Recomiendo Tierra Guaraní Inmobiliaria a todos los que buscan calidad y confianza."
      }
    ]
  },
  ctaImgAndContent:{
    
    firstItem: {
      title: "Compra",
      content: "Encuentra la propiedad perfecta para ti, ya sea una casa, un apartamento o un terreno.",
      imageUrl: "/4.jpg",
      iconUrl: "/whatsapp-blanco.png",
      ctaTitle: "Ver opciones",
      ctaType: "link",
      ctaUrl: "/fracciones/mapa"
    },
    secondItem: {
      title: "Forma Parte",
      content: "Únete a nuestro equipo y forma parte de la familia de Tierra Guaraní Inmobiliaria.",
      imageUrl: "/2.jpg",
      iconUrl: "/whatsapp-blanco.png",
      ctaTitle: "Ver oportunidades",
      ctaType: "whatsapp",
      ctaUrl: "https://api.whatsapp.com/send?phone=595984577585&text=Hola,%20estoy%20interesado%20en%20formar%20parte%20de%20su%20equipo"
    }
    
  },
  frecuentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cuáles son los documentos necesarios para comprar una propiedad?",
        content: "Para comprar una propiedad, necesitarás presentar una identificación válida, comprobante de ingresos y una carta de preaprobación de un banco o entidad financiera, entre otros documentos. Contáctanos para obtener más detalles."
      },
      {
        title: "¿Cuál es el plazo de financiamiento?",
        content: "Ofrecemos financiamiento propio con plazos de 5 a 10 años. Podrás adquirir una propiedad a precios de alquiler y pagarla a lo largo del tiempo. Consulta nuestros planes para obtener más información."
      },
      {
        title: "¿Cuánto tiempo se tarda en vender una propiedad?",
        content: "El tiempo necesario para vender una propiedad puede variar según diferentes factores, como el mercado inmobiliario actual, la ubicación y las características de la propiedad, así como el precio de venta. Nuestro equipo trabajará contigo para establecer una estrategia de venta efectiva y lograr resultados en el menor tiempo posible."
      },
      {
        title: "¿Cómo puedo unirme al equipo de Tierra Guaraní Inmobiliaria?",
        content: "Nos complace que estés interesado en unirte a nuestro equipo. Te recomendamos visitar nuestra página de carreras en nuestro sitio web, donde encontrarás información sobre las oportunidades de trabajo actuales y cómo enviar tu solicitud."
      }
    ]
  },
  ctaFooter: {
    title: "¡Convierte tus sueños en realidad!",
    urlDest: '/',
    backgroundImageUrl: '/footer-background.jpg'
  }
};
