import { useEffect, useState } from 'react';
import Entity, { useEntityFullBySlug } from '../modules/entity/Entity';

const EntityGetOneDocument = ({
  entitySlug,
  docId,
  children
}) => {
  let { doc, mainAttr, mainImgAttr } = useEntityFullBySlug({ docId, entitySlug });

  return (
    (typeof children === 'function' && doc) ? children({ doc, mainAttr, mainImgAttr }) : null
  );
};

export default EntityGetOneDocument;
