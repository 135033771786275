import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { required, RequiredByFieldsList, ShowError } from './formUtils';

const FormFieldTextArea = ({
  name,
  title,
  placeholder,
  className,
  fieldsRequired = null,
  type,
  showLabel = true,
  validate = null,
  disabled = false,
  formatter = null,
  minValue = null,
  maxValue = null,
  minLength = null,
  maxLength = null,
  UnderInput = null,
  onChange = null,
  onBlur = null,
}) => {
  const validateField = (value) => {
    let error;
  
    if (value !== undefined) {
      if (type === 'number') {
        const numericValue = parseFloat(value);
  
        if (minValue && numericValue < parseFloat(minValue)) {
          error = `El valor debe ser mayor o igual a ${minValue}`;
        } else if (maxValue && numericValue > parseFloat(maxValue)) {
          error = `El valor debe ser menor o igual a ${maxValue}`;
        }
      } else if (type === 'text') {
        if (minLength && value.length < parseInt(minLength)) {
          error = `El valor debe tener al menos ${minLength} caracteres`;
        } else if (maxLength && value.length > parseInt(maxLength)) {
          error = `El valor debe tener como máximo ${maxLength} caracteres`;
        }
      }
    }
  
    if (!error && validate && typeof validate === 'function') {
      error = validate(value);
    }
  
    return error;
  };  

  if (!formatter) {
    formatter = (value) => value;
  }

  return (
    <div className={`pb-3 ${className}`}>
      <Field name={name} validate={(args) => validateField(args)}>
        {({ input, meta }) => (
          <>
            {showLabel ? (
              <label position="floating" className="pt-1">
                <span
                  className={`font-semibold mr-3 ${
                    meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'
                  }`}
                >
                  {title}
                  {(meta.touched && meta.error) || (_.includes(fieldsRequired, name) && !input.value)
                    ? '*'
                    : ''}
                </span>
              </label>
            ) : ''}
            <div className="mt-1">
              <textarea
                className="border-gray-200 border px-2 py-1 rounded-md w-full h-[150px]"
                type={type}
                placeholder={placeholder || title}
                value={formatter(input.value)}
                onChange={(e) => {input.onChange(e); onChange && onChange(e)}}
                onBlur={(e) => {input.onBlur(e); onBlur && onBlur(e)}}
                disabled={disabled}
                min={type === 'number' && minValue ? parseFloat(minValue) : undefined}
                max={type === 'number' && maxValue ? parseFloat(maxValue) : undefined}
                minLength={type === 'text' && minLength ? parseInt(minLength) : undefined}
                maxLength={type === 'text' && maxLength ? parseInt(maxLength) : undefined}
              />
              {UnderInput && (<UnderInput input={input} />)}
              {/* error */}
              <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
            </div>
          </>
        )}
      </Field>
    </div>
  );
};

export default FormFieldTextArea;