import { Link, useParams, useNavigate } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import EntityDocView from "../components/EntityDocView";
import { getImageURL } from "../libs/utils";
import ComponentOfModule from '../components/ComponentOfModule';
import { useModule } from "../context/ModuleContext";


export function Content({ entityDoc }) {  
  const navigate = useNavigate();
  const { isAllowed } = useModule();
  const entitySlug = entityDoc?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  
  const validateForm = () => {};
  const handleBeforeSave = () => {};
  const handleDelete = () => {};
  
  const ExtraActions = (props) => (<>
    <ComponentOfModule
      type="crud"
      action="ListHeaderExtraActions"
      entitySlug={entitySlug}
      param={{
        Model, isAllowed, ...props
      }}
    />
  </>);

  const ListItem = ({ doc }) => (<>
    <EntityDocView
      doc={doc}
      entitySlug={entitySlug}
      classes={{
        fieldLabel: "hidden"
      }}
      render={({ ViewData, mainAttr, mainImgAttr }) => (
        <>
          {mainImgAttr && (doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length) ? (
            <div className=" w-[70px] shadow-md hover:shadow-xl">
              <img src={doc?.data && doc.data[mainImgAttr] && doc.data[mainImgAttr].length && getImageURL(doc.data[mainImgAttr][0], 'xs')} 
              className="rounded h-[70px] w-full object-cover" /> {/* image xs */}
            </div>
          ) : null}
          <div className="px-2 w-auto">
            <ViewData field={mainAttr} classes={{ fieldContainer: 'text-2xl font-semibold' }} />
            <ComponentOfModule
              type="crud"
              action="UnderListItemTitle"
              entitySlug={entitySlug}
              param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
            />
          </div>
          <ComponentOfModule
            type="crud"
            action="UnderListItemHeader"
            entitySlug={entitySlug}
            param={{ doc, ViewData, mainAttr, mainImgAttr, isAllowed, entitySlug }} 
          />
        </>
      )}
    />
  </>);

  const ListBtns = ({ doc }) => {
    if ( isAllowed(entitySlug, ['read']) ) {
      return <button
        type="button"
        onClick={() => {
          navigate(`/admin/entity/${entityDoc?.id}/${doc.id}`);
        }}
        className="mr-2 px-2 py-0.5 text-xs bg-blue-200 text-blue-800 rounded-md"
      >
        Ver
      </button>
    }
    return null;
  };
  

  return (
    <div className="">
      <SectionCrudModel
        model={ExtendedModel}
        entitySlug={entitySlug}
        editStyle="route"
        title={false}
        reorder={false}
        navigateTo={(doc) => (`/admin/entity/${entityDoc?.id}/${doc ? doc.id : 'new'}/form`)}

        // callbacks 
        onValidation={validateForm}
        handleBeforeSave={handleBeforeSave}
        onDelete={handleDelete}

        // add UI
        ListItem={ListItem}
        ListBtns={ListBtns}
        ExtraActions={ExtraActions}
      />
    </div>
  );
}

export function AdminEntityCrud() {
  const { entityId } = useParams();
  const entityDoc = useStateSingleResult({
    Model: Model.extend('entities'), 
    id: entityId
  });
  
  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <h2 className="text-xl font-semibold mb-4">{entityDoc?.data?.name}</h2>
      </div>

      {entityDoc?.data && <Content entityDoc={entityDoc} />}
    </LayoutAdmin>
  );
}
