import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { RenderShowForFraction } from "./polygonDataType";
import { getImageURL, openWhatsApp, priceFormat } from "../../libs/utils";
import { FaPhone } from 'react-icons/fa';
import { blocks } from "../../stringsMap";
import ContactBTN from "../../components/ContactBTN";
import SliderGallery from "../../components/SliderGallery";
import ViewMapPoint from "../../components/ui/ViewMapPoint";
import ReactMarkdown from "react-markdown";
import CustomerReviewsBlock from "../../components/Blocks/CustomerReviews";
import CtaContactUsBlock from "../../components/Blocks/CtaContactUs";
import FrecuentAnswersBlock from "../../components/Blocks/FrecuentAnswers";
import CtaImgFullBlock from "../../components/Blocks/CtaImgFull";
import ContactFormBlock from "../../components/Blocks/ContactForm";
import { AddClassWhenVisible } from "../../components/AddClassWhenVisible";
import FilterLotesByPriceRanges from "./FilterLotesByPriceRanges";
import BadgeLoading from "../../components/ui/BadgeLoading";
import CarouselBlock from "../../components/Blocks/CarouselBlock";


const RenderGallery = ({ value }) => {
  if (!value) { return null; }
  const slidersData = value.map(filePath => {
    return {
      key: filePath,
      title: filePath,
      imageUrl: getImageURL(filePath, 'xl'),
      thumbUrl: getImageURL(filePath, 'xs')
    }
  });
  return (
    <SliderGallery
      slidersData={slidersData}
      // showSliderAtInit={showSliderAtInit}
      // gridPlace={gridPlace}
      showGrid={true}
      gridClass="products-grid xs mt-3"
      thumbBtnClass="product-card">
    </SliderGallery>
  );
};

export const RouteShowFraction = ({ parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, navigate }) => {
  const [ fraccionDoc, setFraccionDocs ] = useState();
  const [ lotesDocs, setLotesDocs ] = useState([]);

  useEffect(async () => {
    console.log('useEffect', parsedParams)
    if (parsedParams?.id) {
      const FraccionesModel = Model.extend('fracciones');
      let doc = await FraccionesModel.findById(parsedParams.id);
      if (doc) {
        // add lotes
        const LotesModel = Model.extend('lotes');
        let lotesDocs = await LotesModel.filterByAttributes({
          fraccionId: doc.id
        });
        setLotesDocs(lotesDocs);
        setFraccionDocs(doc);
      }
    }
  }, [parsedParams?.id]);

  if (!fraccionDoc?.data) {
    return (
      <div className="pt-44 flex place-content-center content-center items-center font-brand-main">
        <BadgeLoading className="text-brand-primary" />
      </div>
    );
  }

  return (<>
    <div className="pb-16 pt-28 md:pb-20 md:pt-36 px-4 bg-brand-red text-center text-white">
      <AddClassWhenVisible classToAdd="animate-fadeIn">
        <div>
          <h1 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">
            Descubrí
          </h1>
          <h2 className="text-4xl md:text-5xl font-brand-mulish text-white font-bold">
            {fraccionDoc?.data?.name}
          </h2>

          <div className="mt-4">
            {fraccionDoc?.data?.estado === 'disponible' ? (
              <span className="bg-brand-yellow text-white text-xl px-5 py-2 rounded-full font-semibold">Disponible</span>
            ) : (
              <span className="bg-gray-200 text-gray-700 text-xl px-5 py-2 rounded-full font-semibold">Agotado</span>
            )}
          </div>
        </div>
      </AddClassWhenVisible>
    </div>

    <div className="mt-4 md:mt-8 mb-12 px-4 lg:px-16 lg:max-w-7xl lg:mx-auto">

      <div className="flex flex-col md:flex-row gap-2 md:gap-4">
        <div className={`md:w-[60%] ${fraccionDoc.data.images?.length ? '' : 'hidden'}`}>
          {/* mapa */}
          {/* {fraccionDoc?.data.showParts.includes('mapa-de-loteamientos') ? (
            <RenderShowForFraction doc={fraccionDoc} />
          ) : null} */}
          {/* gallery */}
          {fraccionDoc?.data?.images && _.isArray(fraccionDoc.data.images) ? (
            <RenderGallery value={fraccionDoc.data.images} />
          ) : null}
        </div>

        <div className={`space-y-4 ${fraccionDoc.data.images?.length ? 'md:w-[40%]' : 'w-full'}`}>
          {/* Description */}
          {fraccionDoc?.data?.description ? (
            <div className="prose-lg lg:prose-xl">
              <ReactMarkdown>{fraccionDoc.data.description}</ReactMarkdown>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-10">
        {/* gps */}
        {fraccionDoc?.data?.location ? (
          <ViewMapPoint value={fraccionDoc.data.location} classes={{ mapHeight: 'h-[350px]' }} mapZoom={14} />
        ) : null}
      </div>

      {/* botón para ver en la web de google map el fraccionDoc.data.point */}

      {/* lista de lotes agrupados por manzanas con enlaces, estado y precios de cuotas */}
      {fraccionDoc?.data?.manzanasList?.sort((a, b) => a.name - b.name).map((manzana, index) => (
        lotesDocs?.length && (
          <div className="mb-4 flex flex-col gap-2" key={index}>
            <div className="mt-10 font-semibold text-brand-red text-2xl lg:text-3xl text-center ">
              Manzana {manzana.name}
            </div>
            <div className="p-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 bg-brand-red shadow-lg rounded-md text-center">
              {_.sortBy(lotesDocs.filter((loteDoc) => loteDoc.data.manzana === manzana.name && loteDoc.data.estado !== 'oculto'),['data.num'])
                .map((loteDoc, index) => (
                <div className="flex flex-col border-b py-2 md:py-4 px-2 md:px-4 w-auto rounded-md border-4 border-brand-yellow bg-white" key={index}>
                  <div className={`text-xl ${loteDoc.data.estado === 'disponible' ? 'font-bold text-brand-red' : 'font-semibold text-gray-500'}`}>Lote {loteDoc.data.num}</div>
                  <div className={`text-base capitalize ${loteDoc.data.estado === 'disponible' ? 'font-semibold text-brand-yellow' : 'text-gray-500'}`}>{loteDoc.data.estado}</div>
                  <div className="mb-2">Cuota {priceFormat(loteDoc.data.cuota)}</div>
                  <button onClick={() => openWhatsApp({
                    number: config.number,
                    message: `Hola ${config.brand.name}! me interesa el lote ${loteDoc.data.num} del fraccionamiento ${fraccionDoc.data.name}`
                  })} className={`text-sm xsm:text-base rounded-sm py-1 px-1 text-center ${loteDoc.data.estado === 'disponible' ? 'bg-brand-red hover:bg-orange-700 text-white' 
                    : 'bg-gray-400 hover:bg-gray-600 text-white'}
                  `}>
                    Consultar
                  </button>
                </div>
                )
              )}
            </div>
          </div>
        )
      ))}
    </div>

    {/* Asesor */}
    <div className="mt-10 py-24 px-4 bg-brand-yellow text-center text-white flex flex-col gap-8 lg:gap-14 lg:flex-row place-content-center">
      <AddClassWhenVisible classToAdd="animate-fadeIn">
        <div className="">
          <h1 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">
            Conversemos
          </h1>
          <h2 className="text-4xl md:text-5xl font-brand-mulish text-white font-bold">
            Consultá con <br /> nuestros asesores
          </h2>
        </div>
      </AddClassWhenVisible>
      <div className="">
        <div className="flex flex-col items-center">
          <a target='_blank' href={`tel:+${config.number} `} className="flex  text-lg bg-brand-red mx-auto px-2 py-1 text-white shadow-md rounded-full items-center mb-2" rel="noreferrer"><FaPhone className="mr-1.5"/>Llamar</a>
          <ContactBTN number={config.number} message={`Hola, estoy interesado en el lote de este enlace: ${window.location.href} `} label="Consultar" />
        </div>
        <h3 className="mt-2 text-lg">Tel: 0984577585</h3>
      </div>
    </div>

    <FilterLotesByPriceRanges data={blocks.filterLotesByPriceRanges} />

    <CarouselBlock data={blocks.ultimosLotes} />

    <CustomerReviewsBlock data={blocks.customerReviews} />

    <ContactFormBlock data={blocks.contactForm} messageFooter={`Enlace de referencia: ${window.location.href}`} />

    <CtaContactUsBlock data={blocks.ctaContactUs} />

    <FrecuentAnswersBlock data={blocks.frecuentAnswers} />

    {/* fixed footer */}
    <footer className=" fixed bottom-2 right-2 w-48 z-50">
      <div className="">
        <ContactBTN className={'ml-2 mb-1'} />
      </div>
    </footer>

    <CtaImgFullBlock data={blocks.ctaFooter} />
  </>);
};