import {
  FormFieldSelect,
  FormFieldMultiText,
  FormFieldSelectButtons,
} from '../Form';

import {
  // Select
  FilterRenderSelect,
  FilterCreatorSelect,
} from '../Filters';

export default {
  Render(props) {
    const {
      fieldName,
      taxonomyType,
      overrideParams = {},
      values,
      onChange,
      fieldsRequired
    } = props;
    const taxonomyTypeData = taxonomyType?.data || taxonomyType;
    const title = taxonomyTypeData?.name;
    const design = taxonomyTypeData?.style?.design;
    const _fieldName = fieldName || taxonomyTypeData?.nameSlug;
    const param = { ...taxonomyTypeData?.param, ...overrideParams };
    const style = param?.style;

    if(design === "select") {
      return(
        <FormFieldSelect 
          name={_fieldName}
          title={title}
          placeholder={title}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }

    else if (design === "buttons") {
      return (
        <FormFieldSelectButtons 
          name={_fieldName}
          title={title}
          onSelect={onChange}
          fieldsRequired={fieldsRequired}
          {...param}
          {...style}
          {...props}
        />
      )
    }
    
    return null;
  },

  RenderFilter(props) {
    return <FilterRenderSelect {...props} DataTypeRender={this.Render} />
  },

  RenderFilterParam(props) {
    return <FilterCreatorSelect {...props} />
  },

  RenderInputParams({ values, param }) {
    return <FormFieldMultiText name="param.options" title="Opciones" placeholder="Opciones" />
  },
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (<>
    <FormFieldSelect
      name="show.select.operator"
      title="Operador"
      placeholder="Operador"
      options={[
        { value: 'eq', label: 'es igual' },
      ]}
    />
    <FormFieldSelect
      name="show.select.values"
      title="Opciones"
      placeholder="Opciones"
      options={taxonomyToWatch?.data?.param?.options}
    />
  </>),
  
  RenderStyleParams: ({ values }) => (
    <FormFieldSelect
      name="style.design"
      title="Diseño"
      placeholder="Diseño"
      options={[
        { value: 'select', label: 'Select' },
        { value: 'buttons', label: 'Buttons' },
      ]}
    />
  ),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => {
    if (values?.style?.design === 'select') {
      return (<FormFieldSelect {...formFieldProps} />);
    }
    if (values?.style?.design === 'buttons') {
      return (<FormFieldSelectButtons {...formFieldProps} />);
    }
    return '';
  },

  getDefaultTaxonomyTypeDesign: () => ('select')
}