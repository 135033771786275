import _ from 'lodash';
import departmentAndCities from './departmentAndCities';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


export const CityFilter = ({ fraccionesDocs, onSelect, classes }) => {
  const [fraccionesByCity, setFraccionesByCity] = useState([]);
  const [expandedDepartments, setExpandedDepartments] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);

  useEffect(() => {
    if (fraccionesDocs?.length) {
      let departments = _.groupBy(fraccionesDocs, doc => doc.data.city?.value);
      let cities = _.groupBy(fraccionesDocs, doc => doc.data.city?.value.slice(0, 2));
      setFraccionesByCity({ ...departments, ...cities });
    }
  }, [fraccionesDocs]);

  const Counter = ({ id }) => {
    if (fraccionesByCity[id]?.length) {
      if (fraccionesByCity[id].length === 1) {
        return <span className='ml-2'>( {fraccionesByCity[id].length} fracción )</span>;
      }
      return <span className='ml-2'>( {fraccionesByCity[id].length} fracciones )</span>;
    } else {
      return null;
    }
  };

  const toggleDepartment = (department) => {
    setExpandedDepartments((prev) =>
      prev.includes(department)
        ? prev.filter((d) => d !== department)
        : [...prev, department]
    );
  };

  const selectCity = (city) => {
    let res = selectedCity === city ? null : city;
    setSelectedCity(res);
    onSelect(res);
  };

  return (
    <div className={classes?.container}>
      {departmentAndCities.map((department, index) => {
        const counter = Counter({ id: department.options[0].value.slice(0, 2) });
        const isExpanded = expandedDepartments.includes(department);

        return (
          <div key={index} className="">
            <button
              onClick={() => toggleDepartment(department)}
              className={`
                ${isExpanded ? 'font-semibold' : ''} ${counter ? 'font-semibold text-gray-700' : 'text-gray-500'} 
                text-sm py-1 px-6 hover:bg-gray-100 w-full text-left
              `}
            >
              {department.label} {counter}
            </button>
            <div className={`transition-all duration-500 ease-in-out overflow-hidden ${isExpanded ? 'max-h-screen' : 'max-h-0'}`}>
              <div className="mb-4 text-xs">
                {department.options.map((city, index) => {
                  const counter = Counter({ id: city.value });
                  const isSelected = selectedCity === city;

                  return (
                    <div key={index} className="">
                      <button
                        onClick={() => selectCity(city)}
                        className={`
                          py-1 pl-10 pr-4 hover:bg-gray-100 w-full text-left
                          ${isSelected ? 'underline py-2' : ''}
                          ${counter ? 'font-semibold text-gray-700' : 'text-gray-500'}
                        `}
                      >
                        {city.label} {counter} {(isSelected && !counter) ? '( sin fraccionamientos )' : null}
                      </button>
                      <div className={`transition-all duration-500 ease-in-out overflow-hidden ${isSelected ? 'max-h-screen' : 'max-h-0'}`}>
                        {isSelected && fraccionesByCity[selectedCity?.value]?.length ? (
                          <div className="text-base pl-12 text-gray-700">
                            {fraccionesByCity[selectedCity.value].map((fractions) => (
                              <Link
                                key={fractions.id}
                                to={`/fracciones/show/id/${fractions.id}`}
                                className="hover:underline hover:text-black cursor-pointer"
                              >
                                {fractions.data.name}
                              </Link>
                            ))}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
