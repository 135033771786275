import _ from 'lodash';
import { useEffect, useState } from "react";
import Model from "../../libs/ModelClass";
import { Link } from 'react-router-dom';



export const FractionsList = ({ fractionsDocs }) => {
  const [ fractionsDocsToUse, setFractionsDocs ] = useState([]);
  const [ lotsByFraction, setLotsByFraction ] = useState({});

  useEffect(() => {
    if (fractionsDocs?.length) {
      fetchLotsByFraction();
    }
  }, [fractionsDocs]);

  useEffect(() => {
    setFractionsDocs(_.map(fractionsDocs, (fractionDoc) => {
      return {
        id: fractionDoc.id,
        data: {
          ...fractionDoc.data,
          name: _.includes(fractionDoc.data.name, 'FRACCIÓN') ? fractionDoc.data.name : `FRACCIÓN ${fractionDoc.data.name}`,
        },
      };
    }));
  }, [fractionsDocs]);

  const fetchLotsByFraction = async () => {
    let lotsDocs = await Model.extend('lotes').getAll();
    lotsDocs = _.filter(lotsDocs, (lot) => !lot.data.deleted);
    const lotsByFractionId = _.groupBy(lotsDocs, 'data.fraccionId');
    let lotsByFraction = {};
    _.forEach(lotsByFractionId, (lotsDocs, fraccionId) => {
      const available = _.groupBy(lotsDocs, 'data.estado');
      lotsByFraction[fraccionId] = {
        fraccionId,
        lotsDocs,
        available,
        status: available.disponible?.length ? 'Disponible' : 'Agotado',
      };
    });
    setLotsByFraction(lotsByFraction);
  };

  return (
    <div className="py-24 px-4 bg-brand-yellow">
      <h1 className="text-lg text-white lg:text-xl text-center uppercase font-bold font-brand-mulish md:mb-2">
        Lista de Fracciones
      </h1>
      <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-4 container-width">
        {_.sortBy(fractionsDocsToUse, 'data.name')?.map((doc, index) => {
          return (
            <div key={index} className="bg-white p-4 rounded-md hover:scale-[1.01] hover:shadow-md transition-all duration-200">
              <Link to={`/fracciones/show/id/${doc.id}`}>
                <h3 className="text-lg font-semibold underline">{doc.data.name}</h3>
              </Link>
              <div className="">
                {lotsByFraction[doc.id]?.status === 'Disponible' ? (
                  <div className="space-x-2">
                    <span className="bg-brand-red text-white text-xs px-3 py-1 rounded-full font-semibold">Disponible</span>
                    <span className="text-sm font-semibold">
                      {lotsByFraction[doc.id]?.available?.disponible?.length} de {lotsByFraction[doc.id]?.lotsDocs?.length}
                    </span>
                  </div>
                ) : (
                  <span className="bg-gray-300 text-gray-700 text-xs px-3 py-1 rounded-full font-semibold">Agotado</span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};