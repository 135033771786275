import { useEffect, useState } from "react";
import { numberFormat } from "../../libs/utils";
import Model from "../../libs/ModelClass";
import { AddClassWhenVisible } from "../../components/AddClassWhenVisible";
import { Link } from "react-router-dom";

export default function FilterLotesByPriceRanges ({ data }) {
  const [ items, setItems ] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const { priceSteps } = data;

  const fetchItems = async () => {
    if (!items && priceSteps) {
      // add lotes
      const LotesModel = Model.extend('lotes');
      let lotesDocs = await LotesModel.filterByAttributes({ estado: 'disponible' });
      lotesDocs = lotesDocs.filter(doc => !doc.data?.deleted);
      // set steps
      const itemsData = priceSteps.map((step, index) => {
        let nextStep = priceSteps[index + 1];
        let lotes = lotesDocs.filter(doc => {
          if (nextStep) {
            return doc.data.cuota >= step && doc.data.cuota < nextStep;
          }
          return doc.data.cuota >= step;
        });
        return { 
          price: step,
          lotesDocs: lotes
        };
      });
      setItems(itemsData);
    }
  }

  useEffect(() => fetchItems(), [priceSteps]);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="">        
      <div className="lg:flex text-center bg-orange-700">
        <div className="flex lg:self-start lg:flex-col py-6 px-6 lg:py-16 text-center font-brand-mulish text-white relative justify-center">
          <div className="flex pt-3 lg:ml-0 lg:hidden absolute left-4 top-4">
            <img src="/asset-marca-blanco.svg" alt="marca-2" className="opacity-20 w-32 h-32" />
          </div>
          <AddClassWhenVisible classToAdd="animate-fadeIn">
            <div className="pb-5 pt-5 md:pt-10 px-4">
              <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
              <h3 className="text-4xl md:text-4xl font-brand-mulish text-white font-bold">{data.subtitle}</h3>
            </div>
          </AddClassWhenVisible>
          <div className="w-56 h-56 hidden lg:block m-6">
            <img src="/asset-marca-blanco.svg" alt="marca-2" className="opacity-40 " />
          </div>
        </div>

        {/* Lista de Precios de cuotas */}
        <div className="flex flex-col py-8 lg:py-16 lg:px-0 lg:w-72 bg-orange-800 text-white font-brand-mulish">
          <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish mb-4 lg:text-left ml-2 lg:mr-4 lg:ml-4">{data.priceStepsTitle}</h3>
          <div className="grid grid-cols-2 lg:grid-cols-1 gap-2 ml-2 lg:mr-4 lg:ml-4 lg:px-0 lg:space-y-3 font-brand-mulish">
            {items?.map((item, index) => (
              <div
                key={index}
                className={`cursor-pointer px-2 py-0.5 rounded ${index === activeIndex ? 'bg-orange-600' : ''} ${item.lotesDocs?.length ? '' : 'opacity-40'}`}
                onClick={() => handleAccordionClick(index)}
              >
                <p className="text-start font-semibold lg:text-lg cursor-pointer font-brand-mulish">
                  <span className="mr-1">Gs. {numberFormat(item.price)}</span>
                  {item.lotesDocs?.length ? (
                    <span className="text-gray-100 tracking-widest">({item.lotesDocs?.length})</span>
                  ) : null}
                </p>
              </div>
            ))}
          </div>
          <h3 className="text-sm font-brand-mulish mt-6"> <a href={data.ctaUrl}>{data.ctaTitle}</a></h3>
        </div>

        <div className="flex lg:basis-2/3 lg:py-10 justify-center bg-brand-red">
          {items?.map((item, index) => (
            <div key={index} className="mt-8 mb-8 lg:mt-6 lg:mb-6 ">
              {index === activeIndex && (
                <div className="ml-6 mr-4 flex items-center">
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 lg:text-lg font-brand-mulish">
                    
                    {/* lotes */}
                    {(item.lotesDocs?.length && 
                      item.lotesDocs.sort((a, b) => a.data.couta - b.data.couta)
                      .map((loteDoc, index) => (
                        <div className="flex flex-col bg-white py-2 px-2 w-full rounded-md border-4 border-brand-yellow" key={index}>
                          <div className="text-sm md:text-base">
                            {/* <a href={`/lotes/show/id/${loteDoc.id}`} className="text-blue-600 underline" >
                              Lote {loteDoc.data.num}
                            </a> */}
                            <span className="text-brand-red text-base font-semibold" >
                              Lote {loteDoc.data.num}
                            </span>
                          </div>
                          <div className={`text-gray-800 text-sm md:text-base font-semibold`}>
                            Gs. {numberFormat(loteDoc.data.cuota)}
                          </div>
                          <div className="text-sm md:text-base">
                            <Link to={`/fracciones/show/id/${loteDoc.data.fraccionId}`} className="text-blue-600 underline" >{loteDoc.data.fraccion}</Link>
                          </div>
                        </div>
                      )
                    )) || null}

                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

    </div>
  );
}