import { Link } from 'react-router-dom';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudModel from "../components/SectionCrudModel";
import Entity, { getEntitiesBlueprint } from '../modules/entity/Entity';
import { downloadJSON } from '../libs/utils';


const exportEntities = async () => {
  try {
    let entitiesBlueprints = await getEntitiesBlueprint();
    downloadJSON(entitiesBlueprints, 'entitiesBlueprints.json');
  } catch (error) {
    console.log('Error exporting entities:', error);
  }
};

export function Content() {
  const ListItem = ({ doc }) => (<>
    <span className="">{doc?.data?.name}</span>
  </>);

  const ExtraActions = () => (<>
    <button onClick={exportEntities} className="text-left bg-gray-200 text-gray-900 rounded px-3 p-1 text-sm">
      Exportar JSON
    </button>
  </>);

  return (
    <div className="">
      <SectionCrudModel
        model={Entity}
        entitySlug="entities"
        editStyle="route"
        reorder={true}
        navigateTo={(doc) => (`/admin/entity/creator/${doc ? doc.id : 'new'}/form`)}
        // add UI
        ExtraActions={ExtraActions}
        ListItem={ListItem}
      />
    </div>
  );
}

export function AdminEntityCreator() {
  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Entidades</h2>
      </div>

      <Content />
    </LayoutAdmin>
  );
}
