import { useEffect, useState } from "react";
import _ from 'lodash';
import { Estados, Medidas, RenderShowForLote } from "./polygonDataType";
import { parseCoordinates } from "../../libs/utils";
import { priceFormat } from "../../libs/utils";
import { FaPhone } from 'react-icons/fa';
import ContactBTN from "../../components/ContactBTN";
import { Link } from "react-scroll";


export const RouteShowLote = ({ parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, navigate }) => {
  const [ loteDoc, setLoteDocs ] = useState();
  const polygonPaths = loteDoc?.data?.polygon && parseCoordinates(loteDoc?.data?.polygon);

  useEffect(async () => {
    if (!loteDoc && parsedParams?.id) {
      const LotesModel = Model.extend('lotes');
      let doc = await LotesModel.findById(parsedParams.id);
      if (doc) {
        setLoteDocs(doc);
      }
    }
  }, [parsedParams]);
  
  console.log('loteDoc', loteDoc);

  return (
    <div className="sm:px-4 md:px-8 lg:px-12 xl:px-24 mt-24 sm:mt-32 mb-20 text-center">
      
      <div className="flex flex-col p-3 border-b shadow-sm mb-6 justify-center text-2xl md:text-3xl lg:text-4xl font-semibold">
        <div className="flex justify-center mb-3">
          <h1 className="mr-1">
            Lote {loteDoc?.data?.num} -
          </h1>
          <a href={`/fracciones/show/id/${loteDoc?.data?.fraccionId}`}>
            <h2 className="underline">
              {loteDoc?.data?.fraccion}
            </h2>
          </a>
        </div>

        <Estados doc={loteDoc} />
      </div>

      <div className="md:flex">
        <div className="w-full h-full md:basis-3/5">
          {/* Mapa */}
          <RenderShowForLote doc={loteDoc} />
          {/* botón para ver en la web de google map el loteDoc.data.point */}
        </div>

        <div className="sm:w-2/5 flex flex-col justify-center px-4 border border-gray-500 rounded mx-4 md:mx-6 mt-12 py-6 md:mt-0">
          {/* Precio de Cuota */}
          <h3 className='text-2xl font-semibold'>Desde : {priceFormat(loteDoc?.data?.cuota)}</h3>
          {/* medidas */}
          <h3 className='text-xl mt-2 font-semibold'>Medidas</h3>
          <Medidas polygonPaths={polygonPaths} />

          {/* Asesor */}
          <div className="mt-10 items-center">
            <h3 className="text-xl mt-2 font-semibold">Consultá con <br /> nuestros asesores al:</h3>
            <h3 className="mb-2 text-xl">Tel: 0984577585</h3>
            <div className="flex flex-col items-center">
              <h3 className="hidden md:block">ó por whatsapp:</h3>
              <a target='_blank' href={`tel:+${config.number} `} className="flex md:hidden text-lg bg-brand-yellow mx-auto px-2 py-1 text-white shadow-md rounded-full items-center mb-2" rel="noreferrer"><FaPhone className="mr-1.5"/>Llamar</a>
              <ContactBTN number={config.number} message={`Hola, estoy interesado en el lote de este enlace: ${window.location.href} `} label="Consultar" />
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};