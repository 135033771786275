import React, { useEffect, useState, useRef } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../../config';

const RawInputGPS = ({ value, onChange }) => {
  const [map, setMap] = useState(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ['places'],
  });

  useEffect(() => {
    if (isLoaded && map) {
      const input = document.createElement('input');
      input.type = 'text';
      input.placeholder = 'Buscar lugar';
      input.style.marginTop = '10px';
      input.style.marginLeft = '10px';
      input.style.padding = '8px 20px';
      input.style.fontSize = '15px';
      input.style.width = '80%';
      input.style.borderRadius = '0.25rem';
      input.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(input);

      const searchBox = new window.google.maps.places.SearchBox(input);
            
      const handleSearchResult = () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
          const position = {
            lat: places[0].geometry.location.lat(),
            lng: places[0].geometry.location.lng(),
          };
          onChange(position);
        }
      };

      searchBox.addListener('places_changed', handleSearchResult);
    }
  }, [isLoaded, map]);

  const initializeMap = (map) => {
    setMap(map);
  };

  const handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          onChange(currentPosition);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error('La geolocalización no es compatible en este navegador.');
    }
  };

  return (
    <div className="relative">
      {isLoaded && (
        <GoogleMap
          center={value}
          mapContainerStyle={{ height: '400px', width: '100%' }}
          options={{
            zoom: 11,
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false
          }}
          onLoad={initializeMap}
        >
          <Marker position={value} draggable onDragEnd={(event) => onChange(event.latLng.toJSON())} />
        </GoogleMap>
      )}

      <button
        type="button"
        onClick={handleCurrentLocation}
        className="absolute bottom-6 left-2 bg-white rounded border border-gray-300 shadow-md px-4 py-2 flex flex-row gap-2 items-center"
      >
        <FaMapMarkerAlt />
        Ubicación
      </button>
    </div>
  );
};

export default RawInputGPS;
