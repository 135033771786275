import { AddClassWhenVisible } from "../AddClassWhenVisible";

export default function TextGridWithTitleBlock({ data }) {
  return (
    <div className="px-4 py-24 md:py-32 bg-white text-center">
      <div className="container-width">
        
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="pb-5 px-4">
            <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
            <h3 className="text-4xl md:text-4xl font-brand-mulish text-brand-red font-bold">{data.subtitle}</h3>
          </div>
        </AddClassWhenVisible>
        
        <div className="mt-10 flex flex-wrap md:grid md:grid-cols-3 gap-12 lg:gap-12 px-4">
          {data.items.map((item, index) => (
            <div key={index} className="font-brand-mulish">
              <h4 className="font-bold text-xl mb-2">{item.title}</h4>
              <p>{item.content}</p>
            </div>
          ))}
        </div>

      </div>
    </div>
  );
}
