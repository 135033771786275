import { FaMapMarkerAlt } from "react-icons/fa";
import { AddClassWhenVisible } from "../AddClassWhenVisible";
import { Link } from "react-router-dom";


export default function CtaMapaDeFraccionamientos({ data }) {
  return (
    <div className="px-4 py-24 md:py-32 bg-brand-yellow text-center text-white">
      <div className="container-width">
        
        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="pb-5 px-4">
            <h3 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">{data.title}</h3>
            <h3 className="text-4xl md:text-4xl font-brand-mulish text-white font-bold">{data.subtitle}</h3>
          </div>
        </AddClassWhenVisible>

        <AddClassWhenVisible classToAdd="animate-fadeIn">
          <div className="flex justify-center">
            <Link to={data.ctaUrl} className={`
              px-4 md:px-6 lg:px-8 lg:mx-auto py-3 md:py-3 lg:py-4 flex items-center bg-white hover:bg-amber-100
              "rounded-sm text-black "}
            `}>
              <h3 className="text-xl">
                <FaMapMarkerAlt className="inline mr-2 text-brand-red" size={25}></FaMapMarkerAlt>
                {data.ctaTitle}
              </h3>
            </Link>
          </div>
        </AddClassWhenVisible>

      </div>
    </div>
  );
}
