import { useState, useEffect } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';
import { FaRegTimesCircle, FaSpinner, FaCloudUploadAlt, FaStar } from 'react-icons/fa'
import { uploadFile, deleteImageFile } from '../../firebase';
import { resizeFile, getImageURL } from '../../libs/utils';
import config from '../../config';

/**
 * RawInputGallery Component
 * @param {Array} value - An array of image file names (e.g., ['imag1-12312312.jpg', 'imag2-4234243.jpg']) representing the attached files hashes.
 * @param {Function} onChange - A function to handle the change event and update the value by adding the fileHash.
 * @returns {React.Component} - The RawInputGallery component.
 */
const RawInputGallery = ({ value = [], onChange }) => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [uploading, setUploading] = useState(0);
  
  useEffect(() => {
    if (!_.isArray(value)) {
      value = [];
      setAttachedFiles([]);
    }
    setAttachedFiles(value);
  }, [value]);

  const attachFile = async (files) => {
    const totalSteps = files.length * config.imageDimensions.length; // Cantidad total de redimensiones por cada archivo
    setUploading(0);
    try {
      const newFiles = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const hash = v4();
        const format = file.type.split('/')[1];
  
        for (let j = 0; j < config.imageDimensions.length; j++) {
          const { width, height, suffix } = config.imageDimensions[j];
          const fileData = await resizeFile({ file, width, height, format });
          await uploadFile(fileData, `${config.imgFolder}/${hash}${suffix}.${format}`);
          setUploading(((i * config.imageDimensions.length) + j + 1) * (100 / totalSteps)); // Calcula el porcentaje de avance
        }
  
        newFiles.push(`${hash}.${format}`);
      }
  
      const updatedFiles = [...attachedFiles, ...newFiles];
      setAttachedFiles(updatedFiles);
      onChange(updatedFiles);
    } catch (error) {
      console.log(error);
    }
    setUploading(0);
  };

  const unattachFile = async (fileHashToUnattach) => {
    try {
      await deleteImageFile(`${config.imgFolder}/${fileHashToUnattach}`);
      const newAttachedFiles = attachedFiles.filter((file) => file !== fileHashToUnattach);
      setAttachedFiles(newAttachedFiles);
      onChange(newAttachedFiles);
    } catch (error) {
      console.log(error);
    }
  };

  const markAsMain = (fileHash) => {
    const newAttachedFiles = attachedFiles.filter((file) => file !== fileHash);
    newAttachedFiles.unshift(fileHash);
    setAttachedFiles(newAttachedFiles);
    onChange(newAttachedFiles);
  };

  return (
    <div className="grid grid-flow-row grid-cols-3 gap-4">
      {/* file list */}
      {_.size(attachedFiles) ? attachedFiles.map((fileHash, index) => (
        <div key={fileHash} className="w-24 h-24 mr-2.5 rounded-lg hover:shadow-md relative">
          <img src={getImageURL(fileHash, 'xs')} key={fileHash} className={
            `w-full h-full object-cover rounded-lg ${index === 0 ? 'border-4 border-yellow-400' : 'border border-gray-500'}`
          }/>

          <button type="button" onClick={() => markAsMain(fileHash)} className="absolute left-1 bottom-1 bg-yellow-200 p-0.5 rounded-full">
            <FaStar className='fill-yellow-800' />
          </button>
          <button type="button" onClick={() => unattachFile(fileHash)} className="absolute right-1 bottom-1 bg-red-200 p-0.5 rounded-full">
            <FaRegTimesCircle className='fill-red-900' />
          </button>
        </div>
      )) : null}
      
      {/* new file */}
      <label htmlFor="dropzone-file" 
        className="flex flex-col justify-center items-center w-24 h-24
        rounded-lg border-2 cursor-pointer text-gray-600
        bg-gray-400 hover:bg-gray-500 border-gray-500">
        <div className="flex flex-col justify-center items-center">
          {!uploading ? (<>
            <FaCloudUploadAlt className='w-8 h-8' />
          </>) : (<>
            <FaSpinner className='w-8 h-8' />
            <span className="pt-1.5 text-sm font-semibold text-gray-600">{Math.round(uploading)}</span>
          </>)}
            {/* <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
            <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 2800x2800px)</p> */}
        </div>
        <input onChange={(e) => attachFile(e.target.files)} id="dropzone-file" type="file" className="hidden" multiple />
      </label>
    </div>
  );
}

export default RawInputGallery;