import { Carousel } from 'react-responsive-carousel';
import useBreakpoints from '../../libs/useBreakpoints';
import CtaGrid from '../ui/CtaGrid';


export default function CarouselBlock({ data, history }) {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      className="bg-black"
    >
      {data?.items?.map((item, index) => (
        <div key={index} className=''>
          {item.imageSrc ? (
            <div className="" onClick={() => item.link && history.push(item.link)}>
              <img 
                src={item.imageSrc} 
                alt={item.title}
                className="h-64 sm:h-72 md:h-80 lg:h-auto object-cover object-left"
              />
              <div className='pt-8 pb-16 lg:pt-12 lg:pb-20'>
                <CtaGrid
                  items={item.ctaButtons}
                  classes={{ 
                    ctaGridContainer: '!gap-1 !mt-0',
                    ctaGridButton: '!px-3 !py-1.5 rounded-sm font-semibold'
                  }}
                />
              </div>
            </div>
           ) : null}
        </div>
      ))}
    </Carousel>
  );
}
