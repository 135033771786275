import { createContext, useContext } from "react";
import { collection, query, where, orderBy, doc, getDoc, getDocs, addDoc, updateDoc, deleteDoc, deleteField, onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { nanoid } from "nanoid";
import { db, useAnalytics } from "../firebase";
import useFilters from "../libs/useFilters";
import useCart from "../libs/useCart";
import config from "../config";

const dataContext = createContext();

export const useData = () => {
  const context = useContext(dataContext);
  if (!context) throw new Error("There is no Data provider");
  return context;
};

export function DataProvider({ children }) {
  /*
  Configs
 */
  const ConfigModel = Model('Config');

  ConfigModel.subscribeConfigAll = (cb) => {
    return onSnapshot(
      query(
        collection(db, 'configs')
      ),
      (snap) => cb(snapToList(snap))
    );
  }
  
  /*
  Product
    id: string
    name: string
    description: string
    price: number
    tags: string
    parent: string
    images: [string]
 */
  const ProductModel = Model('Product');

  ProductModel.subscribeProductsAllByParentId = (parentId, cb) => {
    return onSnapshot(
      query(
        collection(db, config.env + '-products'),
        where('parentId', '==', parentId),
        // orderBy('parentId', 'asc'),
        orderBy('name', 'desc')
      ),
      (snap) => cb(snapToList(snap))
    );
  }

  ProductModel.subscribeProductsAll = (cb) => {
    return onSnapshot(
      query(
        collection(db, config.env + '-products'),
        orderBy('name', 'desc')
      ),
      (snap) => cb(snapToList(snap))
    );
  }

  // ProductModel.subscribeOneById = (id, cb) => {
  //   const docRef = doc(db, 'products', id);
  //   return onSnapshot(docRef, (docSnap) => {
  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       cb(data);
  //     } else {
  //       // El documento no existe
  //       cb(null);
  //     }
  //   });
  // };  

  /*
  Cart
    id: string
 */
  const CartModel = Model('Cart', {
    onSet: (fields) => {
      fields.shortId = nanoid(9);
    }
  });

  CartModel.subscribeCartsAll = (cb) => {
    return onSnapshot(
      query(
        collection(db, config.env + '-carts'),
        orderBy('created', 'desc')),
      (snap) => cb(snapToList(snap))
    );
  }

  CartModel.getCartByShortId = async (shortId, cb) => {
    const snap = await getDocs(
      query(
        collection(db, config.env + '-carts'),
        where('shortId', '==', shortId)
      ));
    
    const { list } = snapToList(snap);
    return list[0];
  }

  /*
  Filters
  */
  let filtersLibs = useFilters({ 
    subscribeProductsAll: ProductModel.subscribeProductsAll
  });

  /*
  Carts 
  */
  let cartLibs = useCart({ ...filtersLibs });

  /*
  Analytics
  */
  let analyticsLibs = useAnalytics({ ...cartLibs, ...filtersLibs });


  return (
    <dataContext.Provider
      value={{
        // Models
        ...ConfigModel,
        ...ProductModel,
        ...CartModel,
        // filters
        ...filtersLibs,
        // cart
        ...cartLibs,
        // analytics
        ...analyticsLibs
      }}
    >
      {children}
    </dataContext.Provider>
  );
}

/* 
  Model
    getModelsSanp
    getModel
    saveModel
    updateModel
    deleteModel
*/
function Model(modelName, params={}) {
  const modelNameLowerPlural = config.env + '-' + _.toLower(modelName) + 's';

  const ModelDef = {
    [`get${modelName}sSnap`]:
      () => getDocs(collection(db, modelNameLowerPlural)),

    [`get${modelName}`]:
      (id) => getDoc(doc(db, modelNameLowerPlural, id)),

    [`save${modelName}`]:
      (fields) => {
        fields.created = new Date();
        fields.modified = new Date();
        params.onSet && params.onSet(fields);
        return addDoc(collection(db, modelNameLowerPlural), fields)
      },

    [`update${modelName}`]:
      async (id, newFields) => {
        newFields.modified = new Date();
        // mark field to delete
        let oldFields = await ModelDef[`get${modelName}`](id);
        oldFields = oldFields.data();
        for (const field in newFields) {
          if (Object.hasOwnProperty.call(newFields, field)) {
            if (newFields[field] === null && oldFields[field] !== null) {
              newFields[field] = deleteField();
            }
          }
        }
        return updateDoc(doc(db, modelNameLowerPlural, id), newFields);
      },

    [`delete${modelName}`]:
      (id) => deleteDoc(doc(db, modelNameLowerPlural, id)),

    [`subscribe${modelName}Doc`]: 
      (id, cb) => {
        return onSnapshot(
          doc(db, modelNameLowerPlural, id),
          (snap) => { 
            let newDoc = snap.data();
            newDoc.id = id;
            cb(newDoc);
          }
        );
      }
  };
  
  return ModelDef;
}

const snapToList = (snap) => {
  let list = [];
  snap.forEach(doc => {
    list.push({
      id: doc.id,
      ...doc.data()
    });
  });
  return { list, snap };
}