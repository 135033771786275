import { Link } from 'react-router-dom';


export default function Page404() {
  return (
    <div className="p-10 mt-28">      
      <div className="mb-5 text-2xl text-bold">
        La página no existe
      </div>

      <Link to="/admin" className="bg-zinc-200 hover:bg-zinc-300 rounded py-1.5 px-2 text-black text-xs xsm:text-sm">
        Volver
      </Link>
    </div>
  );
}
