import React, { useEffect, useState } from 'react';
import { GoogleMap, Polygon, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import config from '../../config';
import { calculateTopCenter, parseCoordinates } from '../../libs/utils';
import { generatePointFromPolygonObject } from '../../modules/fraccionesLotes/kmzParser';

const AnyReactComponent = ({ text }) => <div className='text-xl'>{text}</div>;

const ViewMapPolygon = ({ layers, InfoWindowRender, classes }) => {
  const [map, setMap] = useState(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [polygonPaths, setPolygonPaths] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ['geometry'], // Add the 'geometry' library to calculate bounds
  });

  useEffect(() => {
    if (layers) {
      // Parse the layers prop to extract latitude and longitude points for the polygon
      const paths = layers?.map(parseCoordinates).filter(layer => layer);
      setPolygonPaths(paths);
    }
  }, [layers]);

  const initializeMap = (map) => {
    setMap(map);
    setIsMapLoaded(true);
  };

  const onPolygonLoad = (polygon) => {
    // Once the polygon is loaded, fit the map to the polygon's bounds
    if (polygon && map && isMapLoaded) {
      const bounds = new window.google.maps.LatLngBounds();
      polygonPaths.flat().forEach((path) => bounds.extend(path));
      map.fitBounds(bounds);
    }
  };  

  const handlePolygonClick = (polygonIndex) => {
    setSelectedPolygon(polygonIndex);
  };

  return (
    <div className={`relative ${classes?.mapContainer}`}>
      {isLoaded && !loadError && (
        <GoogleMap
          mapContainerClassName={`w-full mb-4 rounded-md ${classes?.mapRender} ${classes?.mapHeight || 'h-48'}`}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false,
          }}
          onLoad={initializeMap}
        >
          {polygonPaths.map((paths, index) => {
            const { lat, lng } = generatePointFromPolygonObject(paths);
            return (
              <React.Fragment key={index}>
                <Polygon
                  paths={paths}
                  options={{
                    strokeColor: layers[index].borderColor || 'red',
                    strokeOpacity: layers[index].strokeOpacity || 0.8,
                    strokeWeight: layers[index].strokeWeight || 2,
                    fillColor: layers[index].bgColor || 'blue',
                    fillOpacity: layers[index].fillOpacity || 0.35,
                  }}
                  onLoad={onPolygonLoad}
                  onClick={() => handlePolygonClick(index)}
                />
                <AnyReactComponent
                  lat={lat}
                  lng={lng}
                  text={layers[index].data.num}
                />
              </React.Fragment>
            );
          })}
          <AnyReactComponent
            lat={-57.07733996404194}
            lng={-25.55256145584122}
            text={"1"}
          />
          {/* Show InfoWindow for the selected polygon */}
          {selectedPolygon !== null && InfoWindow && layers[selectedPolygon].showInfoWindow === true && (
            <InfoWindow
              position={calculateTopCenter(polygonPaths[selectedPolygon])}
              onCloseClick={() => setSelectedPolygon(null)}
            >
              <InfoWindowRender polygonPaths={polygonPaths[selectedPolygon]} layer={layers[selectedPolygon]} index={selectedPolygon} />
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
};

export default ViewMapPolygon;
