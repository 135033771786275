import { useEffect, useState } from "react";
import _ from 'lodash';
import Model from '../../libs/ModelClass';
import ViewMapMultiPoints from "../../components/ui/ViewMapMultiPoints";
import { blocks } from "../../stringsMap";
import FilterLotesByPriceRanges from '../../modules/fraccionesLotes/FilterLotesByPriceRanges';
import ContactBTN from "../../components/ContactBTN";
import { FaMapMarkedAlt, FaPhone } from 'react-icons/fa';
import { CityFilter } from "./CityFilter";
import ActionsDropdown from "../../components/ui/ActionsDropdown";
import CtaContactUsBlock from "../../components/Blocks/CtaContactUs";
import CustomerReviewsBlock from "../../components/Blocks/CustomerReviews";
import FrecuentAnswersBlock from "../../components/Blocks/FrecuentAnswers";
import CtaImgFullBlock from "../../components/Blocks/CtaImgFull";
import ContactFormBlock from "../../components/Blocks/ContactForm";
import { AddClassWhenVisible } from "../../components/AddClassWhenVisible";
import { Link } from "react-router-dom";
import CarouselBlock from "../../components/Blocks/CarouselBlock";
import { FractionsList } from "./FractionsList";


const InfoWindowRender = ({ point }) => {
  const [ lotesDocs, setLotesDocs ] = useState([]);
  const [ lotesDisponibles, setLotesDisponibles ] = useState();
  const LotesModel = Model.extend('lotes');
  
  useEffect(async () => {
    if (point?.doc) {
      // add lotes
      let lotesDocs = await LotesModel.filterByAttributes({
        fraccionId: point?.doc.id
      });
      setLotesDocs(lotesDocs);
      let lotesDisponibles = lotesDocs.filter(lote => lote.data.estado === 'Disponible');
      setLotesDisponibles(lotesDisponibles.length);
    }
  }, [point]);

  return (
    <div className="px-4 pb-6 max-w-[300px]">
      <h2 className='md:text-lg text-base font-semibold border-b border-gray-300 mb-1.5 pb-0.5'>
        {point.doc.data.name}
      </h2>

      {/* nombre de ciudad o región donde está ubicada el loteamiento. Debajo o arriba del nombre de fracción. */}

      {/* <div className="ml-2 inline-block text-sm font-normal">
        {lotesDisponibles ? (
          <span className="px-2 py-0.5 rounded-full bg-brand-yellow text-white">Lotes disponibles</span>
        ) : (
          <span className="px-2 py-0.5 rounded-full bg-gray-500 text-white">Lotes agotados</span>
        )}
      </div> */}

      {/* <div className="sm:flex justify-between mb-4 text-sm">
        <h3>Lotes: {lotesDocs.length}</h3>
        <h3>Disponibles: {lotesDisponibles}</h3>
      </div> */}

      <div className="flex justify-center">
        <Link to={`/fracciones/show/id/${point.doc.id}`} className="px-4 py-1 rounded-sm text-lg bg-brand-red text-white">
          Ver detalles
        </Link>
      </div>
    </div>
  )
};


export const RouteFraccionesMapa = ({ parsedParams, isAllowed, config, Model, module, action, isMinBreakpointActive, location, navigate }) => {
  const [ fraccionesDocs, setFraccionesDocs ] = useState([]);
  const [ points, setPoints ] = useState([]);
  const [ selectedCity, setSelectedCity ] = useState();
  
  useEffect(async () => {
    if (!fraccionesDocs?.length) {
      const FraccionesModel = Model.extend('fracciones');
      let docs = await FraccionesModel.getAll();
      docs = docs.filter(doc => !doc.data.deleted && (doc.data.point || doc.data.location));
      setFraccionesDocs(docs);
      updatePoints(docs);
    }
  }, [parsedParams]);

  const updatePoints = (docs) => {
    let points = docs.map((doc) => {
      const pointArray = doc.data.point?.split(',');
      return {
        point: doc.data.location || { lat: parseFloat(pointArray[1]), lng: parseFloat(pointArray[0]) },
        doc
      };
    });
    setPoints(points);
    return points;
  };
  
  const selectCity = (city) => {
    setSelectedCity(city);
    if (!city) {
      updatePoints(fraccionesDocs);
    } else {
      updatePoints(fraccionesDocs.filter(doc => doc.data?.city?.value === city?.value) || []);
    }
  };

  return (<>
    {/* title */}
    <div className="pb-16 pt-28 md:pb-20 md:pt-36 px-4 bg-brand-yellow text-center text-white">
      <AddClassWhenVisible classToAdd="animate-fadeIn">
        <div>
          <h1 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">
            Mapa de Fracciones
          </h1>
          <h2 className="text-4xl md:text-4xl font-brand-mulish text-white font-bold">
            Explorá nuestras opciones
          </h2>
        </div>
      </AddClassWhenVisible>
    </div>


    {/* Mapa */}
    <div className="mt-4 md:mt-8 mb-24 px-4 lg:px-16 lg:max-w-7xl lg:mx-auto">
      <div className="flex flex-col md:flex-row gap-4 md:gap-6">
        <div className="w-full md:basis-3/4">
          <div className="-mb-4">
            {/* Filters */}
            <div className={`bg-brand-red text-white rounded-md flex flex-row gap-2 px-4 py-2 mb-2 md:px-0 md:py-0 md:mb-0 ${selectedCity ? 'place-content-between !px-4 !py-2 !mb-2' : 'place-content-end'}`}>
              {selectedCity ? (
                <div className="flex flex-row place-content-between gap-2">
                  <div className="">
                    Filtrado por: <span className="font-semibold">{selectedCity.label}</span>
                  </div>
                  <button onClick={() => selectCity(null)} className="bg-white text-brand-red w-6 h-6 rounded-full font-bold">
                    <div className="-mt-0.5">x</div>
                  </button>
                </div>
              ) : null}
              <div className="min-w-[173px] md:hidden">
                <ActionsDropdown label="Filtrar por Ciudad" classes={{ actionsPopover: '!w-[350px] !mt-3 -right-4', button: '!text-brand-red !bg-white hover:!bg-orange-100' }}>
                  <CityFilter
                    fraccionesDocs={fraccionesDocs}
                    onSelect={selectCity}
                    classes={{ container: '!w-[350px] !h-[550px] overflow-y-scroll py-4 text-left lg:text-left border-2 border-brand-red rounded-md' }}
                  />
                </ActionsDropdown>
              </div>
            </div>

            {/* Mapa */}
            <ViewMapMultiPoints
              points={points}
              classes={{ mapContainer: '', mapHeight: 'h-[600px] xl:h-[600px] lg:w-full' }}
              InfoWindowRender={InfoWindowRender}
            />
          </div>
        </div>
        <div className="w-full hidden md:block md:basis-1/4 border-2 border-brand-red rounded">
          <div className="bg-brand-red text-white flex flex-row gap-2 py-2 px-4">
            <FaMapMarkedAlt className="text-xl" />
            <span className="text-xl font-semibold">
              Filtrar por Ciudad
            </span>
          </div>
          <CityFilter
            fraccionesDocs={fraccionesDocs}
            onSelect={selectCity}
            classes={{ container: 'py-4 text-left lg:text-left !h-[550px] overflow-y-scroll' }}
          />
        </div>
      </div>
    </div>

    {/* Fracciones list */}
    <FractionsList fractionsDocs={fraccionesDocs} />

    <CarouselBlock data={blocks.ultimosLotes} />
      
    {/* Asesor */}
    <div className="py-24 px-4 bg-brand-red text-center text-white flex flex-col gap-8 lg:gap-14 lg:flex-row place-content-center">
      <AddClassWhenVisible classToAdd="animate-fadeIn">
        <div className="">
          <h1 className="text-lg lg:text-xl uppercase font-bold font-brand-mulish md:mb-2">
            Conversemos
          </h1>
          <h2 className="text-4xl md:text-5xl font-brand-mulish text-white font-bold">
            Consultá con <br /> nuestros asesores
          </h2>
        </div>
      </AddClassWhenVisible>
      <div className="flex flex-col items-center space-y-2">
        <ContactBTN number={config.number} message={`Hola, estoy interesado en el lote de este enlace: ${window.location.href} `} label="Consultar" />
        <a target='_blank' href={`tel:+${config.number} `} className="flex  text-lg bg-brand-yellow mx-auto px-2 py-1 text-white shadow-md rounded-full items-center" rel="noreferrer"><FaPhone className="mr-1.5"/>Llamar</a>
        <h3 className="text-lg">Tel: 0984577585</h3>
      </div>
    </div>

    <CustomerReviewsBlock data={blocks.customerReviews} />

    <ContactFormBlock data={blocks.contactForm} />

    <CtaContactUsBlock data={blocks.ctaContactUs} />

    <FrecuentAnswersBlock data={blocks.frecuentAnswers} />

    {/* <div className="-mx-4 -mt-8"> 
      <FilterLotesByPriceRanges data={blocks.filterLotesByPriceRanges} />
    </div> */}

    {/* fixed footer */}
    <footer className=" fixed bottom-2 right-2 w-48 z-50">
      <div className="">
        <ContactBTN className={'ml-2 mb-1'} />
      </div>
    </footer>

    <CtaImgFullBlock data={blocks.ctaFooter} />
  </>
  );
};