import { Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import _ from 'lodash';
import Modal from '../../components/Modal';
import { convertCSVToArray, convertXLSXToArray } from './csvParser';


export const extractLotes = async ({ csv }) => {
  if (!(csv instanceof File)) {
    throw new Error('Please specify the csv file');
  }
  
  let csvList;
  const extension = csv.name.split('.').pop().toLowerCase();
  if (extension === 'csv') {
    csvList = await convertCSVToArray(csv);
  } 
  else if (extension === 'xlsx') {
    csvList = await convertXLSXToArray(csv);
  }
  else {
    throw new Error('Formato de archivo no admintido');
  }

  // get lotes
  let lotesData = csvList.map(lote => {
    const newLote = {
      num: lote['Lote'], // mainAttr
      estado: lote['Estado'].toLowerCase(),
      fraccion: lote['Fracción'],
      manzana: lote['Manzana']
    };
    if (newLote.estado === 'recuperado') {
      newLote.estado = 'disponible';
    }
    return newLote;
  });

  return lotesData;
};

const getFraccionesFromLotes = (lotesData) => {
  // group lotesList by fraccionamiento
  let fraccionesData = [];
  const lotesByfracciones = _.groupBy(lotesData, 'fraccion');
  _.forEach(lotesByfracciones, (lotesOfFraccionamiento, fraccionamientoName) => {
    const lotesByManzana = _.groupBy(lotesOfFraccionamiento, 'manzana');
    const manzanasNames = _.keys(lotesByManzana);
    const manzanasList = [];
    const fraccionamientoData = {
      name: fraccionamientoName, // mainAttr
      manzanasList
    };
    if (manzanasNames?.length) {
      manzanasNames.forEach(manzanaName => {
        const newManzana = {
          name: manzanaName // mainAttr
        };
        manzanasList.push(newManzana);
      });
    }
    fraccionesData.push(fraccionamientoData);
  });

  return fraccionesData;
}

export const ModalUpdateLotes = ({ onClose, Model }) => {
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [processedFiles, setProcessedFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const canProcess = selectedFiles?.csv;

  const saveData = async () => {
    if (loading) { return; } // skip
    setLoading(true);
    const { lotesUpdated } = processedFiles;
    for (const loteData of lotesUpdated) {
      let loteDoc = loteData.docFromModel;
      if (loteDoc) {
        loteDoc.data.estado = loteData.estado;
        await loteDoc.save();
      }
    }
    setLoading(false);
    closeModal();
    toast('Se han importado los datos', { icon: 'ℹ️' });
  };

  const handleFileChange = (fileType) => (event) => {
    const file = event.target.files[0];
    setSelectedFiles({ ...selectedFiles, [fileType]: file });
  };

  const processData = async () => {
    if (loading) { return; } // skip
    setLoading(true);
    if (canProcess) {
      const LotesModel = Model.extend('lotes');
      const lotesData = await extractLotes(selectedFiles);
      const lotesDocsAll = await LotesModel.getAll();
      const lotesUpdated = lotesData?.filter(loteData => {
        const loteFromModel = lotesDocsAll.find(loteDoc => (
          loteDoc.data.num === parseInt(loteData.num)
          && loteDoc.data.fraccion === loteData.fraccion
        ));
        if (loteData.estado !== loteFromModel?.data?.estado) {
          loteData.docFromModel = loteFromModel;
          return loteData;
        }
      });
      const fraccionesData = getFraccionesFromLotes(lotesUpdated);
      setProcessedFiles({
        lotesUpdated, lotesData, fraccionesData
      });
    }
    setLoading(false);
  };

  const closeModal = () => {
    onClose && onClose();
    setSelectedFiles(null);
    setProcessedFiles(null);
  };

  return (
    <Modal
    title="Sincronizar estados de lotes" open={true} setOpen={closeModal}
    classes={{ modalCardContainer: 'w-full sm:max-w-md mx-4 max-h-[90vh]', modalContent: 'pb-10' }}>

      <ul className="mb-10 list-disc list-inside">
        <li>No se crearán registros nuevos</li>
        <li>Se ignoran los precios de cuotas</li>
        <li>Sólo se actualizarán los valores de disponibilidad</li>
      </ul>
      
      {!processedFiles?.fraccionesData ? (
        <div className='mb-4'>
          <div className={`p-2 bg-gray-200 rounded border-[3px] ${selectedFiles?.csv ? 'border-blue-600' : 'border-gray-400'}`}>
            <label className='mb-2 block font-semibold'>Listado del sistema de administración - XLSX / CSV</label>
            <input type="file" accept=".csv, .xlsx" onChange={handleFileChange('csv')} className='' />
          </div>
          
          <button className={`mt-4 px-3 p-1 text-lg text-white shadow-md rounded ${canProcess ? 'opacity-100 bg-blue-600' : 'opacity-30 cursor-not-allowed bg-gray-500'}
          `}
          onClick={processData}>
            Procesar
          </button>
        </div>
      ) : null}

      {processedFiles?.lotesUpdated?.length ? (
        <h4 className="mb-4 uppercase text-lg text-gray-800 tracking-wide">fracciones Procesados</h4>
      ) : null}
      {processedFiles?.fraccionesData?.map((fraccionamiento, index) => (
        <Fragment key={index}>
          <div className="p-2 border border-gray-300 rounded">
            <h3 className="mb-2 font-bold text-xl">{fraccionamiento.name}</h3>
            {/* manzanas */}
            <h4 className="mb-1 uppercase text-sm text-gray-400 tracking-wide">Manzanas y Lotes</h4>
            {fraccionamiento.manzanasList.sort((a, b) => a.name - b.name).map((manzana, index) => (
              <div className="mb-3 flex flex-row gap-5" key={index}>
                <div className="mt-0.5 font-semibold text-xl">
                  {manzana.name}
                </div>
                {/* lotes */}
                <div className="px-4 w-full border border-gray-200 rounded">
                  {processedFiles?.lotesUpdated?.
                    filter((lote) => 
                      lote.fraccion === fraccionamiento.name
                      && lote.manzana === manzana.name
                    )?.
                    sort((a, b) => a.num - b.num)
                    .map((lote, index) => (
                      <div className="flex flex-row border-b border-gray-200 py-1.5 last:border-none" key={index}>
                        <div className={`w-12 mr-10 text-right ${lote.estado === 'Disponible' ? 'font-semibold text-blue-600' : ''}`}>{lote.num}</div>
                        <div className={`${lote.estado === 'Disponible' ? 'font-semibold text-blue-700' : 'text-gray-500'}`}>{lote.estado}</div>
                      </div>
                    )
                  )}
                </div>
              </div>
            ))}
          </div>
        </Fragment>
      ))}

      {processedFiles?.lotesUpdated?.length ? (
        <div>
          <button className={`
            mt-3 px-3 p-1 text-lg text-left text-white 
            flex flex-row justify-center items-center gap-2
            shadow-md rounded ${canProcess ? 'opacity-100 bg-green-600' : 'opacity-30 cursor-not-allowed bg-gray-500'}
          `}
          onClick={saveData}>
            {loading ? (
              <svg className="animate-spin mx-auto h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            ) : null}
            Actualizar
          </button>
          <div className='mt-2.5'>
            Se guardarán las modificiones del estado de los lotes listados.
          </div>
        </div>
      ) : null}
      {processedFiles?.lotesUpdated && !processedFiles.lotesUpdated.length ? (
        <div>
          <div className=''>
            No se encontraron cambios de estado.
          </div>
          <button className={`
            mt-3 px-3 p-1 text-lg text-left text-white 
            flex flex-row justify-center items-center gap-2
            shadow-md rounded bg-blue-600
          `}
          onClick={() => {
            setSelectedFiles(null);
            setProcessedFiles(null);
          }}>
            Entendido
          </button>
        </div>
      ) : null}
    </Modal>
  );
}