import React, { useEffect, useState, useMemo } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import config from '../../config';

const ViewMapMultiPoints = ({ points, InfoWindowRender, classes }) => {
  const center = useMemo(() => ({ lat: -23.442503, lng: -58.443832 }), []); // Centro de Paraguay
  const zoom = 6; // Nivel de zoom para mostrar todo Paraguay

  const [map, setMap] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
  });

  useEffect(() => {
    if (isLoaded && map) {
      // No necesitas ajustar el centro o el zoom aquí
    }
  }, [isLoaded, map]);

  const initializeMap = (map) => {
    setMap(map);
  };

  return (
    <div className={`relative ${classes?.mapContainer}`}>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName={`w-full mb-4 rounded-md ${classes?.mapRender} ${classes?.mapHeight || 'h-48'}`}
          center={center}
          zoom={zoom}
          options={{
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false
          }}
          onLoad={initializeMap}
        >
          {points.map((point, index) => (
            <React.Fragment key={index}>
              <Marker
                position={point.point}
                onClick={() => setSelectedMarker(index)}
              />
              {selectedMarker === index && (
                <InfoWindow
                  position={point.point}
                  onCloseClick={() => setSelectedMarker(null)}
                  options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                >
                  {InfoWindowRender && <InfoWindowRender point={point} />}
                </InfoWindow>
              )}
            </React.Fragment>
          ))}
        </GoogleMap>
      )}
    </div>
  );
};

export default ViewMapMultiPoints;
