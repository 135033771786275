export default {
  /////////////////////////////////////////////////////
  // BRAND
  /////////////////////////////////////////////////////
  projectName: 'Tierra Guaraní | Terrenos y Lotes',
  brand: {
    name: 'Tierra Guaraní',
    marketType: 'Terrenos y Lotes'
  },
  domain: 'tierra-guarani.web.app',
  protocol: 'https',

  env: 'prod', //window.location.hostname === 'localhost' ? 'dev' : 'prod',

  currency: 'PYG',
  
  number: '595984577585',
  numberString: '(0984) 577 585',
  locationString: 'Del Maestro entre Teófilo Lesingg y Ntra. Sra. de la Asunción',
  schedule: 'Lunes a Viernes de 9:00 a 18:00hs',
  email: 'tginmobiliaria@etg.com.py',
  location: {lat: -25.3462287, lng: -57.5194769},
  locationLink: 'https://goo.gl/maps/HrMuawpY7jjxCvuTA',
  instagram: 'https://www.instagram.com/tginmobiliaria_/',
  facebook: 'https://www.facebook.com/tierraguaraniInmobiliaria',
  whatsappFooter: 'https://api.whatsapp.com/send?phone=595984577585&text=%C2%A1Hola!%20Quisiera%20solicitar%20informaci%C3%B3n',

  webmailUrl: 'https://webmail.tierraguarani.com.py/',

  getURLprefix: function () {
    return `${this.protocol}://${this.domain}`;
  },

  getProductLink: function (id) {
    return `${this.getURLprefix()}/product/${id}`;
  },

  getCartLink: function (id) {
    return `${this.getURLprefix()}/cart/${id}`;
  },

  getWhatsAppText: function (id) {
    return `Hola! este es mi enlace de pedido ${this.getCartLink(id)}`;
  },

  whatsAppTextBTN: '¡Hola Tierra Guaraní! Quisiera solicitar información sobre un lote.',

  imgFolder: 'productsImages',
  // el imgFolder y el fileName deben ser una sola cadena urlEncoded
  getImgPrefix: (fileName) => `https://firebasestorage.googleapis.com/v0/b/tierra-guarani.appspot.com/o/productsImages%2F${fileName}?alt=media`,
  // youtube video tutorial embed id
  embedId: 'Do7B8xsvsVw', 

  /////////////////////////////////////////////////////
  // PLATFORM
  /////////////////////////////////////////////////////
  
  urlSeparator: '---',

  defaultLocationGPS: {
    "lat": -25.34337159646032,
    "lng": -57.50208590620728
  },

  imageDimensions: [
    { width: 2000, height: 2000, suffix: '' },   // full
    { width: 1300, height: 1300, suffix: '-xl' }, // xl
    { width: 800, height: 800, suffix: '-md' },   // md
    { width: 300, height: 300, suffix: '-xs' },   // xs
  ],

  googleMapApiKey: "AIzaSyBVsnosGIDa-GIMdQf5zPEn7KkSLnIBwZI"
}