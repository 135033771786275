import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { LayoutAdmin } from "../components/LayoutAdmin";
import SectionCrudForm from "../components/SectionCrudForm";
import Model, { useStateSingleResult } from "../libs/ModelClass";
import useEntityTaxonomyForm from '../components/EntityTaxonomyForm';
import ComponentOfModule from '../components/ComponentOfModule';
import { useModule } from '../context/ModuleContext';

export function Content({ entity }) {
  const navigate = useNavigate();
  const entitySlug = entity?.data?.nameSlug;
  const ExtendedModel = Model.extend(entitySlug);
  const { docId } = useParams();
  const { fireEventReducer } = useModule();
  const doc = useStateSingleResult({
    Model: ExtendedModel, 
    id: docId
  });
  
  const [fieldsRequired, setFieldsRequired] = useState([]);
  const { taxonomyTypesDocList, EntityTaxonomyForm } = useEntityTaxonomyForm({ 
    entityId: entity?.id,
    entitySlug,
    fieldsRequired, 
    setFieldsRequired
  });

  const handleSave = async (formValues) => {
    const modifiedFormValues = await fireEventReducer('beforeSave', { entitySlug, taxonomyTypesDocList }, formValues);
    const savedItem = await ExtendedModel.createOrUpdate(modifiedFormValues);
    if (docId === 'new') {
      navigate(`/admin/entity/${entity?.id}`);
      navigate(`/admin/entity/${entity?.id}/${savedItem.id}/form`);
    }
  };

  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // [TODO] realiar validación de taxonomías segú tipo y param
  };
  const FormInputFields = (({ values }) => (<>
    <EntityTaxonomyForm values={values} />
    <ComponentOfModule
      type="crud"
      action="UnderForm"
      entitySlug={entitySlug}
      param={{ values }} 
    />
  </>));

  return (
    <div className="">
      {/* Entity Form */}
      <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
        {doc ? (
          <SectionCrudForm
            doc={doc}
            onSave={handleSave}
            onValidation={onValidation}
            fieldsRequired={fieldsRequired}
            FormInputFields={FormInputFields}
            editStyle="route"
          />
        ) : null}
      </div>
    </div>
  );
}

export function AdminEntityCrudForm() {
  const { entityId } = useParams();
  const entity = useStateSingleResult({
    Model: Model.extend('entities'), 
    id: entityId
  });

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Admin</h2></Link>
        <Link to={`/admin/entity/${entityId}`}><h2 className="text-xl font-thin mb-4 mr-3">{entity?.data?.name}</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Formulario</h2>
      </div>

      {entity && <Content entity={entity} />}
    </LayoutAdmin>
  );
}
