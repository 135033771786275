import { useEntityFullBySlug } from '../modules/entity/Entity';
import { typeConfigs } from "../components/DataTypes";

const Render = ({ entitySlug, values, taxonomyType, fieldsRequired }) => {
  const typeConfig = typeConfigs[taxonomyType?.data?.type];

  if (!typeConfig || !typeConfig.Render) {
    return null;
  }

  return typeConfig.Render({ entitySlug, values, taxonomyType, fieldsRequired });
};

export const EntityTaxonomyForm = ({ entitySlug, values, taxonomyTypesDocList, fieldsRequired }) => {
  return (
    <>
      {taxonomyTypesDocList?.map((taxonomyTypeDoc) => {
        const { id, data } = taxonomyTypeDoc;
        if (data?.deleted || data?.hidden) { return null; }
        return (
          <div key={id} className='p-1'>
            <Render  
              entitySlug={entitySlug}
              values={values}
              taxonomyType={taxonomyTypeDoc}
              fieldsRequired={fieldsRequired}
            />
          </div>
        );
      })}
    </>
  );
};

const useEntityTaxonomyForm = ({ entitySlug, fieldsRequired=[], setFieldsRequired }) => {
  const onFetch = ({ taxonomyTypesDocList }) => {
    const addFieldsRequired = [];
    taxonomyTypesDocList.forEach((taxonomyType) => {
      if (taxonomyType.data.required && !taxonomyType.data.deleted) {
        addFieldsRequired.push(taxonomyType.data.nameSlug);
      }
    });
    setFieldsRequired([ ...fieldsRequired, ...addFieldsRequired ]);
  }
  
  const { taxonomyTypesDocList, ...fns } = useEntityFullBySlug({ entitySlug, onFetch });

  return {
    taxonomyTypesDocList,
    ...fns,
    EntityTaxonomyForm: ({ values }) => (
      <EntityTaxonomyForm 
        entitySlug={entitySlug}
        values={values}
        taxonomyTypesDocList={taxonomyTypesDocList} 
        fieldsRequired={fieldsRequired} 
      />
    )
  };
}

export default useEntityTaxonomyForm;