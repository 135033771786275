import { useState, useEffect } from 'react';
import _ from "lodash";
import { slug } from './utils';


export default function useFilters ({ subscribeProductsAll })  { 
  let [productsById, setProductsById] = useState({});
  let [productsByParentId, setProductsByParentId] = useState({});
  // let [productsBySectors, setProductsBySectors] = useState([]);
  // let [productsByMaterials, setProductsByMaterials] = useState({});
  // let [productsByCollections, setProductsByCollections] = useState({});
  // let [productsByColors, setProductsByColors] = useState({});
  let [productsAll, setProductsAll] = useState([]);
  let [selectedFilters, setSelectedFilters] = useState({});
  let [openFilters, setOpenFilters] = useState({});
  let [selectedProductId, setSelectedProductId] = useState();


  // fill products list
  useEffect(() => {
    return subscribeProductsAll(({ list }) => {
      // update state
      setProductsAll(list);
    });
  }, []);

  useEffect(() => {
    // nest variants
    const parents = {};
    const byId = {};
    // const toAssignAllColors = [];
    // set parents
    productsAll.forEach((prod) => {
      // add parent
      if (!parents[prod.id] && prod.parentId === 'main' && prod.show) {
        parents[prod.id] = prod;
        prod.variants = [];
      }
    });
    productsAll.forEach((prod) => {
      byId[prod.id] = prod;
      // add variants to parent
      if (parents[prod.parentId] && prod.parentId !== 'main' && prod.show && parents[prod.parentId].withVariants) {
        parents[prod.parentId].variants.push(prod);
      }
      // add parent as variant
      if (prod.parentId === 'main' && !prod.withVariants) {
        const selfVariant = { ...prod };
        selfVariant.parentId = prod.id;
        prod.variants = [ selfVariant ];
      }
      // // Colors, include field name
      // if (prod.colors) {
      //   if (prod.colors === 'todos') {
      //     toAssignAllColors.push(prod);
      //   }
      //   else {
      //     const colorsList = prod.colors.split(', ');
      //     colorsList.forEach((color, colorIndex) => {
      //       let colorName = _(color).trim();
      //       if (!productsByColors[colorName]) {
      //         productsByColors[colorName] = {
      //           name: color,
      //           slug: slug(color),
      //           useAsMain: 0
      //         };
      //       }
      //       if (!productsByColors[colorName][prod.id]) {
      //         productsByColors[colorName][prod.id] = prod;
      //       }
      //       productsByColors[colorName].useAsMain += colorIndex === 0 ? 1 : 0;
      //     });
      //   }
      // }
      // Only products main
      if (prod.parentId === 'main' && prod.show) {
        // // Sectors
        // if (prod.sectors) {
        //   const sectorsList = prod.sectors.split(', ');
        //   sectorsList.forEach(sector => {
        //     let sectorSlug = _(sector).trim();
        //     if (!productsBySectors[sectorSlug]) {
        //       productsBySectors[sectorSlug] = {};
        //     }
        //     if (!productsBySectors[sectorSlug][prod.id]) {
        //       productsBySectors[sectorSlug][prod.id] = prod;
        //     }
        //   });
        // }
        // // Collection
        // if (prod.collections) {
        //   const collectionsList = prod.collections.split(', ');
        //   collectionsList.forEach(collection => {
        //     let collectionName = _(collection).trim();
        //     if (!productsByCollections[collectionName]) {
        //       productsByCollections[collectionName] = {};
        //     }
        //     if (!productsByCollections[collectionName][prod.id]) {
        //       productsByCollections[collectionName][prod.id] = prod;
        //     }
        //   });
        // }
        // // Materials, include field name
        // if (prod.materials) {
        //   const materialsList = prod.materials.split(', ');
        //   materialsList.forEach(material => {
        //     let materialName = _(material).trim();
        //     if (!productsByMaterials[materialName]) {
        //       productsByMaterials[materialName] = {
        //         name: material
        //       };
        //     }
        //     if (!productsByMaterials[materialName][prod.id]) {
        //       productsByMaterials[materialName][prod.id] = prod;
        //     }
        //   });
        // }
      }
    });
    // // assign all colors
    // _.forEach(productsByColors, (products, color) => {
    //   _.forEach(toAssignAllColors, prod => {
    //     products[prod.id] = prod;
    //   });
    // });
    // update state
    setProductsById(byId);
    setProductsByParentId(parents);
    // setProductsBySectors({ ...productsBySectors });
    // setProductsByMaterials({ ...productsByMaterials });
    // setProductsByCollections({ ...productsByCollections });
    // setProductsByColors({ ...productsByColors });
    
  }, [productsAll]);

  const filterHasActiveOptions = (filterType) => {
    if (selectedFilters[filterType]) {
      let optionsSelectedQty = 0;
      _.each(selectedFilters[filterType], (value, filterOption) => {
        if (value) {
          optionsSelectedQty++;
        }
      });
      return optionsSelectedQty;
    }
  }

  // const hasValidTexture = (productId) => {
  //   if (selectedFilters['texture']) {
  //     let textureOfProduct = getVariantAttr('texture', productId, false);
  //     // rayitas: 0, listo: 1
  //     if (selectedFilters['texture']['rayitas'] && textureOfProduct === true) {
  //       return true;
  //     }
  //     if (selectedFilters['texture']['liso'] && textureOfProduct === false) {
  //       return true;
  //     }
  //   }
  // }

  const hasValidFilterOption = (filterField, productId) => {
    let validFiltersQty = 0;
    // si hay filtros en uso
    if (_.size(selectedFilters[filterField])) {
      _.forEach(selectedFilters[filterField], (isActive, field) => {
        // verificar si cumple con alguno de los filtros activos
        if (isActive) {
          // iterar sobre cada opción asiganada al producto
          let valuesToFilter = getVariantAttr(filterField, productId);
          valuesToFilter = valuesToFilter.split(',');
          _.forEach(valuesToFilter, val => {
            if (selectedFilters[filterField][ _.trim(val) ]) {
              validFiltersQty++;
            }
          });
        }
      });
    }
    return validFiltersQty;
  }

  const getVariantAttr = (field, productId, byDefault='') => {
    const product = productsById[productId];
    if (!product) { return; }
    const parent = productsById[product.parentId];
    if (parent) {
      if (field === 'name') { return parent['name']; }
    }
    if ( !_.isUndefined(product[field]) && !_.isNull(product[field]) ) {
      return product[field];
    } 
    else if ( parent && !_.isUndefined(parent[field]) && !_.isNull(parent[field]) ) {
      return parent[field];
    }
    return byDefault;
  }

  return {
    // data index
    productsById,
    productsByParentId,
    // productsBySectors,
    // productsByMaterials,
    // productsByCollections,
    // productsByColors,
    productsAll,
    // filters
    filterHasActiveOptions,
    // hasValidTexture,
    hasValidFilterOption,
    getVariantAttr,
    selectedFilters, setSelectedFilters,
    openFilters, setOpenFilters,
    selectedProductId, setSelectedProductId
  };
}