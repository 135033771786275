import React, { useEffect, useState, useMemo } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import config from '../../config';

const ViewMapPoint = (props) => {
  let {
    value,
    classes,
    mapZoom = 11,
    MapElements
  } = props;
  const center = useMemo(() => (config.defaultLocationGPS), []);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config.googleMapApiKey,
  });

  useEffect(() => {
    if (value && value.lat && value.lng) {
      if (map) {
        const center = { lat: value.lat, lng: value.lng };
        map.panTo(center);
        createOrUpdateMarker(value);
      }
    }
  }, [value, map]);

  const initializeMap = (map) => {
    setMap(map);
    createOrUpdateMarker(value);
  };

  const createOrUpdateMarker = (position) => {
    if (marker) {
      marker.setPosition(position);
    } else {
      const newMarker = new window.google.maps.Marker({
        position,
        map,
        draggable: false,
      });
      setMarker(newMarker);
    }
  };

  const handleOpenGoogleMaps = () => {
    const { lat, lng } = value;
    const url = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(url, '_blank');
  };

  return (
    <div className={`relative ${classes?.mapContainer}`}>
      {isLoaded && (
        <GoogleMap
          mapContainerClassName={`w-full mb-4 rounded-md ${classes?.mapRender} ${classes?.mapHeight || 'h-48'}`}
          center={center}
          options={{
            zoom: mapZoom,
            zoomControl: true,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            rotateControl: false,
            scaleControl: false
          }}
          onLoad={initializeMap}
        >
          <Marker position={value} />
          {value && map && MapElements ? (<MapElements map={map} value={value} />) : null}
        </GoogleMap>
      )}

      <button
        type="button"
        onClick={handleOpenGoogleMaps}
        className="absolute bottom-6 left-2 bg-white rounded border border-gray-300 shadow-md px-4 py-2 flex flex-row gap-2 items-center"
      >
        <FaMapMarkerAlt />
        Ver en Google Maps
      </button>
    </div>
  );
};

export default ViewMapPoint;
