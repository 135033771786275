import { FaMapMarkerAlt, FaRegClock, FaWhatsapp, FaEnvelope } from "react-icons/fa";
import { AddClassWhenVisible } from "../AddClassWhenVisible";

export default function CtaContactUsBlock({ data }) {
  return (
    <div id="contactos" className="text-black text-center md:grid md:grid-cols-2">

      {/* Left / Top */}
      <div className="h-[35vh] md:h-full bg-cover bg-bottom"
        style={{ backgroundImage: `url(${data.backgroundImageUrl})` }}>
      </div>

      {/* Right / Bottom*/}
      <div className="text-start bg-gray-100 content-center py-10 px-8 sm:px-14 md:py-16 lg:py-20 ">
        <div className="container-width pt-4 md:pt-12 lg:pt-28 lg:pr-10 lg:pl-12 xl:pl-20 xl:pr-28 xl:pt-36">

          <AddClassWhenVisible classToAdd="animate-fadeIn">
            <div className="text-center lg:text-start px-3 sm:px-2 md:px-0">
              <h3 className="pb-2 text-4xl lg:text-4xl md:pb-4 font-brand-mulish text-brand-red font-bold">{data.title}</h3>
              <p className="pb-4 text-lg md:text-xl font-brand-mulish">{data.content}</p>
            </div>
          </AddClassWhenVisible>
          
          <div className="grid gap-4 grid-cols-2 font-brand-mulish px-3 pb-2 sm:px-2 md:px-0">
            {data.items.map((item, index) => (
              <div key={index} className="flex items-center justify-center md:justify-normal text-white bg-brand-yellow p-1.5 sm:pr-4 rounded-sm shadow-sm cursor-pointer hover:shadow-md">
                <img src={item.iconUrl} alt="imagen-ContactUs" className="h-6 pr-2 pl-0.5 md:pl-2" />
                <a href={item.url} target="_blank" className="lg:text-xl text-lg" rel="noreferrer">
                  {item.title}
                </a>
              </div>
            ))}
          </div>

          <div className="grid gap-3 grid-cols-1 font-brand-montserrat text-base p-4 md:p-2">
            {data.phoneNumber ? (
              <div className="flex items-start text-center place-content-start align-top py-1 md:py-0">
                <div className="mr-1.5">
                  <FaWhatsapp size={25}></FaWhatsapp>
                </div>
                <div className="font-semibold">Teléfono:</div>
                <div className="text-base ml-2">
                  {data.phoneNumber}
                </div>
              </div>
            ) : null}

            {data.email ? (
              <div className="flex items-start text-center place-content-start align-top py-1 md:py-0">
                <div className="mr-1.5">
                  <FaEnvelope size={25}></FaEnvelope>
                </div>
                <div className="font-semibold">Email:</div>
                <a href={`mailto:${data.email}?subject=Consulta%20sobre%20propiedades`} className="text-base ml-2 mt-0">
                  {data.email}
                </a>
              </div>
            ) : null}

            {data.schedule ? (
              <div className="flex items-start text-center place-content-start align-top">
                <div className="mr-1.5">
                  <FaRegClock size={25}></FaRegClock>
                </div>
                <div className="font-semibold">Horarios:</div>
                <div className="text-sm text-left md:text-base lg:text-base ml-2">
                  {data.schedule}
                </div>
              </div>
            ) : null}

            {data.address ? (
              <div className="flex items-start text-center place-content-start align-top">
                <div className="mr-1.5">
                  <FaMapMarkerAlt size={25}></FaMapMarkerAlt>
                </div>
                <div className="font-semibold">Ubicación:</div>
                <div className="text-base text-left ml-2">
                  {data.address}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

    </div>
  );
}
