import VisibilitySensor from 'react-visibility-sensor';
import { useNavigate } from "react-router-dom";
import { useData } from "../context/DataContext";
import { priceFormat, getImageURL } from "../libs/utils";


export default function ItemThumbOne({ item }) {
  const navigate = useNavigate();

  // const {
  //   // analytics
  //   viewItem
  // } = useData();

  const onSensor = (_isVisible) => {
    if (_isVisible && item.id) {
      // viewItem(item.id); // analytics
      console.log('TODO: viewItem')
    }
  }

  const goToItem = (itemId) => {
    navigate(`/item/${itemId}`);
  }
  
  return (
    item ? (
      <VisibilitySensor minTopValue={0} onChange={onSensor}>
        <button className="block w-full mb-3 relative" onClick={() => goToItem(item.id)}>
          <div className="shadow-md hover:shadow-xl">
            <img src={item && item.data.images.length && getImageURL(item.data.images[0], 'xs')} 
            className="rounded h-[230px] w-full object-cover" /> {/* image xs */}
          </div>
          
          <div className="absolute top-[190px] px-4 w-full">
            <div className="bg-white shadow-md hover:shadow-xl rounded-2xl px-3 py-3">
              <div className="mx-2">
                {/* titles */}
                {item.data.name ? (
                  <h2 className="text-xl font-semibold">{item.data.name}</h2>
                ) : ''}
                {item.data.price ? (
                  <p> <span className="text-green-600 text-lg mr-2">{priceFormat(item.data.price)}</span> </p>
                ) : ''}
                {/* tags */}
                <div className="tags"></div>
                {/* {item.data.description ? (
                  <p className="text-gray-400">{item.data.description}</p>
                ) : ''} */}
                {/* <div className="flex place-content-between mt-4">
                  <div className="">
                    <button className=" px-2 py-0.5 text-xs bg-red-500 rounded-md text-white mr-2" onClick={() => confirmDeleteitem(id)}>Eliminar</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

        </button>
      </VisibilitySensor>
    ) : ''
  )
}