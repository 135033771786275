import {
  FormFieldGallery
} from '../Form';


export default {
  Render({values, taxonomyType, fieldsRequired}) {
    let title = taxonomyType?.data?.name;
    return (<FormFieldGallery 
      fieldsRequired={fieldsRequired}
      name={taxonomyType?.data?.nameSlug}
      title={title}
      placeholder={title}
      {...taxonomyType?.data?.param}
    />);
  },

  RenderInputParams: ({ values, param }) => (null),
  
  RenderShowConditionParams: ({ values, taxonomyToWatch }) => (null),
  
  RenderStyleParams: ({ values }) => (null),
  
  RenderTaxonomyTypePreview: ({ values, formFieldProps }) => (
    <FormFieldGallery {...formFieldProps} />
  ),
  
  getDefaultTaxonomyTypeDesign: () => ('default')
}